import {
  CREATE_DOCUMENT_TEMPLATE,
  CREATE_DOCUMENT_TEMPLATE_SUCCESS,
  GET_DOCUMENT_TEMPLATE,
  GET_DOCUMENT_TEMPLATE_SUCCESS,
  GET_DOCUMENT_TEMPLATE_BY_ID,
  GET_DOCUMENT_TEMPLATE_BY_ID_SUCCESS,
  DELETE_DOCUMENT_TEMPLATE,
  DELETE_DOCUMENT_TEMPLATE_SUCCESS,
  SEARCH_DOCUMENT_TEMPLATES,
  UPDATE_DOCUMENT_TEMPLATE,
  UPDATE_DOCUMENT_TEMPLATE_SUCCESS,
} from "../../constants/ActionTypes";
export const createDocumentTemplate = (
  documentTemplate,
  documentTemplates,
  isNew = true
) => {
  return {
    type: CREATE_DOCUMENT_TEMPLATE,
    documentTemplate,
    documentTemplates,
    isNew,
  };
};

export const createDocumentTemplateSuccess = (documentTemplate) => {
  return {
    type: CREATE_DOCUMENT_TEMPLATE_SUCCESS,
    payload: documentTemplate,
  };
};

export const updateDefaultDocumentTemplate = (payload) => {
  return {
    type: UPDATE_DOCUMENT_TEMPLATE,
    payload
  };
};

export const updateDefaultDocumentTemplateSuccess = (payload) => {
  return {
    type: UPDATE_DOCUMENT_TEMPLATE_SUCCESS,
    payload,
  };
};

export const deleteDocumentTemplate = (ids) => {
  return {
    type: DELETE_DOCUMENT_TEMPLATE,
    payload: ids,
  };
};

export const deleteDocumentTemplateSuccess = (ids) => {
  return {
    type: DELETE_DOCUMENT_TEMPLATE_SUCCESS,
    payload: ids,
  };
};

export const getListOfDocumentTemplates = (page, take) => {
  return {
    type: GET_DOCUMENT_TEMPLATE,
    page,
    take,
  };
};

export const getListOfDocumentTemplatesSuccess = (documentTemplates) => {
  return {
    type: GET_DOCUMENT_TEMPLATE_SUCCESS,
    payload: documentTemplates,
  };
};

export const getDocumentTemplatesById = (id) => {
  return {
    type: GET_DOCUMENT_TEMPLATE_BY_ID,
    payload: id,
  };
};

export const getDocumentTemplatesByIdSuccess = (documentTemplate) => {
  return {
    type: GET_DOCUMENT_TEMPLATE_BY_ID_SUCCESS,
    payload: documentTemplate,
  };
};

export const searchDocumentTemplate = (value, list, filterOption = "name") => {
  try {
    let selected;
    if (value !== "") {
      selected = list.filter(
        (item, index) =>
          item[filterOption]
            .toString()
            .toLowerCase()
            .search(value.toLowerCase()) !== -1
      );
    } else {
      selected = list;
    }
    return {
      type: SEARCH_DOCUMENT_TEMPLATES,
      payload: selected ? selected : [],
    };
  } catch (error) {
    console.error(error);
  }
};

export const _ = (list, selectedOption) => {
  let selected = [];
  if (Object.values(selectedOption)[0] === 1) {
    selected = list;
  } else {
    let dateNow = new Date();
    dateNow.setDate(dateNow.getDate() - 0); // - 0 ==> today
    selected = list.filter(
      (item) =>
        item[Object.keys(selectedOption)[0]].split("T")[0] ===
        dateNow.toISOString().split("T")[0]
    );
  }
  return {
    type: SEARCH_DOCUMENT_TEMPLATES,
    payload: selected ? selected : [],
  };
};
