import {
  GET_PRODUCT_RELATED_INDICATORS,
  GET_PRODUCT_RELATED_INDICATORS_SUCCESS,
  GET_PATHOLOGIE_RELATED_INDICATORS,
  GET_PATHOLOGIE_RELATED_INDICATORS_SUCCESS,
  GET_COMBINE_RELATED_INDICATORS,
  GET_COMBINE_RELATED_INDICATORS_SUCCESS,
  GET_DOCTOR_RELATED_INDICATORS,
  GET_DOCTOR_RELATED_INDICATORS_SUCCESS,
  POST_SATISFACTION_GRAPH,
  POST_SATISFACTION_GRAPH_SUCCESS,
  // COVID
  GET_COVID_TRETEMENTS,
  GET_COVID_TRETEMENTS_SUCCESS,
  POST_SATISFACTION_GRAPH_MY_DOCUMENT_TEMPLATES,
  POST_SATISFACTION_GRAPH_MY_DOCUMENT_TEMPLATES_SUCCESS,
  POST_SATISFACTION_GRAPH_ALL_MY_DOCUMENT_TEMPLATES,
  POST_SATISFACTION_GRAPH_ALL_MY_DOCUMENT_TEMPLATES_SUCCESS,
  POST_SATURATION_O2_GRAPH,
  POST_SATURATION_O2_GRAPH_SUCCESS,
} from "../../constants/ActionTypes";

const initModalState = {
  loader: true,
  list: [],
  listSaturation: [],
  listSatisfactionOfMyPatients: [],
  listSatisfactionOfAllMyPatients: []
};

const ProductGraphReducer = (state = initModalState, action) => {
  switch (action.type) {
    case GET_COVID_TRETEMENTS:
      return {
        ...state,
        loader: false,
      };
    case GET_COVID_TRETEMENTS_SUCCESS:
      return {
        ...state,
        loader: false,
        list: action.payload,
      };
    case POST_SATISFACTION_GRAPH_ALL_MY_DOCUMENT_TEMPLATES:
      return {
        ...state,
        loader: false,
      };
    case POST_SATISFACTION_GRAPH_ALL_MY_DOCUMENT_TEMPLATES_SUCCESS:
      return {
        ...state,
        loader: false,
        listSatisfactionOfAllMyPatients: action.payload.map(item => {
          item.name = item.name.split('T')[0]
          return item
        }),
      };
    case POST_SATISFACTION_GRAPH_MY_DOCUMENT_TEMPLATES:
      return {
        ...state,
        loader: false,
      };
    case POST_SATISFACTION_GRAPH_MY_DOCUMENT_TEMPLATES_SUCCESS:
      return {
        ...state,
        loader: false,
        listSatisfactionOfMyPatients: action.payload.map(item => {
          item.name = item.name.split('T')[0]
          return item
        }),
      };
    case POST_SATURATION_O2_GRAPH:
      return {
        ...state,
        loader: false,
      };
    case POST_SATURATION_O2_GRAPH_SUCCESS:
      return {
        ...state,
        loader: false,
        listSaturation: action.payload.map(item => {
          item.name = item.name.split('T')[0]
          return item
        }),
      };
    case POST_SATISFACTION_GRAPH:
      return {
        ...state,
        loader: false,
      };
    case POST_SATISFACTION_GRAPH_SUCCESS:
      return {
        ...state,
        loader: false,
        list: action.payload.map(item => {
          item.name = item.name.split('T')[0]
          return item
        }),
      };
    case GET_PRODUCT_RELATED_INDICATORS:
      return {
        ...state,
        loader: false,
      };
    case GET_PRODUCT_RELATED_INDICATORS_SUCCESS:
      return {
        ...state,
        loader: false,
        list: action.payload,
      };
    case GET_PATHOLOGIE_RELATED_INDICATORS:
      return {
        ...state,
        loader: false,
      };
    case GET_PATHOLOGIE_RELATED_INDICATORS_SUCCESS:
      return {
        ...state,
        loader: false,
        list: action.payload,
      };
    case GET_COMBINE_RELATED_INDICATORS:
      return {
        ...state,
        loader: false,
      };
    case GET_COMBINE_RELATED_INDICATORS_SUCCESS:
      return {
        ...state,
        loader: false,
        list: action.payload,
      };
    case GET_DOCTOR_RELATED_INDICATORS:
      return {
        ...state,
        loader: true,
      };
    case GET_DOCTOR_RELATED_INDICATORS_SUCCESS:
      return {
        ...state,
        loader: false,
        list: action.payload,
      };
    default:
      return state;
  }
};

export default ProductGraphReducer;
