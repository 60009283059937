import React, { useEffect, useState } from "react";
import { Button, Form, Input, notification } from "antd";
import { Link, useHistory, useParams } from "react-router-dom";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { hideMessage, showAuthLoader, userSignUp } from "appRedux/actions/Auth";

import IntlMessages from "util/IntlMessages";
import { message } from "antd/lib/index";
import CircularProgress from "components/CircularProgress/index";
import "./styles.css";

const FormItem = Form.Item;

const SignUpAccountant = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { clientId, accountantEmail, countryCode } = useParams();
  const { loader, alertMessage, showMessage, authUser } = useSelector(
    ({ auth }) => auth
  );

  const [checks, setChecks] = useState({
    upperLetterCheck: false,
    lowerLetterCheck: false,
    numberCheck: false,
    lengthCheck: false,
  });

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
    if (authUser !== null) {
      history.push("/");
    }
  }, []);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const otherPwdMessage = (
    <div>
      <p>
        <span className="text-colors">le mot de passe doit contenir :</span>
        <br />
        <ul>
          <li
            className={`text-color-${checks.lengthCheck ? "valide" : "invalide"
              }`}
          >
            Minimum de 8 caractères
          </li>
          <li
            className={`text-color-${checks.upperLetterCheck ? "valide" : "invalide"
              }`}
          >
            1 caractère alphabétique majuscule
          </li>
          <li
            className={`text-color-${checks.lowerLetterCheck ? "valide" : "invalide"
              }`}
          >
            1 caractère alphabétique minuscule
          </li>
          <li
            className={`text-color-${checks.numberCheck ? "valide" : "invalide"
              }`}
          >
            1 chiffre
          </li>
        </ul>
      </p>
    </div>
  );

  const onChangePwd = (event) => {
    const { value } = event.target;

    const upperLetterCheck = /(?=.*[A-Z])/.test(value);
    const lowerLetterCheck = /(?=.*[a-z])/.test(value);
    const numberCheck = /(?=.*[0-9])/.test(value);
    const lengthCheck = /(?=.{8,})/.test(value);
    setChecks({
      upperLetterCheck,
      lowerLetterCheck,
      numberCheck,
      lengthCheck,
    });
  };

  const onFinish = (values) => {
    dispatch(showAuthLoader());
    dispatch(
      userSignUp(
        {
          accountantData: {
            ...values,
            email: accountantEmail,
          },
          clientId,
          countryCode,
        },
        "create-accountant"
      )
    );
  };

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">

          <div className="gx-app-login-content" style={{ width: '100%' }}>
            <Form
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0"
            >
              <FormItem
                rules={[{ required: true, message: "Champ obligatoire!" }]}
                name="givenName"
              >
                <Input placeholder="Prénom" />
              </FormItem>
              <FormItem
                rules={[{ required: true, message: "Champ obligatoire!" }]}
                name="familyName"
              >
                <Input placeholder="Nom" />
              </FormItem>

              <FormItem
                name="password"
                rules={[
                  {
                    required: true,
                    message: otherPwdMessage,
                  },
                  {
                    pattern: new RegExp(
                      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"
                    ),
                    message: otherPwdMessage,
                  },
                ]}
              >
                <Input.Password
                  onChange={onChangePwd}
                  //type="password"
                  placeholder="Password"
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </FormItem>
              <FormItem
                name="Confirm"
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "Les deux mots de passe que vous avez entrés ne correspondent pas !"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  //type="password"
                  placeholder="Confirm password"
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </FormItem>
              <FormItem>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="app.userAuth.signUp" />
                </Button>
              </FormItem>

            </Form>
          </div>
          {loader && (
            <div className="gx-loader-view">
              <CircularProgress />
            </div>
          )}
          {showMessage &&
            notification.error({
              message: alertMessage,
              duration: 20
            })}
        </div>
      </div>
    </div>
  );
};

export default SignUpAccountant;
