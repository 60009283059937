import {
  ADD_BANKING_TRANSACTION_SUCCESS,
  GET_ALL_BANKING_TRANSACTION,
  GET_ALL_BANKING_TRANSACTION_SUCCESS,
  GET_ALL_MONTHLY_BANKING_TRANSACTION,
  GET_ALL_MONTHLY_BANKING_TRANSACTION_SUCCESS,
  GET_BANKING_TRANSACTION,
  GET_BANKING_TRANSACTION_SUCCESS,
  UPDATE_BANKING_TRANSACTION,
  UPDATE_BANKING_TRANSACTION_SUCCESS,
  DELETE_BANKING_TRANSACTION,
  DELETE_BANKING_TRANSACTION_SUCCESS,
  SHOW_MODAL,
  HIDE_MODAL,
  SHOW_EDIT_MODAL,
  HIDE_EDIT_MODAL,
  SEARCH_DOCTOR,
  SEARCH_DOCTOR_MASTER,
} from "../../constants/ActionTypes";

const bankingTransactionsInitState = {
  addModal: false,
  updateModal: false,
  bankingTransaction: {},
  pageInfos: {},
  base: [],
  editable: [],
  loader: true,
};

const BankingTransactionReducers = (state = bankingTransactionsInitState, action) => {
  let { type, payload } = action
  switch(type){
    case GET_BANKING_TRANSACTION:
    case UPDATE_BANKING_TRANSACTION:
      return { ...state };
    case GET_BANKING_TRANSACTION_SUCCESS:
    case UPDATE_BANKING_TRANSACTION_SUCCESS:
      return { ...state, bankingTransaction: payload, base: payload };
    case ADD_BANKING_TRANSACTION_SUCCESS:
      return state;
    case GET_ALL_BANKING_TRANSACTION_SUCCESS:
    case GET_ALL_MONTHLY_BANKING_TRANSACTION_SUCCESS:
      return { ...state, loader: false, base: payload.data, editable: payload.data, pageInfos: payload.pageInfos };
    case DELETE_BANKING_TRANSACTION:
      return { ...state, loader: true };
    case DELETE_BANKING_TRANSACTION_SUCCESS:
      return { ...state, loader: false, base: action.payload, editable: action.payload }
    case SEARCH_DOCTOR:
      return { ...state, editable: payload };
    case SEARCH_DOCTOR_MASTER:
      return { ...state, editable: payload };
    case SHOW_MODAL:
      return { ...state, addModal: true };
    case HIDE_MODAL:
      return { ...state, addModal: false };
    case SHOW_EDIT_MODAL:
      return { ...state, updateModal: true, bankingTransaction: action.payload };
    case HIDE_EDIT_MODAL:
      return { ...state, updateModal: false, bankingTransaction: {} };
    default:
      return state;
  }
}

export default BankingTransactionReducers;