import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { post, get, update, remove, downloadFile } from "../../axios/master";
import {
  ADD_FILE,
  DELETE_FILES,
  DOWNLOAD_FILE_PDF,
  DOWNLOAD_CERTIFICATE_OF_WITHHOLDING_TAX,
  SEND_MAIL_CERTIFICATE_OF_WITHHOLDING_TAX,
  GET_ALL_FILES,
  GET_ALL_MONTHLY_FILES,
  GET_FILE,
  UPDATE_FILE,
  FETCH_WITHHOLDING_TAX,
  FETCH_LOCAL_EQUIPEMENT_TAX,
  FETCH_LOCAL_PURCHASE_TAX,
  FETCH_IMPORTED_EQUIPEMENT_TAX,
  FETCH_IMPORTED_PURCHASE_TAX
} from "../../constants/ActionTypes";
import {
  getListOfFilesSuccess,
  getListOfMonthlyFilesSuccess,
  getFileSuccess,
  showFileMessage,
  deleteFileSuccess,
  fetchWithholdingTaxSuccess,
  fetchLocalEquipementTaxSuccess,
  fetchLocalPurchaseTaxSuccess,
  fetchImportedEquipementTaxSuccess,
  fetchImportedPurchaseTaxSuccess
} from "../actions/File";
import { fetchError, hideDocModal } from "../actions/Common";
import { message, notification } from "antd";

const baseUrl = "files";

function* addNewFile({ payload }) {
  try {
    const addFile = yield call(post, baseUrl, payload);
    if (addFile) {
      yield put(showFileMessage("Fichier Ajouter avec succées"));
      yield put(hideDocModal());
    } else {
    }
  } catch (error) {
    for (const errorMessage of error.message) {
      for (const keys of Object.keys(errorMessage.constraints)) {
        notification.error({
            message: errorMessage.constraints[keys],
            duration: 20
          });
      }
    }
    yield put(fetchError(error));
  }
}

function* getFiles({ page, take }) {
  let response = yield call(
    get,
    page && take ? `${baseUrl}?page=${page}&take=${take}` : `${baseUrl}`
  );
  try {
    if (response) {
      yield put(getListOfFilesSuccess(response));
    } else {
      yield put(showFileMessage(response.message));
    }
  } catch (error) {
    console.error(error);
  }
}

function* getMonthlyFiles({ yearMonth, clientId }) {
  let response = yield call(
    get, 
    yearMonth ? 
    `${baseUrl}/monthly?clientId=${clientId}&yearMonth=${yearMonth}`: 
    `${baseUrl}/monthly?clientId=${clientId}`);

  if(response) {
    yield put(getListOfMonthlyFilesSuccess(response));
  } else {
    yield put(showFileMessage(response.message));
  }
}

function* getWithholdingTax({ clientId, month }) {
  if (month && clientId){
    try {
      let response = yield call(
        get,
        `${baseUrl}/withholding-tax?userId=${clientId}&currentMonth=${month}`
      );
      if (response){
        yield put(fetchWithholdingTaxSuccess(response));
      }
    } catch(error){
      notification.error({
      message: error,
      duration: 20
    });
    }
  }
}

function* getLocalEquipementTax({ clientId, month }) {
  if (month && clientId){
    try {
      let response = yield call(
        get,
        `${baseUrl}/local-equipement?userId=${clientId}&currentMonth=${month}`
      );
      if (response){
        yield put(fetchLocalEquipementTaxSuccess(response));
      }
    } catch(error){
      notification.error({
      message: error,
      duration: 20
    });
    }
  }
}

function* getLocalPurchaseTax({ clientId, month }) {
  if (month && clientId){
    try {
      let response = yield call(
        get,
        `${baseUrl}/local-purchase?userId=${clientId}&currentMonth=${month}`
      );
      if (response){
        yield put(fetchLocalPurchaseTaxSuccess(response));
      }
    } catch(error){
      notification.error({
      message: error,
      duration: 20
    });
    }
  }
}

function* getImportedEquipementTax({ clientId, month }) {
  if (month && clientId){
    try {
      let response = yield call(
        get,
        `${baseUrl}/imported-equipement?userId=${clientId}&currentMonth=${month}`
      );
      if (response){
        yield put(fetchImportedEquipementTaxSuccess(response));
      }
    } catch(error){
      notification.error({
      message: error,
      duration: 20
    });
    }
  }
}

function* getImportedPurchaseTax({ clientId, month }) {
  if (month && clientId){
    try {
      let response = yield call(
        get,
        `${baseUrl}/imported-purchase?userId=${clientId}&currentMonth=${month}`
      );
      if (response){
        yield put(fetchImportedPurchaseTaxSuccess(response));
      }
    } catch(error){
      notification.error({
      message: error,
      duration: 20
    });
    }
  }
}

function* getSingleFile({ payload }) {
  try {
    let file = yield call(get, `${baseUrl}/${payload}`);
    if (file) {
      yield put(getFileSuccess(file));
    }
  } catch (error) {
    for (const errorMessage of error.message) {
      for (const keys of Object.keys(errorMessage.constraints)) {
        notification.error({
            message: errorMessage.constraints[keys],
            duration: 20
          });
      }
    }
    yield put(fetchError(error));
  }
}

function* updateFile({ payload }) {
  let { data, id } = payload;
  try {
    let response = yield call(update, `${baseUrl}/${id}`, data);
    if (response) {
      yield put(showFileMessage("File Modifier avec succées "));
    }
  } catch (error) {
    for (const errorMessage of error.message) {
      for (const keys of Object.keys(errorMessage.constraints)) {
        notification.error({
            message: errorMessage.constraints[keys],
            duration: 20
          });
      }
    }
    yield put(fetchError(error));
  }
}

function* deleteFile({ payload: { id, oldList } }) {
  try {
    const response = yield call(remove, `${baseUrl}/${id}`);
    const newList = oldList.filter((item) => item.id !== id);
    yield put(deleteFileSuccess(newList));
    if (response) {
      yield put(showFileMessage("File Supprimer avec succées "));
    }
  } catch (error) {
    for (const errorMessage of error.message) {
      for (const keys of Object.keys(errorMessage.constraints)) {
        notification.error({
            message: errorMessage.constraints[keys],
            duration: 20
          });
      }
    }
    yield put(fetchError(error));
  }
}

function* downloadFilePDFApi({ payload }) {
  try {
    const { id, name, createdAt } = payload;
    yield call(
      downloadFile({ fileName: name, createdAt, responseType: "blob" }),
      `${baseUrl}/uploads/${id}`
    );
  } catch (error) {
    console.log(error);
  }
}

function* downloadCertificateOfWithholdingTaxApi({ payload }) {
  try {
    const { id, name, createdAt } = payload;
    yield call(
      downloadFile({ fileName: "Certificate retenue à la source", createdAt, responseType: "blob" }),
      `${baseUrl}/generate-certificate/${id}`
    );
  } catch (error) {
    console.log(error);
  }
}

function* sendCertificateOfWithholdingTaxApi({ payload }) {
  try {
    const { id } = payload;
    yield call(get, `${baseUrl}/send/${id}`);
  } catch (error) {
    console.log(error);
  }
}

export function* addFile() {
  yield takeEvery(ADD_FILE, addNewFile);
}
function* updateSingleFile() {
  yield takeEvery(UPDATE_FILE, updateFile);
}
export function* getEveryFile() {
  yield takeEvery(GET_ALL_FILES, getFiles);
}
export function* getEveryMonthlyFile() {
  yield takeEvery(GET_ALL_MONTHLY_FILES, getMonthlyFiles);
}
export function* getMonthlyWithholdingTax() {
  yield takeEvery(FETCH_WITHHOLDING_TAX, getWithholdingTax)
}
export function* getMonthlyLocalEquipementTax() {
  yield takeEvery(FETCH_LOCAL_EQUIPEMENT_TAX, getLocalEquipementTax)
}
export function* getMonthlyLocalPurchaseTax() {
  yield takeEvery(FETCH_LOCAL_PURCHASE_TAX, getLocalPurchaseTax)
}
export function* geMonthlytImportedEquipementTax() {
  yield takeEvery(FETCH_IMPORTED_PURCHASE_TAX, getImportedEquipementTax)
}
export function* getMonthlyImportedPurchaseTax() {
  yield takeEvery(FETCH_IMPORTED_PURCHASE_TAX, getImportedPurchaseTax)
}
export function* getFile() {
  yield takeEvery(GET_FILE, getSingleFile);
}
export function* deleteFiles() {
  yield takeEvery(DELETE_FILES, deleteFile);
}
export function* downloadFilePDF() {
  yield takeEvery(DOWNLOAD_FILE_PDF, downloadFilePDFApi);
}
export function* sendCertificatePDF() {
  yield takeEvery(SEND_MAIL_CERTIFICATE_OF_WITHHOLDING_TAX, sendCertificateOfWithholdingTaxApi);
}
export function* downloadCertificatePDF() {
  yield takeEvery(DOWNLOAD_CERTIFICATE_OF_WITHHOLDING_TAX, downloadCertificateOfWithholdingTaxApi)
}
export default function* rootSaga() {
  yield all([
    fork(addFile),
    fork(getEveryFile),
    fork(getEveryMonthlyFile),
    fork(getMonthlyWithholdingTax),
    fork(getMonthlyLocalEquipementTax),
    fork(getMonthlyLocalPurchaseTax),
    fork(geMonthlytImportedEquipementTax),
    fork(getMonthlyImportedPurchaseTax),
    fork(getFile),
    fork(updateSingleFile),
    fork(deleteFiles),
    fork(downloadFilePDF),
    fork(sendCertificatePDF),
    fork(downloadCertificatePDF),
  ]);
}
