import {
  ADD_QUOTATION,
  ADD_QUOTATION_SUCCESS,
  DUPLICATE_QUOTATION,
  DUPLICATE_QUOTATION_SUCCESS,
  GET_QUOTATION,
  GET_QUOTATION_SUCCESS,
  GET_QUOTATION_REFERENCE,
  GET_QUOTATION_REFERENCE_SUCCESS,
  GET_ALL_QUOTATION,
  GET_ALL_QUOTATION_SUCCESS,
  SELECT_ALL_QUOTATIONS,
  SELECT_SINGLE_QUOTATION,
  UPDATE_QUOTATION,
  UPDATE_QUOTATION_SUCCESS,
  DELETE_QUOTATION,
  DELETE_QUOTATION_SUCCESS,
  ADD_QUOTATION_ITEM,
  ADD_QUOTATION_ITEM_SUCCESS,
  DELETE_QUOTATION_ITEM,
  DELETE_QUOTATION_ITEM_SUCCESS,
  DOWNLOAD_QUOTATION,
  DOWNLOAD_QUOTATION_SUCCESS,
  SEARCH_DOCTOR,
  SEARCH_DOCTOR_MASTER,
  SORT_DOCTORS_BY_NEWEST,
  SHOW_MODAL,
  HIDE_MODAL,
  SHOW_EDIT_MODAL,
  HIDE_EDIT_MODAL,
  SEND_MAIL_QUOTATION,
  SEND_MAIL_QUOTATION_SUCCESS,
} from "../../constants/ActionTypes";

const quotationsInitState = {
  addModal: false,
  updateModal: false,
  quotation: {},
  base: [],
  editable: [],
  loader: true,
  meta:{},
  quotationReference: {}
};

const quotationReducers = (state= quotationsInitState, action) => {
  let { type, payload } = action
  switch(type) {
    case GET_QUOTATION:
    case UPDATE_QUOTATION:
    case GET_QUOTATION_REFERENCE:
      return { ...state, loader: true };
    case GET_QUOTATION_SUCCESS:
    case UPDATE_QUOTATION_SUCCESS:
      return { 
        ...state,
        loader: false,
        quotation: action.payload, 
        base: payload,
      };
    case GET_QUOTATION_REFERENCE_SUCCESS: 
      return {
        ...state,
        loader: false,
        quotationReference: action.payload,
      }
    case ADD_QUOTATION_SUCCESS:
    case ADD_QUOTATION_ITEM_SUCCESS:
    case DUPLICATE_QUOTATION_SUCCESS:
      return state;
    case GET_ALL_QUOTATION_SUCCESS:
      //state.quotation= payload.data
      return { 
        ...state,
        //quotation: {},
        loader: false,
        base: payload.data, 
        editable: payload.data,
        meta:payload.meta
      };
    case ADD_QUOTATION:
      return {
        ...state,
        loader: true
      }
    case DOWNLOAD_QUOTATION:
    case SEND_MAIL_QUOTATION:
      return {
        ...state,
        // loader: true,
      };
    case DOWNLOAD_QUOTATION_SUCCESS:
      case SEND_MAIL_QUOTATION_SUCCESS:
      return {
        ...state,
        loader: false,
        quotation: action.payload,
      };
    case DELETE_QUOTATION:
    case DELETE_QUOTATION_ITEM:
      return {
        ...state,
        loader: true,
      };
    case DELETE_QUOTATION_SUCCESS:
    case DELETE_QUOTATION_ITEM_SUCCESS:
      state.quotation.quotationItems = action.payload
      return {
        ...state,
        loader: false,
        quotation: state.quotation,
        base: action.payload,
        editable: payload,
      }
    case SELECT_ALL_QUOTATIONS:
      let newState = state.editable.map((elm) => {
        let newElm = { ...elm, selected: payload };
        return newElm
      });
      return {
        ...state,
        editable: newState,
      };
    case SELECT_SINGLE_QUOTATION:
      let newValue= state.editable.map((elm) => {
        if (elm.id == payload.id) {
          let newElm= { ...elm, selected: payload.checked };
          return newElm;
        } else {
          return elm
        }
      });
      return {
        ...state,
        editable: newValue,
      };
    case SORT_DOCTORS_BY_NEWEST:
      let result= state.base.sort((x, y) => {
        return new Date(y.createdAt) - new Date(x.createdAt);
      });
      return { 
        ...state,
        editable: result,
      };
    case SEARCH_DOCTOR:
      return {
        ...state,
        editable: payload,
      };
    case SEARCH_DOCTOR_MASTER:
      return {
        ...state,
        editable: payload,
      };
    case SHOW_MODAL:
      return {
        ...state,
        addModal: true,
      };
    case HIDE_MODAL:
      return {
        ...state,
        addModal: false,
      };
    case SHOW_EDIT_MODAL:
      return {
        ...state,
        updateModal: true,
        quotation: action.payload
      };
    case HIDE_EDIT_MODAL:
      return {
        ...state,
        updateModal: false,
        quotation: {}
      };
    default:
      return state;
  }
};

export default quotationReducers;