import React, { useEffect } from "react";
import { Modal, Form, Input, Col, Select, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { COUNTRY_CODE, PROFESSIONS } from "../../constants/variables";
import {
  updateProfile,
  updateProfileSuccess,
} from "../../appRedux/actions/Profile";
import CustomUploadFile from "../../components/UploadFile";
import IntlMessages from "util/IntlMessages";

const { Option } = Select;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const EditProfile = ({ closeModal, visible, email, phone }) => {
  const ref = React.createRef();
  const { authUser } = useSelector(({ auth }) => auth);

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(authUser);
  }, [authUser]);

  const onSaveEdit = () => {
    form
      .validateFields()
      .then((values) => {
        const formData = new FormData();
        ref.current.HandleCreateEntity(formData, values);
        closeModal();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={closeModal}
      onOk={onSaveEdit}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          paddingBottom: "20px",
        }}
      ></div>
      <Form
        {...layout}
        name="basic"
        initialValues={{
          remember: true,
        }}
        form={form}
      >
        <Form.Item label={<IntlMessages id="avatar" />}>
          <CustomUploadFile
            isModal={true}
            ref={ref}
            url={"users"}
            successApiResponse={updateProfileSuccess}
            addEntityApi={updateProfile}
          />
        </Form.Item>
        <Form.Item
          name="givenName"
          label="Prenom"
          rules={[
            {
              type: "string",
              message: "Non valide!",
            },
          ]}
        >
          <Input placeholder="Prenom" />
        </Form.Item>
        <Form.Item
          name="familyName"
          label="Nom"
          rules={[
            {
              type: "string",
              message: "Non valide!",
            },
          ]}
        >
          <Input placeholder="Nom" />
        </Form.Item>
        <Form.Item name="email" label="E-mail">
          <Input disabled placeholder="E-mail" />
        </Form.Item>
        <Form.Item
          name="phone"
          label="Téléphone"
          initialValue={phone}
          rules={[
            {
              validator: (_, value) =>
                !isNaN(value)
                  ? Promise.resolve()
                  : Promise.reject("Choisir un numero valide !"),
            },
          ]}
        >
          <Input placeholder="Telephone" />
        </Form.Item>
        <Form.Item name="companyName" label="Nom de la société">
          <Input placeholder="Nom de la société" />
        </Form.Item>
        <Form.Item name="activity" label="Activité">
          <Input placeholder="Activité" />
        </Form.Item>
        <Form.Item name="legalStatus" label="Statut juridique">
          <Input placeholder="Statut juridique" />
        </Form.Item>
        <Form.Item name="croNumber" label={authUser && authUser.countryCode === "TN" ? "Registre de commerce" : "Numéro de siret"}>
          <Input placeholder={authUser.countryCode === "TN" ? "Registre de commerce" : "Numéro de siret"} />
        </Form.Item>
        <Form.Item name="administrativeOffice" label={authUser.countryCode === "TN" ? "Matricule Fiscale" : "Numéro de TVA"}>
          <Input placeholder={authUser.countryCode === "TN" ? "Matricule Fiscale" : "Numéro de TVA"} />
        </Form.Item>
        {authUser.countryCode === "TN" &&
        <Form.Item name="socialCapital" label="Capital Social">
          <Input placeholder="Capital Social" addonAfter={"TND"} />
        </Form.Item>
        }
        <Form.Item >
          <Input.Group>
            <Form.Item
              name={["address", "street"]}
              label={<IntlMessages id="user.address" />}
              rules={[
                {
                  type: "string",
                  message: "Non valide!",
                },
              ]}
            >
              <Input.TextArea  placeholder="Adresse" />
            </Form.Item>
            <Form.Item name={["address", "postCode"]} label="Code postal">
              <Input placeholder="Code postal" />
            </Form.Item>
            <Form.Item name={["address", "city"]} label="Ville">
              <Input placeholder="Ville" />
            </Form.Item>
          </Input.Group>
        </Form.Item>
        <Form.Item
          label="Pays"
          rules={[
            {
              type: "string",
              message: "Non valide!",
            },
          ]}
          name="countryCode"
        >
          <Select showSearch placeholder="Pays">
            {Array.isArray(COUNTRY_CODE) &&
              COUNTRY_CODE.map((county, index) => (
                <Option key={`county__item__${index}`} value={county.name}>
                  <IntlMessages id={county.label} />
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item name="webSite" label="Site web">
          <Input placeholder="Cite web" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditProfile;
