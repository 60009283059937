import {
  ADD_PRODUCT,
  ADD_PRODUCT_SUCCESS,
  GET_PRODUCT,
  GET_PRODUCT_SUCCESS,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_SUCCESS,
  DELETE_PRODUCTS,
  DELETE_PRODUCTS_SUCCESS,
  SEARCH_DOCTOR_MASTER,
  GET_ALL_PRODUCTS_SUCCESS,
  SEARCH_DOCTOR,
  SELECT_ALL_PRODUCTS,
  SELECT_SINGLE_PRODUCT,
  SORT_DOCTORS_BY_NEWEST,
  SHOW_MODAL,
  HIDE_MODAL,
  HIDE_EDIT_MODAL,
  SHOW_EDIT_MODAL,
} from "../../constants/ActionTypes";

const productsInitState = {
  addModal: false,
  updateModal: false,
  product: {},
  base: [],
  editable: [],
  loader: true,
  meta:{}
};

const ProductReducers = (state = productsInitState, action) => {
  let { type, payload } = action;
  switch (type) {
    case GET_PRODUCT:
    case UPDATE_PRODUCT:
      return { ...state, };
    case GET_PRODUCT_SUCCESS:
    case UPDATE_PRODUCT_SUCCESS:
      return { ...state, product: action.payload };
    case ADD_PRODUCT_SUCCESS:
      return state;
    case GET_ALL_PRODUCTS_SUCCESS:
      return { ...state, base: payload.data, editable: payload.data, meta:payload.meta, loader: false };
    case DELETE_PRODUCTS:
      return {
        ...state,
        loader: true,
      };
    case DELETE_PRODUCTS_SUCCESS:
      return {
        ...state,
        loader: false,
        base: action.payload,
        editable: action.payload,
      };
    case SELECT_ALL_PRODUCTS:
      let newState = state.editable.map((elm) => {
        let newElm = { ...elm, selected: payload };
        return newElm;
      });
      return {
        ...state,
        editable: newState,
      };
    case SELECT_SINGLE_PRODUCT:
      let newValue = state.editable.map((elm) => {
        if (elm.id === payload.id) {
          let newElm = { ...elm, selected: payload.checked };
          return newElm;
        } else {
          return elm;
        }
      });
      return { ...state, editable: newValue };
    case SORT_DOCTORS_BY_NEWEST:
      let result = state.base.sort((x, y) => {
        return new Date(y.createdAt) - new Date(x.createdAt);
      });
      return { ...state, editable: result };
    case SEARCH_DOCTOR:
      return { ...state, editable: payload };
    case SEARCH_DOCTOR_MASTER:
      return { ...state, editable: payload };
    case SHOW_MODAL:
      return { ...state, addModal: true };
    case HIDE_MODAL:
      return { ...state, addModal: false };
    case SHOW_EDIT_MODAL:
      return { ...state, updateModal: true, product: action.payload };
    case HIDE_EDIT_MODAL:
      return { ...state, updateModal: false, product: {} };
    default:
      return state;
  }
};

export default ProductReducers;
