import {
  ADD_ENTERPRISE,
  ADD_ENTERPRISE_SUCCESS,
  ADD_PROVIDER,
  ADD_PROVIDER_SUCCESS,
  GET_ALL_ENTERPRISES,
  GET_ALL_ENTERPRISES_SUCCESS,
  GET_ALL_PROVIDER,
  GET_ALL_PROVIDER_SUCCESS,
  UPDATE_ENTERPRISE,
  UPDATE_ENTERPRISE_SUCCESS,
  DELETE_ENTERPRISES,
  DELETE_ENTERPRISES_SUCCESS,
  GET_ENTERPRISE,
  GET_ENTERPRISE_SUCCESS,
  SEARCH_DOCTOR,
  SHOW_MESSAGE,
  SEARCH_DOCTOR_MASTER,
  GET_ALL_CUSTOMERS,
  GET_ALL_CUSTOMERS_SUCCESS
} from "../../constants/ActionTypes";

export const addEnterprise = (payload, redirect, path) => {
  return {
    type: ADD_ENTERPRISE,
    payload,
    redirect,
    path
  };
};

export const addEnterpriseSuccess = (payload) => {
  return {
    type: ADD_ENTERPRISE_SUCCESS,
    payload,
  };
};

export const updateEnterprise = (payload) => {
  return {
    type: UPDATE_ENTERPRISE,
    payload,
  };
};

export const updateEnterpriseSuccess = (payload) => {
  return {
    type: UPDATE_ENTERPRISE_SUCCESS,
    payload,
  };
};

export const getEnterprise = (payload) => {
  return {
    type: GET_ENTERPRISE,
    payload,
  };
};

export const getEnterpriseSuccess = (client) => {
  return {
    type: GET_ENTERPRISE_SUCCESS,
    payload: client,
  };
};

export const deleteEnterprise = (id, oldList) => {
  return {
    type: DELETE_ENTERPRISES,
    payload: { id, oldList },
  };
};

export const deleteEnterpriseSuccess = (payload) => {
  return {
    type: DELETE_ENTERPRISES_SUCCESS,
    payload,
  };
};

export const getListOfEnterprises = (filter = "PARTICULAR", page, take) => {
  return {
    type: GET_ALL_ENTERPRISES,
    filter,
    page,
    take
  };
};

export const getListOfEnterprisesSuccess = (client) => {
  return {
    type: GET_ALL_ENTERPRISES_SUCCESS,
    payload: client,
  };
};

export const getListOfAllCustomers = (filter = "PARTICULAR") => {
  return {
    type: GET_ALL_CUSTOMERS,
    filter
  };
};

export const getListOfAllCustomersSuccess = (client) => {
  return {
    type: GET_ALL_CUSTOMERS_SUCCESS,
    payload: client,
  };
};

export const getListOfAllProviders = (payload) => {
  return {
    type: GET_ALL_PROVIDER,
    payload
  }
}

export const getListOfAllProvidersSuccess = (payload) => {
  return {
    type: GET_ALL_PROVIDER_SUCCESS,
    payload
  }
}

export const showEnterpriseMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message,
  };
};

export const searchEnterprise = (value, list, filterOption = "id") => {
  try {
    let selected;
    if (value !== "") {
      selected = list.filter(
        (item, index) =>
          item[filterOption]
            .toString()
            .toLowerCase()
            .search(value.toLowerCase()) !== -1
      );
    } else {
      selected = list;
    }
    return {
      type: SEARCH_DOCTOR,
      payload: selected ? selected : [],
    };
  } catch (error) {
    console.error(error);
  }
};
export const _m = (list, selectedOption) => {
  let selected = [];
  if (Object.values(selectedOption)[0] === 1) {
    selected = list;
  } else {
    let dateNow = new Date();
    dateNow.setDate(dateNow.getDate() - 0); // - 0 ==> today
    selected = list.filter(
      (item) =>
        item[Object.keys(selectedOption)[0]].split("T")[0] ===
        dateNow.toISOString().split("T")[0]
    );
  }
  return {
    type: SEARCH_DOCTOR_MASTER,
    payload: selected ? selected : [],
  };
};
