import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { post, get, update, downloadFile, remove } from "../../axios/master";
import { 
  ADD_MONTHLY_REPORT,
  GET_ALL_MONTHLY_REPORT, 
  GET_MONTHLY_REPORT,
  DOWNLOAD_MONTHLY_REPORT,
  DELETE_MONTHLY_REPORT,
  UPDATE_MONTHLY_REPORT,
 } from "../../constants/ActionTypes";
import {
  getAllMonthlyReportSuccess,
  showMonthlyReportMessage,
  deleteMonthlyReportSuccess,
  getMonthlyReportSuccess
} from "../actions/MonthlyReport";
import { fetchError, hideDocModal } from "../actions/Common";
import { message, notification } from "antd";

const baseUrl = "monthly-reports";

function* addNewMonthlyReport({ payload }) {
  try {
    const addMonthlyReport = yield call(
      post, 
      `${baseUrl}`,
      payload
    );
    console.log(addMonthlyReport);
    if (addMonthlyReport) {
      yield put(showMonthlyReportMessage("Monthly Report Ajouter avec success"));
      yield put(hideDocModal());
    }
  } catch (error) {
    for (const errorMessage of error.message) {
      for (const keys of Object.keys(errorMessage.constraints)) {
        notification.error({
            message: errorMessage.constraints[keys],
            duration: 20
          });
      }
    }
    yield put(fetchError(error));
  }
}

function* getAllMonthlyReports({ page, take }) {
  let response = yield call(
    get,
    page && take
      ? `${baseUrl}?page=${page}&take=${take}`
      : `${baseUrl}`
  )

  if (response) {
    yield put(getAllMonthlyReportSuccess(response));
  } else {
    yield put(showMonthlyReportMessage(response.message));
  }
}

function* downloadMonthlyReportPDF({ payload }) {
  try {
    const { id, createdAt } = payload;
    yield call(
      downloadFile({ fileName: "Rapport-mensuel-de-performances", createdAt, responseType: "blob" }),
      `${baseUrl}/generate-document/${id}`
    );
  } catch (error) {
    console.log(error);
  }
}

function* deleteMonthlyReportApi({ payload: { id, oldList } }) {
  try {
    const response = yield call(remove, `${baseUrl}/${id}`);
    console.log(response);
    const newList = oldList.filter((item) => item.id !== id);
    yield put(deleteMonthlyReportSuccess(newList));
    if (response) {
      yield put(showMonthlyReportMessage("Monthly Report Supprimer avec succées "));
    }
  } catch (error) {
    for (const errorMessage of error.message) {
      for (const keys of Object.keys(errorMessage.constraints)) {
        notification.error({
            message: errorMessage.constraints[keys],
            duration: 20
          });
      }
    }
    yield put(fetchError(error));
  }
}

function* getSingleMonthlyReport({ payload }) {
  try {
    let singleMonthlyReport = yield call(get, `${baseUrl}/${payload}`);
    console.log("singleMonthlyReport", singleMonthlyReport);
    if (singleMonthlyReport) {
      yield put(getMonthlyReportSuccess(singleMonthlyReport));
    }
  } catch (error) {
    for (const errorMessage of error.message) {
      for (const keys of Object.keys(errorMessage.constraints)) {
        notification.error({
            message: errorMessage.constraints[keys],
            duration: 20
          });
      }
    }
    yield put(fetchError(error));
  }
}

function* updateMonthlyReport({ payload }) {
  let { data, id } = payload;

  try {
    let result = yield call(update, `${baseUrl}/${id}`, data);
    console.log("Resultat", result);
    if (result) {
      yield put(showMonthlyReportMessage("Monthly Report Modifier avec succées "));
    }
  } catch (error) {
    for (const errorMessage of error.message) {
      for (const keys of Object.keys(errorMessage.constraints)) {
        notification.error({
            message: errorMessage.constraints[keys],
            duration: 20
          });
      }
    }
    yield put(fetchError(error));
  }
}

export function* addMonthlyReport() {
  yield takeEvery(ADD_MONTHLY_REPORT, addNewMonthlyReport);
}

export function* getMonthlyReports() {
  yield takeEvery(GET_ALL_MONTHLY_REPORT, getAllMonthlyReports);
}

export function* getMonthlyReportPDF() {
  yield takeEvery(DOWNLOAD_MONTHLY_REPORT, downloadMonthlyReportPDF);
}

export function* deleteMonthlyReport() {
  yield takeEvery(DELETE_MONTHLY_REPORT, deleteMonthlyReportApi);
}

export function* getMonthlyReport() {
  yield takeEvery(GET_MONTHLY_REPORT, getSingleMonthlyReport);
}

export function* updateSingleMonthlyReport() {
  yield takeEvery(UPDATE_MONTHLY_REPORT, updateMonthlyReport);
}

export default function* rootSaga() {
  yield all([
    fork(addMonthlyReport),
    fork(getMonthlyReports),
    fork(getMonthlyReportPDF),
    fork(deleteMonthlyReport),
    fork(getMonthlyReport),
    fork(updateSingleMonthlyReport),
  ]);
}