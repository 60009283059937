import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import SidebarLogo from "./SidebarLogo";
import { ReactComponent as HomeIcon } from "../../assets/images/homeIcon.svg";
import { ReactComponent as ClientIcon } from "../../assets/images/clientIcon.svg";
import { ReactComponent as ProductIcon } from "../../assets/images/productIcon.svg";
import { ReactComponent as QuotationIcon } from "../../assets/images/quotationIcon.svg";
import { ReactComponent as FilePageIcon } from "../../assets/images/filePageIcon.svg";
import { ReactComponent as InvoiceIcon } from "../../assets/images/invoiceIcon.svg";
import { ReactComponent as DeliveryNoteIcon } from "../../assets/images/deliveryNoteIcon.svg";
import { ReactComponent as ReleaseNoteIcon } from "../../assets/images/releaseNoteIcon.svg";
import { ReactComponent as FeeNote } from "../../assets/images/feeNoteIcon.svg";
import { ReactComponent as BankingTransactionIcon } from "../../assets/images/bankingTransactionIcon.svg";
import { ReactComponent as RapportIcon } from "../../assets/images/rapportIcon.svg";

import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import IntlMessages from "util/IntlMessages";

import { useSelector } from "react-redux";

const MenuItemGroup = Menu.ItemGroup;

const SidebarContent = () => {
  let { pathname } = useSelector(({ common }) => common);
  let { authUser = {} } = useSelector(({ auth }) => auth);
  let { navStyle, themeType } = useSelector(({ settings }) => settings);
  let role = useSelector((state) => state.auth.role);
  const getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];

  const CustomMenu = (role) => {
    switch (role.toUpperCase()) {
      case "USER":
        return UserMenu();

      case "ACCOUNTANT":
        return AccountantMenu();
    }
  };

  const UserMenu = () => (
    <Menu
      defaultOpenKeys={[defaultOpenKeys]}
      selectedKeys={[selectedKeys]}
      theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
      mode="inline"
      className="width-max-content"
    >
      <Menu.Item key="homePage">
        <Link to="/">
          {/* <i className="icon icon-apps" /> */}
          <HomeIcon className="icon" />
          <span>
            <IntlMessages id="sidebar.homePage" />
          </span>
        </Link>
      </Menu.Item>
      <Menu.Item key="Client">
        <Link to="/clients">
          {/* <i className="icon icon-profile" /> */}
          <ClientIcon className="icon" />
          <span>
            <IntlMessages id="sidebar.enterprises" />
          </span>
        </Link>
      </Menu.Item>
      <Menu.Item key="Product">
        <Link to="/products">
          {/* <i className="icon icon-profile" /> */}
          <ProductIcon className="icon" />
          <span>
            <IntlMessages id="sidebar.products" />
          </span>
        </Link>
      </Menu.Item>
      <Menu.Item key="quotation">
        <Link to="/quotations">
          {/* <i className="icon icon-product-list" /> */}
          <QuotationIcon className="icon" />
          <span>
            <IntlMessages id="sidebar.quotations" />
          </span>
        </Link>
      </Menu.Item>
      <Menu.Item key="invoice">
        <Link to="/invoices">
          {/* <i className="icon icon-product-list" /> */}
          <InvoiceIcon className="icon" />
          <span>
            <IntlMessages id="sidebar.invoices" />
          </span>
        </Link>
      </Menu.Item>
      <Menu.Item key="deliveryNote">
        <Link to="/delivery-notes">
          {/* <i className="icon icon-product-list" /> */}
          <DeliveryNoteIcon className="icon" />
          <span>
            <IntlMessages id="sidebar.delivery.notes" />
          </span>
        </Link>
      </Menu.Item>
      <Menu.Item key="releaseNote">
        <Link to="/release-notes">
          {/* <i className="icon icon-product-list" /> */}
          <ReleaseNoteIcon className="icon" />
          <span>
            <IntlMessages id="sidebar.release.notes" />
          </span>
        </Link>
      </Menu.Item>
      <Menu.Item key="feeNote">
        <Link to="/fee-notes">
          {/* <i className="icon icon-product-list" /> */}
          <FeeNote className="icon" />
          <span>
            <IntlMessages id="sidebar.fee.notes" />
          </span>
        </Link>
      </Menu.Item>
      <Menu.Item key="bankingTransaction">
        <Link to="/banking-transactions">
          {/* <i className="icon icon-product-list" /> */}
          <BankingTransactionIcon className="icon" />
          <span>
            <IntlMessages id="sidebar.transactions" />
          </span>
        </Link>
      </Menu.Item>

      <Menu.Item key="file">
        <Link to="/files">
          {/* <i className="icon icon-profile" /> */}
          <FilePageIcon className="icon" />
          <span>
            <IntlMessages id="sidebar.files" />
          </span>
        </Link>
      </Menu.Item>
    </Menu>
  );

  const AccountantMenu = () => (
    <Menu
      defaultOpenKeys={[defaultOpenKeys]}
      selectedKeys={[selectedKeys]}
      theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
      mode="inline"
    >
      <Menu.Item key="clients">
        <Link to="/">
          {/* <i className="icon icon-profile" /> */}
          <ClientIcon className="icon" />
          <span>
            <IntlMessages id="sidebar.enterprises" />
          </span>
        </Link>
      </Menu.Item>
      <Menu.Item key="report">
        <Link to="/monthly-performance-report">
          {/* <i className="icon icon-profile" /> */}
          <RapportIcon className="icon" />
          <span>
            <IntlMessages id="sidebar.monthly.report" />
          </span>
        </Link>
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      <SidebarLogo />
      <div className="gx-sidebar-content">
        <div style={{ paddingTop: "20px", paddingLeft: "30px" }}></div>
        {/*<CustomScrollbars className="gx-layout-sider-scrollbar">
          {roleMenu(role)}
        </CustomScrollbars>*/}
        {CustomMenu(authUser.role)}
      </div>
    </>
  );
};

SidebarContent.propTypes = {};
export default SidebarContent;
