import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";


const ActivateAccount = () => {
  const { token } = useParams();
  const [data, setData] = useState();
  const [timer, setTimer] = useState(false);

  useEffect(() => {
    try {
      axios
        .post(
          `${process.env.REACT_APP_SERVER_URI}auth/validate-account/${token}`
        )
        .then((response) => {
          setData(response.data);
        });

        setTimeout(() => {
          setTimer(true)
        }, 3000);
        
    } catch (error) {}
  }, []);

  return <div>{timer && !data && <div>FAILED</div>}</div>;
};

export default ActivateAccount;
