import {
  ADD_FILE,
  ADD_FILE_SUCCESS,
  GET_FILE,
  GET_FILE_SUCCESS,
  UPDATE_FILE,
  UPDATE_FILE_SUCCESS,
  DELETE_FILES,
  DELETE_FILES_SUCCESS,
  SEARCH_DOCTOR_MASTER,
  GET_ALL_FILES,
  GET_ALL_FILES_SUCCESS,
  GET_ALL_MONTHLY_FILES_SUCCESS,
  SEARCH_DOCTOR,
  SELECT_ALL_FILES,
  SELECT_SINGLE_FILE,
  SORT_DOCTORS_BY_NEWEST,
  SHOW_MODAL,
  HIDE_MODAL,
  HIDE_EDIT_MODAL,
  SHOW_EDIT_MODAL,
  DOWNLOAD_FILE_PDF,
  DOWNLOAD_FILE_PDF_SUCCESS,
  DOWNLOAD_CERTIFICATE_OF_WITHHOLDING_TAX,
  DOWNLOAD_CERTIFICATE_OF_WITHHOLDING_TAX_SUCCESS,
  SEND_MAIL_CERTIFICATE_OF_WITHHOLDING_TAX,
  SEND_MAIL_CERTIFICATE_OF_WITHHOLDING_TAX_SUCCESS,
  FETCH_WITHHOLDING_TAX,
  FETCH_WITHHOLDING_TAX_SUCCESS,
  FETCH_LOCAL_EQUIPEMENT_TAX,
  FETCH_LOCAL_EQUIPEMENT_TAX_SUCCESS,
  FETCH_LOCAL_PURCHASE_TAX,
  FETCH_LOCAL_PURCHASE_TAX_SUCCESS,
  FETCH_IMPORTED_EQUIPEMENT_TAX,
  FETCH_IMPORTED_EQUIPEMENT_TAX_SUCCESS,
  FETCH_IMPORTED_PURCHASE_TAX,
  FETCH_IMPORTED_PURCHASE_TAX_SUCCESS
} from "../../constants/ActionTypes";

const filesInitState = {
  addModal: false,
  updateModal: false,
  file: {},
  base: [],
  editable: [],
  holdingTax: [],
  localEquip: [],
  localPur: [],
  impEquip: [],
  impPur: [],
  loader: true,
  meta: {},
};

const FileReducers = (state = filesInitState, action) => {
  let { type, payload } = action;
  switch (type) {
    case GET_FILE:
    case UPDATE_FILE:
    case FETCH_WITHHOLDING_TAX:
    case FETCH_LOCAL_EQUIPEMENT_TAX:
    case FETCH_LOCAL_PURCHASE_TAX:
    case FETCH_IMPORTED_EQUIPEMENT_TAX:
    case FETCH_IMPORTED_PURCHASE_TAX:
    case GET_ALL_FILES: 
      return { ...state, 
               file: payload, 
               //loader: true 
            };
    case FETCH_WITHHOLDING_TAX_SUCCESS:
      return { ...state, loader: false, holdingTax: action.payload }
    case FETCH_LOCAL_EQUIPEMENT_TAX_SUCCESS:
      return { ...state, loader: false, localEquip: action.payload }
    case FETCH_LOCAL_PURCHASE_TAX_SUCCESS:
      return { ...state, loader: false, localPur: action.payload }
    case FETCH_IMPORTED_EQUIPEMENT_TAX_SUCCESS:
      return { ...state, loader: false, impEquip: action.payload }
    case FETCH_IMPORTED_PURCHASE_TAX_SUCCESS:
      return { ...state, loader: false, impPur: action.payload }
    case GET_FILE_SUCCESS:
    case UPDATE_FILE_SUCCESS:
      return { ...state, file: action.payload, loader: false };
    case ADD_FILE_SUCCESS:
      return state;
    case GET_ALL_FILES_SUCCESS:
      return {
        ...state,
        base: payload.data,
        editable: payload.data,
        meta: payload.meta,
        loader: false,
      };
    case GET_ALL_MONTHLY_FILES_SUCCESS:
      return {
        ...state,
        base: action.payload,
        editable: action.payload,
      }
    case DOWNLOAD_FILE_PDF:
    case DOWNLOAD_CERTIFICATE_OF_WITHHOLDING_TAX:
    case SEND_MAIL_CERTIFICATE_OF_WITHHOLDING_TAX:
      return {
        ...state,
        //loader: true,
      };
    case DOWNLOAD_FILE_PDF_SUCCESS:
    case DOWNLOAD_CERTIFICATE_OF_WITHHOLDING_TAX_SUCCESS:
    case SEND_MAIL_CERTIFICATE_OF_WITHHOLDING_TAX_SUCCESS:
      return {
        ...state,
        loader: false,
        file: action.payload,
      };
    case DELETE_FILES:
      return {
        ...state,
        loader: true,
      };
    case DELETE_FILES_SUCCESS:
      return {
        ...state,
        loader: false,
        base: action.payload,
        editable: action.payload,
      };
    case SELECT_ALL_FILES:
      let newState = state.editable.map((elm) => {
        let newElm = { ...elm, selected: payload };
        return newElm;
      });
      return {
        ...state,
        editable: newState,
      };
    case SELECT_SINGLE_FILE:
      let newValue = state.editable.map((elm) => {
        if (elm.id === payload.id) {
          let newElm = { ...elm, selected: payload.checked };
          return newElm;
        } else {
          return elm;
        }
      });
      return { ...state, editable: newValue };
    case SORT_DOCTORS_BY_NEWEST:
      let result = state.base.sort((x, y) => {
        return new Date(y.createdAt) - new Date(x.createdAt);
      });
      return { ...state, editable: result };
    case SEARCH_DOCTOR:
      return { ...state, editable: payload };
    case SEARCH_DOCTOR_MASTER:
      return { ...state, editable: payload };
    case SHOW_MODAL:
      return { ...state, addModal: true };
    case HIDE_MODAL:
      return { ...state, addModal: false };
    case SHOW_EDIT_MODAL:
      return { ...state, updateModal: true, file: action.payload };
    case HIDE_EDIT_MODAL:
      return { ...state, updateModal: false, file: {} };
    default:
      return state;
  }
};

export default FileReducers;
