import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Popover } from "antd";
import { userSignOut } from "appRedux/actions/Auth";
import {
  LogoutOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar } from "antd";

import EditProfile from "../../components/editprofile";
import { Link, useHistory } from "react-router-dom";
import "./styles.css";
import IntlMessages from "util/IntlMessages";

const UserProfile = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const { authUser } = useSelector(({ auth }) => auth);

  let phone = localStorage.getItem("phone");
  let email = localStorage.getItem("email");
  const handleClose = () => {
    setVisible(false);
  };

  const handlePopoverClick = (e) => {
    e.stopPropagation(); // Prevent click event propagation
    setOpen(!open);
  };

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li
        onClick={() => {
          history.push({
            pathname: "/settings",
            search: "?profile",
          })
        }}
      >
        <SettingOutlined className="mr-8" />
        <IntlMessages id="navbar.settings" />
      </li>
      <div className="profile-underline"></div>
      <li onClick={() => dispatch(userSignOut())}>
        <LogoutOutlined className="mr-8" /> <IntlMessages id="logout" />
      </li>
    </ul>
  );

  return (
    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row cursor-pointer">
      <EditProfile
        visible={visible}
        closeModal={handleClose}
        email={email}
        phone={phone}
      />
      <div onClick={handlePopoverClick}>
        <Popover
          placement="bottomRight"
          content={userMenuOptions}
          trigger="click"
          visible={open}
        >
          {authUser.avatar ? (
            <Avatar
              src={`${process.env.REACT_APP_SERVER_URI}${authUser.avatar}`
                .split("\\")
                .join("/")}
              className="gx-size-40 gx-pointer gx-mr-3"
              alt="avatar"
            />
          ) : (
            <Avatar alt="avatar" size={42} icon={<UserOutlined />} />
          )}
        </Popover>
      </div>
    </div>
  );
};

export default UserProfile;
