import {
  GET_COMBINE_RELATED_INDICATORS,
  GET_COMBINE_RELATED_INDICATORS_SUCCESS,
  GET_DOCTOR_RELATED_INDICATORS,
  GET_DOCTOR_RELATED_INDICATORS_SUCCESS,
  GET_NOTES_BY_DOCTOR,
  GET_NOTES_BY_DOCTOR_SUCCESS,
  GET_PATHOLOGIE_RELATED_INDICATORS,
  GET_PATHOLOGIE_RELATED_INDICATORS_SUCCESS,
  GET_PRODUCT_RELATED_INDICATORS,
  GET_PRODUCT_RELATED_INDICATORS_SUCCESS,
  POST_SATISFACTION_GRAPH,
  POST_SATISFACTION_GRAPH_SUCCESS,
  // COVID
  GET_COVID_TRETEMENTS,
  GET_COVID_TRETEMENTS_SUCCESS,
  POST_SATISFACTION_GRAPH_MY_DOCUMENT_TEMPLATES,
  POST_SATISFACTION_GRAPH_MY_DOCUMENT_TEMPLATES_SUCCESS,
  POST_SATISFACTION_GRAPH_ALL_MY_DOCUMENT_TEMPLATES,
  POST_SATISFACTION_GRAPH_ALL_MY_DOCUMENT_TEMPLATES_SUCCESS,
  POST_SATURATION_O2_GRAPH,
  POST_SATURATION_O2_GRAPH_SUCCESS,
} from "../../constants/ActionTypes";

export const getCovidTretementsGraph = (payload) => {
  return {
    type: GET_COVID_TRETEMENTS,
    payload
  };
};
export const getCovidTretementsGraphSuccess = (list) => {
  return {
    type: GET_COVID_TRETEMENTS_SUCCESS,
    payload: list,
  };
};

export const getSatisfactionOfAllMyPatientsGraph = (id, periode) => {
  return {
    type: POST_SATISFACTION_GRAPH_ALL_MY_DOCUMENT_TEMPLATES,
    payload: { id, periode },
  };
};
export const getSatisfactionOfAllMyPatientsGraphSuccess = (list) => {
  return {
    type: POST_SATISFACTION_GRAPH_ALL_MY_DOCUMENT_TEMPLATES_SUCCESS,
    payload: list,
  };
};

export const getSatisfactionOfMyPatientsGraph = (id, periode) => {
  return {
    type: POST_SATISFACTION_GRAPH_MY_DOCUMENT_TEMPLATES,
    payload: { id, periode },
  };
};
export const getSatisfactionOfMyPatientsGraphSuccess = (id, list) => {
  return {
    type: POST_SATISFACTION_GRAPH_MY_DOCUMENT_TEMPLATES_SUCCESS,
    payload: list,
  };
};

export const getSaturationO2Graph = (id, periode) => {
  return {
    type: POST_SATURATION_O2_GRAPH,
    payload: { id, periode },
  };
};
export const getSaturationO2GraphSuccess = (list) => {
  return {
    type: POST_SATURATION_O2_GRAPH_SUCCESS,
    payload: list,
  };
};

export const getSatisfactionGraph = (id, periode) => {
  return {
    type: POST_SATISFACTION_GRAPH,
    payload: { id, periode },
  };
};
export const getSatisfactionGraphSuccess = (list) => {
  return {
    type: POST_SATISFACTION_GRAPH_SUCCESS,
    payload: list,
  };
};

export const getProductGraph = (list) => {
  return {
    type: GET_PRODUCT_RELATED_INDICATORS,
    payload: list,
  };
};
export const getPathologieGraph = (list) => {
  return {
    type: GET_PATHOLOGIE_RELATED_INDICATORS,
    payload: list,
  };
};
export const getProductGrahSuccess = (list) => {
  return {
    type: GET_PRODUCT_RELATED_INDICATORS_SUCCESS,
    payload: list,
  };
};

export const getPathologieGraphSuccess = (list) => {
  return {
    type: GET_PATHOLOGIE_RELATED_INDICATORS_SUCCESS,
    payload: list,
  };
};

export const getCombinedGraph = (list) => {
  return {
    type: GET_COMBINE_RELATED_INDICATORS,
    payload: list,
  };
};
export const getCombinedGraphSuccess = (list) => {
  return {
    type: GET_COMBINE_RELATED_INDICATORS_SUCCESS,
    payload: list,
  };
};

export const getDcotorGraph = () => {
  return {
    type: GET_DOCTOR_RELATED_INDICATORS,
  };
};
export const getDcotorGraphSuccess = (list) => {
  return {
    type: GET_DOCTOR_RELATED_INDICATORS_SUCCESS,
    payload: list,
  };
};

export const getNotesGraph = () => {
  return {
    type: GET_NOTES_BY_DOCTOR,
  };
};

export const getNotesGraphSucces = (list) => {
  return {
    type: GET_NOTES_BY_DOCTOR_SUCCESS,
    payload: list,
  };
};
