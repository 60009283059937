import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { post, get, update, remove, downloadFile } from "../../axios/master";
import { push } from 'react-router-redux';
import {
  ADD_QUOTATION,
  ADD_QUOTATION_ITEM,
  GET_ALL_QUOTATION,
  GET_QUOTATION,
  GET_QUOTATION_REFERENCE,
  UPDATE_QUOTATION,
  DELETE_QUOTATION,
  DELETE_QUOTATION_ITEM,
  DOWNLOAD_QUOTATION,
  DUPLICATE_QUOTATION,
  SEND_MAIL_QUOTATION
} from "../../constants/ActionTypes";
import {
  getListOfQuotationSuccess,
  getQuotationSuccess,
  getQuotationReferenceSuccess,
  showQuotationMessage,
  deleteQuotationSuccess,
  deleteQuotationItemSuccess,
  downloadQuotationSuccess
} from "../actions/Quotation";
import { fetchError, hideDocModal } from "../actions/Common";
import { message, notification } from "antd";


const baseUrl = "quotations";


function* addNewQuotation({ payload }) {
  try {
    const addQuotation = yield call(post, baseUrl, payload);
    if (addQuotation) {
      yield put(showQuotationMessage("Devis ajouter avec succées"));
      yield put(hideDocModal);
      notification.success({
        message: "Devis ajouté avec succès",
        duration: 20
      });
      yield put(push('/quotations'));
    }
  } catch (error) {
    for (const errorMessage of error.message) {
      for (const keys of Object.keys(errorMessage.constraints)) {
        notification.error({
          message: errorMessage.constraints[keys],
          duration: 20
        });
      }
    }
    yield put(fetchError(error));
    notification.error({
      message: 'L\'ajout du devis a échoué',
      duration: 20
    });
  }
}

function* duplicateSampleQuotation({ payload }) {
  try {
    const duplicateQuotation = yield call(post, `${baseUrl}/duplicate-quotation/${payload}`);
    if (duplicateQuotation) {
      yield put(showQuotationMessage("duplicate devis avec succès"))
    }
  } catch (error) {
    for (const errorMessage of error.message) {
      for (const keys of Object.keys(errorMessage.constraints)) {
        notification.error({
          message: errorMessage.constraints[keys],
          duration: 20
        });
      }
    }
    yield put(fetchError(error))
  }
}

function* getQuotations({ page, take }) {
  let response = yield call(get, page && take ? `${baseUrl}?page=${page}&take=${take}` : `${baseUrl}`);
  if (response) {
    yield put(getListOfQuotationSuccess(response));
  } else {
    yield put(showQuotationMessage(response.message));
  }
}

function* getSingleQuotation({ payload }) {
  try {
    let singleQuotation = yield call(get, `${baseUrl}/${payload}`);
    if (singleQuotation) {
      yield put(getQuotationSuccess(singleQuotation))
    }
  } catch (error) {
    for (const errorMessage of error.message) {
      for (const keys of Object.keys(errorMessage.constraints)) {
        notification.error({
          message: errorMessage.constraints[keys],
          duration: 20
        });
      }
    }
    yield put(fetchError(error));
  }
}

function* getQuotationReferenceApi() {
  try {
    let quotationReference = yield call(get, `${baseUrl}/quotation-reference`);
    if (quotationReference) {
      yield put(getQuotationReferenceSuccess(quotationReference));
    }
  } catch (error) {
    for (const errorMessage of error.message) {
      for (const keys of Object.keys(errorMessage.constraints)) {
        notification.error({
          message: errorMessage.constraints[keys],
          duration: 20
        });
      }
    }
    yield put(fetchError(error));
  }
}

function* updateQuotation({ payload }) {
  let { id, data } = payload;

  try {
    let result = yield call(update, `${baseUrl}/${id}`, data);
    if (result) {
      yield put(showQuotationMessage("Quotation Modifier avec succées"));
    }
  } catch (error) {
    for (const errorMessage of error.message) {
      for (const keys of Object.keys(errorMessage.constraints)) {
        notification.error({
          message: errorMessage.constraints[keys],
          duration: 20
        });
      }
    }
    yield put(fetchError(error));
  }
}

function* sendQuotationPDFApi({ payload }) {
  try {
    const { id, page, take } = payload;
    yield call(get, `${baseUrl}/send/${id}`);
    notification.success({
      message: "L'envoi du devis s'est effectué avec succès",
      duration: 20
    });
    let response = yield call(get, page && take ? `${baseUrl}?page=${page}&take=${take}` : `${baseUrl}`);
    if (response) {
      yield put(getListOfQuotationSuccess(response));
    } else {
      yield put(showQuotationMessage(response.message));
    }
  } catch (error) {
    console.log(error);
    notification.error({
      message: "L'envoi du devis a échoué",
      duration: 20
    });
  }
}

function* deleteQuotation({ payload: { id, oldList } }) {
  try {
    const response = yield call(remove, `${baseUrl}/${id}`);
    const newList = oldList.filter((item) => item.id !== id);
    yield put(deleteQuotationSuccess(newList))
    if (response) {
      yield put(showQuotationMessage("Quotation supprimer avec succées"));
    }
  } catch (error) {
    for (const errorMessage of error.message) {
      for (const keys of Object.keys(errorMessage.constraints)) {
        notification.error({
          message: errorMessage.constraints[keys],
          duration: 20
        });
      }
    }
    yield put(fetchError(error));
  }
}

function* addNewQuotationItem({ payload }) {
  try {
    const addQuotationItem = yield call(post, `${baseUrl}/quotation-item`, payload);
    if (addQuotationItem) {
      yield put(showQuotationMessage("Quotation item ajouter avec succées"));
      // yield put(hideDocModal);
    }
  } catch (error) {
    for (const errorMessage of error.message) {
      for (const keys of Object.keys(errorMessage.constraints)) {
        notification.error({
          message: errorMessage.constraints[keys],
          duration: 20
        });
      }
    }
    yield put(fetchError(error));
  }
}

function* deleteQuotationItem({ payload: { id, quotationId, oldList } }) {
  try {
    const response = yield call(remove, quotationId ? `${baseUrl}/quotation-item/${id}?quotationId=${quotationId}` : `${baseUrl}/quotation-item/${id}`);
    const newList = oldList.filter((item) => item.id !== id);
    yield put(deleteQuotationItemSuccess(newList))
    if (response) {
      yield put(showQuotationMessage("Quotation item supprimer avec succées"))
    }
  } catch (error) {
    if (error && error.statusCode === 400) {
      notification.error({
        message: `le devis doit contenir au moins un produit`,
        duration: 20
      });
    } else {
      for (const errorMessage of error.message) {
        for (const keys of Object.keys(errorMessage.constraints)) {
          notification.error({
            message: errorMessage.constraints[keys],
            duration: 20
          });
        }
      }
    }
    yield put(fetchError(error))
  }
}

function* downloadQuotationPDF({ payload }) {
  try {
    const { id, name, createdAt } = payload;
    const s3signedUrl = yield call(get, `${baseUrl}/generate-document/${id}`, payload);
    window.open(s3signedUrl)
    notification.success({
      message: "Le téléchargement du devis s'est effectué avec succès",
      duration: 20
    });
  } catch (error) {
    console.log(error);
    notification.error({
      message: "Le téléchargement du devis à échoué",
      duration: 20
    });
  }
}

export function* sendQuotationPDF() {
  yield takeEvery(SEND_MAIL_QUOTATION, sendQuotationPDFApi);
}

export function* addQuotation() {
  yield takeEvery(ADD_QUOTATION, addNewQuotation);
}

export function* duplicateQuotation() {
  yield takeEvery(DUPLICATE_QUOTATION, duplicateSampleQuotation);
}

export function* getAllQuotations() {
  yield takeEvery(GET_ALL_QUOTATION, getQuotations)
}

export function* getQuotation() {
  yield takeEvery(GET_QUOTATION, getSingleQuotation)
}

export function* getNextQuotationReference() {
  yield takeEvery(GET_QUOTATION_REFERENCE, getQuotationReferenceApi)
}

export function* updateOneQuotation() {
  yield takeEvery(UPDATE_QUOTATION, updateQuotation)
}

export function* deleteOneQuotation() {
  yield takeEvery(DELETE_QUOTATION, deleteQuotation)
}

export function* addQuotationItem() {
  yield takeEvery(ADD_QUOTATION_ITEM, addNewQuotationItem)
}

export function* deleteOneQuotationItem() {
  yield takeEvery(DELETE_QUOTATION_ITEM, deleteQuotationItem)
}

export function* downloadOneQuotationPDF() {
  yield takeEvery(DOWNLOAD_QUOTATION, downloadQuotationPDF)
}

export default function* rootSaga() {
  yield all([
    fork(addQuotation),
    fork(sendQuotationPDF),
    fork(duplicateQuotation),
    fork(getAllQuotations),
    fork(getQuotation),
    fork(getNextQuotationReference),
    fork(updateOneQuotation),
    fork(deleteOneQuotation),
    fork(addQuotationItem),
    fork(deleteOneQuotationItem),
    fork(downloadOneQuotationPDF),
  ]);
}