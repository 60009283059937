import {
  ADD_FILE,
  ADD_FILE_SUCCESS,
  GET_ALL_FILES,
  GET_ALL_FILES_SUCCESS,
  GET_ALL_MONTHLY_FILES,
  GET_ALL_MONTHLY_FILES_SUCCESS,
  UPDATE_FILE,
  UPDATE_FILE_SUCCESS,
  DELETE_FILES,
  DELETE_FILES_SUCCESS,
  GET_FILE,
  GET_FILE_SUCCESS,
  SEARCH_DOCTOR,
  SHOW_MESSAGE,
  SEARCH_DOCTOR_MASTER,
  DOWNLOAD_FILE_PDF,
  DOWNLOAD_FILE_PDF_SUCCESS,
  DOWNLOAD_CERTIFICATE_OF_WITHHOLDING_TAX,
  DOWNLOAD_CERTIFICATE_OF_WITHHOLDING_TAX_SUCCESS,
  SEND_MAIL_CERTIFICATE_OF_WITHHOLDING_TAX,
  SEND_MAIL_CERTIFICATE_OF_WITHHOLDING_TAX_SUCCESS,
  FETCH_WITHHOLDING_TAX,
  FETCH_WITHHOLDING_TAX_SUCCESS,
  FETCH_LOCAL_EQUIPEMENT_TAX,
  FETCH_LOCAL_EQUIPEMENT_TAX_SUCCESS,
  FETCH_LOCAL_PURCHASE_TAX,
  FETCH_LOCAL_PURCHASE_TAX_SUCCESS,
  FETCH_IMPORTED_EQUIPEMENT_TAX,
  FETCH_IMPORTED_EQUIPEMENT_TAX_SUCCESS,
  FETCH_IMPORTED_PURCHASE_TAX,
  FETCH_IMPORTED_PURCHASE_TAX_SUCCESS
} from "../../constants/ActionTypes";

export const addFile = (payload) => {
  return {
    type: ADD_FILE,
    payload,
  };
};

export const addFileSuccess = (payload) => {
  return {
    type: ADD_FILE_SUCCESS,
    payload,
  };
};

export const downloadFilePDF = (payload) => {
  return {
    type: DOWNLOAD_FILE_PDF,
    payload,
  };
};

export const downloadFilePDFSuccess = (payload) => {
  return {
    type: DOWNLOAD_FILE_PDF_SUCCESS,
    payload,
  };
};

export const downloadCertificateOfWithholdingTax = (payload) => {
  return {
    type: DOWNLOAD_CERTIFICATE_OF_WITHHOLDING_TAX,
    payload
  }
}

export const downloadCertificateOfWithholdingTaxSuccess = (payload) => {
  return {
    type: DOWNLOAD_CERTIFICATE_OF_WITHHOLDING_TAX_SUCCESS,
    payload
  }
}

export const sendMailCertificateOfWithholdingTax = (payload) => {
  return {
    type: SEND_MAIL_CERTIFICATE_OF_WITHHOLDING_TAX,
    payload
  }
}

export const sendMailCertificateOfWithholdingTaxSuccess = (payload) => {
  return {
    type : SEND_MAIL_CERTIFICATE_OF_WITHHOLDING_TAX_SUCCESS,
    payload
  }
}

export const updateFile = (payload) => {
  return {
    type: UPDATE_FILE,
    payload,
  };
};

export const updateFileSuccess = (payload) => {
  return {
    type: UPDATE_FILE_SUCCESS,
    payload,
  };
};

export const getFile = (payload) => {
  return {
    type: GET_FILE,
    payload,
  };
};

export const getFileSuccess = (file) => {
  return {
    type: GET_FILE_SUCCESS,
    payload: file,
  };
};

export const deleteFile = (id, oldList) => {
  return {
    type: DELETE_FILES,
    payload: { id, oldList },
  };
};

export const deleteFileSuccess = (payload) => {
  return {
    type: DELETE_FILES_SUCCESS,
    payload,
  };
};

export const getListOfFiles = (page, take) => {
  return {
    type: GET_ALL_FILES,
    page,
    take
  };
};

export const getListOfFilesSuccess = (file) => {
  return {
    type: GET_ALL_FILES_SUCCESS,
    payload: file,
  };
};

export const getListOfMonthlyFiles = (yearMonth, clientId) => {
  return {
    type: GET_ALL_MONTHLY_FILES,
    yearMonth,
    clientId
  };
};

export const getListOfMonthlyFilesSuccess = (file) => {
  return {
    type: GET_ALL_MONTHLY_FILES_SUCCESS,
    payload: file,
  }
}

export const fetchWithholdingTax = (clientId, month) => {
  return {
    type: FETCH_WITHHOLDING_TAX,
    clientId,
    month
  }
}

export const fetchWithholdingTaxSuccess = (payload) => {
  return {
    type: FETCH_WITHHOLDING_TAX_SUCCESS,
    payload
  }
}

export const fetchLocalEquipementTax = (clientId, month) => {
  return {
    type: FETCH_LOCAL_EQUIPEMENT_TAX,
    clientId,
    month
  }
}

export const fetchLocalEquipementTaxSuccess = (payload) => {
  return {
    type: FETCH_LOCAL_EQUIPEMENT_TAX_SUCCESS,
    payload
  }
}

export const fetchLocalPurchaseTax = (clientId, month) => {
  return {
    type: FETCH_LOCAL_PURCHASE_TAX,
    clientId,
    month
  }
}

export const fetchLocalPurchaseTaxSuccess = (payload) => {
  return {
    type: FETCH_LOCAL_PURCHASE_TAX_SUCCESS,
    payload
  }
}

export const fetchImportedEquipementTax = (clientId, month) => {
  return {
    type: FETCH_IMPORTED_EQUIPEMENT_TAX,
    clientId,
    month
  }
}

export const fetchImportedEquipementTaxSuccess = (payload) => {
  return {
    type: FETCH_IMPORTED_EQUIPEMENT_TAX_SUCCESS,
    payload
  }
}

export const fetchImportedPurchaseTax = (clientId, month) => {
  return {
    type: FETCH_IMPORTED_PURCHASE_TAX,
    clientId,
    month
  }
}

export const fetchImportedPurchaseTaxSuccess = (payload) => {
  return {
    type: FETCH_IMPORTED_PURCHASE_TAX_SUCCESS,
    payload
  }
}

export const showFileMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message,
  };
};

export const searchFile = (value, list, filterOption = "title") => {
  try {
    let selected;
    if (value !== "") {
      selected = list.filter(
        (item, index) =>
          item[filterOption]
            .toString()
            .toString()
            .toLowerCase()
            .search(value.toLowerCase()) !== -1
      );
    } else {
      selected = list;
    }
    return {
      type: SEARCH_DOCTOR,
      payload: selected ? selected : [],
    };
  } catch (error) {
    console.error(error);
  }
};
export const _m = (list, selectedOption) => {
  let selected = [];
  if (Object.values(selectedOption)[0] === 1) {
    selected = list;
  } else {
    let dateNow = new Date();
    dateNow.setDate(dateNow.getDate() - 0); // - 0 ==> today
    selected = list.filter(
      (item) =>
        item[Object.keys(selectedOption)[0]].split("T")[0] ===
        dateNow.toISOString().split("T")[0]
    );
  }
  return {
    type: SEARCH_DOCTOR_MASTER,
    payload: selected ? selected : [],
  };
};
