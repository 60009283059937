import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";


const SidebarLogo = () => {
  const dispatch = useDispatch();
  const {width, themeType} = useSelector(({settings}) => settings);
  let navStyle = useSelector(({settings}) => settings.navStyle);
  if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
    navStyle = NAV_STYLE_DRAWER;
  }
  return (
    <div className="gx-layout-sider-header">          
      <div className="logo">
      <Link to="/" className="gx-site-logo gx-pr-5">
        {navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_MINI_SIDEBAR && width >= TAB_SIZE ?

          <div>
          <img alt="" src={require("assets/images/app-logo.svg")}/>
          <img alt="" src={require("assets/images/line-separator.svg")}/>
          </div>

           :
          themeType === THEME_TYPE_LITE ?
            <img alt="" src={require("assets/images/app-logo.svg")}/> :
            <div><img alt="" src={require("assets/images/app-logo.svg")}/>
            <img alt="" src={require("assets/images/line-separator.svg")}/>
            </div>}

      </Link>
      </div>
    </div>
  );
};

export default SidebarLogo;
