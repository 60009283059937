import {
  UPDATE_FORM_STATE_SUCCESS,
} from "../../constants/ActionTypes";

const initialState = {
  formState: {},
 };
 
const FormStateReducer = (state = initialState, action) => {
  switch (action.type) {
     case UPDATE_FORM_STATE_SUCCESS:
       return {
         ...state,
         formState: action.payload,
       };
     default:
       return state;
  }
 }
 
 export default FormStateReducer