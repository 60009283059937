import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { fetchError } from "../actions/Common";
import { post } from "../../axios/master";
import {
  getCombinedGraphSuccess,
  getDcotorGraphSuccess,
  getNotesGraphSucces,
  getPathologieGraphSuccess,
  getProductGrahSuccess,
  getSatisfactionGraphSuccess,
  getCovidTretementsGraphSuccess,
  getSaturationO2GraphSuccess,
  getSatisfactionOfMyPatientsGraphSuccess,
  getSatisfactionOfAllMyPatientsGraphSuccess,
} from "../actions/Graphs";
import {
  GET_COMBINE_RELATED_INDICATORS,
  GET_DOCTOR_RELATED_INDICATORS,
  GET_NOTES_BY_DOCTOR,
  GET_PATHOLOGIE_RELATED_INDICATORS,
  GET_PRODUCT_RELATED_INDICATORS,
  POST_SATISFACTION_GRAPH,
  GET_COVID_TRETEMENTS,
  POST_SATURATION_O2_GRAPH,
  POST_SATISFACTION_GRAPH_MY_DOCUMENT_TEMPLATES,
  POST_SATISFACTION_GRAPH_ALL_MY_DOCUMENT_TEMPLATES,
} from "../../constants/ActionTypes";

const productsGraph = `statistics/product-related-indicators`;
const pathologiesGraph = `statistics/pathology-related-indicators`;
const combinedGraph = `statistics/combined-treatments-indicators`;
const doctorGraph = "statistics/patients-per-doctor";
const satisfaction = "statistics/satisfaction-patient-per-doctor";
const notes = "statistics/notes-per-doctor";
const covidTretementsGraph = `statistics/product-efficiency-covid`;
const covidSaturationO2Graph = `statistics/saturationO2-all-patient-per-day-covid-per-doctor`;
const covidSatisfactionPatientPerDoctorGraph = `statistics/saturationO2-per-patient-per-doctor`;
const covidSatisfactionAllMyPatientPerDoctorGraph = `statistics/satisfaction-all-patient-per-doctor`;

function* getCovidTretementsGraphApi({ payload }) {
  try {
    const response = yield call(post, `${covidTretementsGraph}`, payload);
    yield put(getCovidTretementsGraphSuccess(response));
  } catch (error) {
    yield put(fetchError(error));
  }
}
function* getDocNotesApi() {
  try {
    const response = yield call(post, `${notes}`);
    yield put(getNotesGraphSucces(response.data));
  } catch (error) {
    yield put(fetchError(error));
  }
}
function* getGraphProductsApi({ payload }) {
  try {
    const response = yield call(post, `${productsGraph}`, { products: payload });
    yield put(getProductGrahSuccess(response.data));
  } catch (error) {
    yield put(fetchError(error));
  }
}
function* getGraphPathologieApi({ payload }) {
  try {
    const response = yield call(post, `${pathologiesGraph}`, {
      pattern: payload,
    });
    yield put(getPathologieGraphSuccess(response.data));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* getGraphCombinedApi({ payload }) {
  try {
    const response = yield call(post, `${combinedGraph}`, { products: payload });
    yield put(getCombinedGraphSuccess(response.data));
  } catch (error) {
    yield put(fetchError(error));
  }
}
function* getGraphDoctorApi({ payload }) {
  try {
    const response = yield call(post, `${doctorGraph}`, payload);
    yield put(getDcotorGraphSuccess(response.data));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* getSatisfactionGraphApi({ payload }) {
  try {
    const { id, periode } = payload
    let response
    if (id !== null) {
      response = yield call(post, `${satisfaction}/${id}`, periode);
    } else {
      response = yield call(post, `${satisfaction}`, periode);
    }
    yield put(getSatisfactionGraphSuccess(response.data));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* getSatisfactionOfMyPatientsGraphApi({ payload }) {
  try {
    const { id, periode } = payload
    const response = yield call(post, `${covidSatisfactionPatientPerDoctorGraph}/${id}`, periode);
    yield put(getSatisfactionOfMyPatientsGraphSuccess(response.data));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* getSatisfactionOfAllMyPatientsGraphApi({ payload }) {
  try {
    const { periode } = payload
    const response = yield call(post, `${covidSatisfactionAllMyPatientPerDoctorGraph}`, periode);
    yield put(getSatisfactionOfAllMyPatientsGraphSuccess(response.data));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* getSaturationO2Api({ payload }) {
  try {
    const { periode } = payload
    const response = yield call(post, `${covidSaturationO2Graph}`, periode);
    yield put(getSaturationO2GraphSuccess(response.data));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* getCovidTretementsGraph() {
  yield takeEvery(GET_COVID_TRETEMENTS, getCovidTretementsGraphApi)
}
function* getDoctorNotes() {
  yield takeEvery(GET_NOTES_BY_DOCTOR, getDocNotesApi)
}
function* getDcotorGraph() {
  yield takeEvery(GET_DOCTOR_RELATED_INDICATORS, getGraphDoctorApi);
}
export function* getGraphProducts() {
  yield takeEvery(GET_PRODUCT_RELATED_INDICATORS, getGraphProductsApi);
}

export function* getGraphPathologie() {
  yield takeEvery(GET_PATHOLOGIE_RELATED_INDICATORS, getGraphPathologieApi);
}

export function* getGraphCombined() {
  yield takeEvery(GET_COMBINE_RELATED_INDICATORS, getGraphCombinedApi);
}

export function* getSatisfactionGraph() {
  yield takeEvery(POST_SATISFACTION_GRAPH, getSatisfactionGraphApi);
}

export function* getSatisfactionOfMyPatientsGraph() {
  yield takeEvery(POST_SATISFACTION_GRAPH_MY_DOCUMENT_TEMPLATES, getSatisfactionOfMyPatientsGraphApi);
}
export function* getSatisfactionOfAllMyPatientsGraph() {
  yield takeEvery(POST_SATISFACTION_GRAPH_ALL_MY_DOCUMENT_TEMPLATES, getSatisfactionOfAllMyPatientsGraphApi);
}
export function* getSaturationO2() {
  yield takeEvery(POST_SATURATION_O2_GRAPH, getSaturationO2Api);
}

export default function* rootSaga() {
  yield all([
    fork(getCovidTretementsGraph),
    fork(getGraphProducts),
    fork(getGraphCombined),
    fork(getGraphPathologie),
    fork(getDcotorGraph),
    fork(getSatisfactionGraph),
    fork(getDoctorNotes),
    fork(getSatisfactionOfMyPatientsGraph),
    fork(getSatisfactionOfAllMyPatientsGraph),
    fork(getSaturationO2),
  ]);
}
