import {
  ADD_MONTHLY_REPORT,
  ADD_MONTHLY_REPORT_SUCCESS,
  GET_ALL_MONTHLY_REPORT,
  GET_ALL_MONTHLY_REPORT_SUCCESS,
  DOWNLOAD_MONTHLY_REPORT,
  DOWNLOAD_MONTHLY_REPORT_SUCCESS,
  DELETE_MONTHLY_REPORT,
  DELETE_MONTHLY_REPORT_SUCCESS,
  GET_MONTHLY_REPORT,
  GET_MONTHLY_REPORT_SUCCESS,
  UPDATE_MONTHLY_REPORT,
  UPDATE_MONTHLY_REPORT_SUCCESS,
} from "../../constants/ActionTypes";

const monthlyReportsInitState = {
  addModal: false,
  updateModal: false,
  monthlyReport: {},
  base: [],
  editable: [],
  loader: true,
  meta: {},
};

const MonthlyReportsReducers = (state = monthlyReportsInitState, action) => {
  let {type, payload} = action;
  switch (type) {
    case ADD_MONTHLY_REPORT_SUCCESS:
      return state;
    case GET_ALL_MONTHLY_REPORT:
    case GET_MONTHLY_REPORT:
    case UPDATE_MONTHLY_REPORT: 
      return { ...state };
    case GET_MONTHLY_REPORT_SUCCESS:
    case UPDATE_MONTHLY_REPORT_SUCCESS:
      return { ...state, monthlyReport: action.payload, loader: false };
    case GET_ALL_MONTHLY_REPORT_SUCCESS:
      return { 
        ...state,
        base: payload.data,
        editable: payload.data,
        meta: payload.meta,
        loader: false
      }
    case DOWNLOAD_MONTHLY_REPORT:
      return {
        ...state,
        loader: true,
      };
    case DOWNLOAD_MONTHLY_REPORT_SUCCESS:
      return {
        ...state,
        loader: false,
        monthlyReports: action.payload,
      };
    case DELETE_MONTHLY_REPORT:
      return {
        ...state,
        loader: true,
      };
    case DELETE_MONTHLY_REPORT_SUCCESS:
      return {
        ...state,
        loader: false,
        base: action.payload,
        editable: payload,
      };
    default: 
      return state;
  };
};

export default MonthlyReportsReducers;