const languageData = [
 
  {
    languageId: 'french',
    locale: 'fr',
    name: 'Français',
    icon: 'fr'
  },
  {
    languageId: 'saudi-arabia',
    locale: 'ar',
    name: 'عربي',
    icon: 'sa'
  }

];
export default languageData;
