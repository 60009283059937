import {
  ADD_BANKING_TRANSACTION,
  ADD_BANKING_TRANSACTION_SUCCESS,
  GET_ALL_BANKING_TRANSACTION,
  GET_ALL_BANKING_TRANSACTION_SUCCESS,
  GET_ALL_MONTHLY_BANKING_TRANSACTION,
  GET_ALL_MONTHLY_BANKING_TRANSACTION_SUCCESS,
  UPDATE_BANKING_TRANSACTION,
  UPDATE_BANKING_TRANSACTION_SUCCESS,
  DELETE_BANKING_TRANSACTION,
  DELETE_BANKING_TRANSACTION_SUCCESS,
  GET_BANKING_TRANSACTION,
  GET_BANKING_TRANSACTION_SUCCESS,
  SHOW_MESSAGE,
  SEARCH_DOCTOR,
  SEARCH_DOCTOR_MASTER
} from "../../constants/ActionTypes";

export const addBankingTransaction = (payload) => {
  return {
    type: ADD_BANKING_TRANSACTION,
    payload,
  };
};

export const addBankingTransactionSuccess = (payload) => {
  return {
    type: ADD_BANKING_TRANSACTION_SUCCESS,
    payload,
  };
};

export const getAllBankingTransaction = (after, before) => {
  return {
    type: GET_ALL_BANKING_TRANSACTION,
    after,
    before
  };
};

export const getAllBankingTransactionSuccess = (transactions) => {
  return {
    type: GET_ALL_BANKING_TRANSACTION_SUCCESS,
    payload: transactions
  };
};

export const getListOfMonthlyBankingTransaction = (yearMonth, clientId) => {
  return {
    type: GET_ALL_MONTHLY_BANKING_TRANSACTION,
    yearMonth,
    clientId
  }
}

export const getListOfMonthlyBankingTransactionSuccess = (transactions) => {
  return {
    type: GET_ALL_MONTHLY_BANKING_TRANSACTION_SUCCESS,
    payload: transactions
  }
}

export const getSingleBankingTransaction = (payload) => {
  return {
    type: GET_BANKING_TRANSACTION,
    payload
  };
};

export const getSingleBankingTransactionSuccess = (bankingTransaction) => {
  return {
    type: GET_BANKING_TRANSACTION_SUCCESS,
    payload: bankingTransaction,
  };
};

export const updateBankingTransaction = (payload) => {
  return {
    type: UPDATE_BANKING_TRANSACTION,
    payload,
  };
};

export const updateBankingTransactionSuccess = (payload) => {
  return {
    type: UPDATE_BANKING_TRANSACTION_SUCCESS,
    payload,
  };
};

export const deleteBankingTransaction = (id, oldList) => {
  return {
    type: DELETE_BANKING_TRANSACTION,
    payload: {id, oldList}
  };
};

export const deleteBankingTransactionSuccess = (payload) => {
  return {
    type: DELETE_BANKING_TRANSACTION_SUCCESS,
    payload,
  };
};

export const showBankingTransactionMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message,
  };
};

export const searchBankingTransaction = (value, list, filterOption = "description") => {
  try {
    let selected;
    if (value !== ""){
      selected = list.filter(
        (item, index) => 
          item[filterOption]
            .toString()
            .toString()
            .toLowerCase()
            .search(value.toLowerCase()) !== -1
      );
    } else {
      selected = list;
    }
    return {
      type: SEARCH_DOCTOR,
      payload: selected ? selected: [],
    };
  } catch (error) {
    console.error(error);
  }
};

export const _m = (list, selectedOption) => {
  let selected = [];
  if (Object.values(selectedOption)[0] === 1) {
    selected = list;
  } else {
    let dateNow = new Date();
    dateNow.setDate(dateNow.getDate() - 0);
    selected = list.filter(
      (item) => 
        item[Object.keys(selectedOption)[0]].splite("T")[0] ===
        dateNow.toISOString().split("T")[0]
    );
  }
  return {
    type: SEARCH_DOCTOR_MASTER,
    payload: selected? selected: [],
  };
};