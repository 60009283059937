import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { post, get, update, remove, downloadFile } from "../../axios/master";
import { push } from 'react-router-redux';

import {
  ADD_INVOICE,
  ADD_INVOICE_FROM_DELIVERY_NOTE,
  TRANSFERT_QUOTATION_TO_INVOICE,
  DUPLICATE_INVOICE,
  DELETE_INVOICE,
  DOWNLOAD_INVOICE,
  GET_ALL_INVOICE,
  GET_ALL_MONTHLY_INVOICE,
  FETECH_SALES_REVENUE,
  GET_ALL_DELIVERY_NOTE,
  GET_INVOICE,
  GET_INVOICES_REFERENCES,
  UPDATE_INVOICE,
  UPDATE_META_INVOICE,
  PAYE_INVOICE,
  SEND_MAIL_INVOICE,
} from "../../constants/ActionTypes";
import {
  getListOfInvoicesSuccess,
  getListOfMonthlyInvoicesSuccess,
  fetechSalesRevenueSuccess,
  getListOfDeliveryNoteSuccess,
  getInvoiceSuccess,
  getInvoicesReferencesSuccess,
  showInvoiceMessage,
  deleteInvoiceSuccess,
  payeInvoiceSuccess,
  updateMetaInvoiceSuccess,
  downloadInvoiceSuccess,
} from "../actions/Invoice";
import { fetchError, hideDocModal } from "../actions/Common";
import { message, notification } from "antd";

const baseUrl = "invoices";
const paye = "paye";
const meta = "meta";

function* addNewInvoice({ payload, invoiceType, redirect, path }) {
  try {
    const addInvoice = yield call(
      post,
      invoiceType
        ? `${baseUrl}/create-invoice-with-type?invoiceType=${invoiceType}`
        : `${baseUrl}/create-invoice-with-type`,
      payload
    );
    if (addInvoice) {
      yield put(showInvoiceMessage("Circuit Ajouter avec succées "));
      yield put(hideDocModal());
      switch (invoiceType) {
        case "SALES":
          notification.success({
            message: "Facture ajoutée avec succès",
            duration: 20
          });
          yield put(push('/invoices'));
          break;
        case "DELIVERY_NOTES":
          notification.success({
            message: "Bon de livraison ajouté avec succès",
            duration: 20
          });
          yield put(push('/delivery-notes'));
          break;
        case "RELEASE_NOTE":
          notification.success({
            message: "Bon de sortie ajouté avec succès",
            duration: 20
          });
          yield put(push('/release-notes'));
          break;
        case "FEE_NOTE":
          notification.success({
            message: "Note de honoraires ajoutée avec succès",
            duration: 20
          });
          yield put(push('/fee-notes'));
          break;
        default:
          break;
      }
    }
    if (redirect) {
      redirect(path)
    }
  } catch (error) {
    for (const errorMessage of error.message) {
      for (const keys of Object.keys(errorMessage.constraints)) {
        notification.error({
          message: errorMessage.constraints[keys],
          duration: 20
        });
      }
    }
    yield put(fetchError(error));
    switch (invoiceType) {
      case "SALES":
        notification.error({
          message: "L\'ajout de la facture a échoué",
          duration: 20
        });
        break;
      case "DELIVERY_NOTES":
        notification.error({
          message: "L\'ajout du Bon de livraison a échoué",
          duration: 20
        });
        break;
      case "RELEASE_NOTE":
        notification.error({
          message: "L\'ajout du Bon de sortie a échoué",
          duration: 20
        });
        break;
      case "FEE_NOTE":
        notification.error({
          message: "L\'ajout de la note de honoraires a échoué",
          duration: 20
        });
        break;
      default:
        break;
    }
  }
}

function* addInvoiceFromDeliveryNote({ payload, redirect, path }) {
  try {
    const addNewInvoiceFromDN = yield call(
      post,
      `${baseUrl}/invoice-of-delivery-notes`,
      payload
    );
    if (redirect) {
      redirect(path)
    }
    if (addNewInvoiceFromDN) {
      yield put(showInvoiceMessage("Invoice Added with success"));
      notification.success({
        message: "Facture ajoutée avec succès",
        duration: 20
      });
      yield put(push('/invoices'));
    }
  } catch (error) {
    for (const errorMessage of error.message) {
      for (const keys of Object.keys(errorMessage.constraints)) {
        notification.error({
          message: errorMessage.constraints[keys],
          duration: 20
        });
      }
    }
    yield put(fetchError(error));
    notification.error({
      message: "L\'ajout de la facture a échoué",
      duration: 20
    });
  }
}

function* duplicateOneInvoice({ payload }) {
  try {
    const duplicateInvoice = yield call(
      post,
      `${baseUrl}/duplicate-invoice/${payload}`
    );
    if (duplicateInvoice) {
      yield put(showInvoiceMessage("Dupliquer facture avec succèss"));
    }
  } catch (error) {
    for (const errorMessage of error.message) {
      for (const keys of Object.keys(errorMessage.constraints)) {
        notification.error({
          message: errorMessage.constraints[keys],
          duration: 20
        });
      }
    }
    yield put(fetchError(error));
  }
}

function* transfertSampleQuotationToInvoice({ payload }) {
  //let {id, data} = payload
  try {
    const quotationToInvoice = yield call(
      post,
      `${baseUrl}/transfer-quotation-to-invoice/${payload}`
    );
    if (quotationToInvoice) {
      yield put(showInvoiceMessage("Transfert devis sur facture avec succèss"));
      yield put(push('/invoices'));
    }
  } catch (error) {
    for (const errorMessage of error.message) {
      for (const keys of Object.keys(errorMessage.constraints)) {
        notification.error({
          message: errorMessage.constraints[keys],
          duration: 20
        });
      }
    }
    yield put(fetchError(error));
  }
}

function* getInvoicesWithStatus({ filter, page, take }) {
  let response = yield call(
    get,
    page && take
      ? `${baseUrl}?status=${filter}&page=${page}&take=${take}`
      : `${baseUrl}?status=${filter}`
  );
  if (response) {
    yield put(getListOfInvoicesSuccess(response));
  } else {
    yield put(showInvoiceMessage(response.message));
  }
}

function* getInvoices({ page, take, invoiceType }) {
  let response = yield call(
    get,
    page && take && invoiceType
      ? `${baseUrl}?invoiceType=${invoiceType}&page=${page}&take=${take}`
      : `${baseUrl}`
  );

  if (response) {
    yield put(getListOfInvoicesSuccess(response));
  } else {
    yield put(showInvoiceMessage(response.message));
  }
}

function* getMonthlyInvoices({ yearMonth, clientId }) {
  let response = yield call(
    get,
    yearMonth
      ? `${baseUrl}/monthly?clientId=${clientId}&yearMonth=${yearMonth}`
      : `${baseUrl}/monthly?clientId=${clientId}`
  );

  if (response) {
    yield put(getListOfMonthlyInvoicesSuccess(response));
  } else {
    yield put(showInvoiceMessage(response.message));
  }
}

function* getSalesRevenueByUser({ month, clientId }) {

  if (month && clientId) {
    try {
      let response = yield call(
        get,
        `${baseUrl}/sales-revenue?userId=${clientId}&currentMonth=${month}`
      );
      if (response) {
        yield put(fetechSalesRevenueSuccess(response));
      }
    } catch (error) {
      notification.error({
        message: error,
        duration: 20
      });
    }
  }
}

function* getAllOfDeliveryNotes({ payload }) {
  let response = yield call(get, `${baseUrl}/delivery-note`);

  if (response) {
    yield put(getListOfDeliveryNoteSuccess(response));
  } else {
    yield put(showInvoiceMessage(response.message));
  }
}

function* downloadInvoicePDF({ payload }) {
  try {
    const { id, name, createdAt } = payload;
    const s3signedUrl = yield call(get, `${baseUrl}/generate-document/${id}`, payload);
    window.open(s3signedUrl)
    notification.success({
      message: "Le téléchargement de la facture s'est effectué avec succès",
      duration: 20
    });
  } catch (error) {
    console.log(error);
    notification.error({
      message: "Le téléchargement de la facture à échoué",
      duration: 20
    });
  }
}

function* sendInvoicePDFApi({ payload }) {
  try {
    const { id } = payload;
    yield call(get, `${baseUrl}/send/${id}`);
    notification.success({
      message: "L'envoi de la facture s'est effectué avec succès",
      duration: 20
    });
  } catch (error) {
    notification.error({
      message: error,
      duration: 20
    });
  }
}

function* getSingleInvoice({ payload }) {
  try {
    let singleInvoice = yield call(get, `${baseUrl}/${payload}`);
    if (singleInvoice) {
      yield put(getInvoiceSuccess(singleInvoice));
    }
  } catch (error) {
    for (const errorMessage of error.message) {
      for (const keys of Object.keys(errorMessage.constraints)) {
        notification.error({
          message: errorMessage.constraints[keys],
          duration: 20
        });
      }
    }
    yield put(fetchError(error));
  }
}

function* getInvoicesReferencesApi() {
  try {
    let invoicesReferences = yield call(get, `${baseUrl}/invoice-reference`);
    if (invoicesReferences) {
      yield put(getInvoicesReferencesSuccess(invoicesReferences));
    }
  } catch (error) {
    for (const errorMessage of error.message) {
      for (const keys of Object.keys(errorMessage.constraints)) {
        notification.error({
          message: errorMessage.constraints[keys],
          duration: 20
        });
      }
    }
    yield put(fetchError(error));
  }
}

function* updateInvoice({ payload }) {
  let { data, id } = payload;

  try {
    let result = yield call(update, `${baseUrl}/${id}`, data);
    if (result) {
      yield put(showInvoiceMessage("Invoice Modifier avec succées "));
    }
  } catch (error) {
    for (const errorMessage of error.message) {
      for (const keys of Object.keys(errorMessage.constraints)) {
        notification.error({
          message: errorMessage.constraints[keys],
          duration: 20
        });
      }
    }
    yield put(fetchError(error));
  }
}

function* updateMetaInvoiceApi({ payload }) {
  let { data, id } = payload;
  try {
    let result = yield call(update, `${baseUrl}/${meta}/${id}`, data);
    if (result) {
      yield put(showInvoiceMessage("Invoice meta modifier avec succées"));
    }
  } catch (error) {
    for (const errorMessage of error.message) {
      for (const keys of Object.keys(errorMessage.constraints)) {
        notification.error({
          message: errorMessage.constraints[keys],
          duration: 20
        });
      }
    }
    yield put(fetchError(error));
  }
}

function* payeInvoiceApi({ payload }) {
  let { id } = payload;
  try {
    let result = yield call(update, `${baseUrl}/${paye}/${id}`);
    if (result) {
      yield put(payeInvoiceSuccess(result.data));
      yield put(showInvoiceMessage("Invoice Modifier avec succées "));
    }
  } catch (error) {
    for (const errorMessage of error.message) {
      for (const keys of Object.keys(errorMessage.constraints)) {
        notification.error({
          message: errorMessage.constraints[keys],
          duration: 20
        });
      }
    }
    yield put(fetchError(error));
  }
}

function* deleteInvoicesApi({ payload: { id, oldList } }) {
  try {
    const response = yield call(remove, `${baseUrl}/${id}`);
    const newList = oldList.filter((item) => item.id !== id);
    yield put(deleteInvoiceSuccess(newList));
    if (response) {
      yield put(showInvoiceMessage("Invoice Supprimer avec succées "));
    }
  } catch (error) {
    for (const errorMessage of error.message) {
      for (const keys of Object.keys(errorMessage.constraints)) {
        notification.error({
          message: errorMessage.constraints[keys],
          duration: 20
        });
      }
    }
    yield put(fetchError(error));
  }
}
export function* addInvoice() {
  yield takeEvery(ADD_INVOICE, addNewInvoice);
}
export function* addNewInvoiceFromDeliveryNotes() {
  yield takeEvery(ADD_INVOICE_FROM_DELIVERY_NOTE, addInvoiceFromDeliveryNote);
}
export function* transfertQuotationToInvoice() {
  yield takeEvery(
    TRANSFERT_QUOTATION_TO_INVOICE,
    transfertSampleQuotationToInvoice
  );
}
export function* duplicateInvoice() {
  yield takeEvery(DUPLICATE_INVOICE, duplicateOneInvoice);
}
export function* updateSingleInvoice() {
  yield takeEvery(UPDATE_INVOICE, updateInvoice);
}
export function* updateMetaInvoice() {
  yield takeEvery(UPDATE_META_INVOICE, updateMetaInvoiceApi);
}
export function* getEveryInvoice() {
  yield takeEvery(GET_ALL_INVOICE, getInvoices);
}
export function* getEveryMonthlyInvoice() {
  yield takeEvery(GET_ALL_MONTHLY_INVOICE, getMonthlyInvoices);
}
export function* fetechSalesRevenueByUser() {
  yield takeEvery(FETECH_SALES_REVENUE, getSalesRevenueByUser)
}
export function* getEveryDeliveryNotes() {
  yield takeEvery(GET_ALL_DELIVERY_NOTE, getAllOfDeliveryNotes);
}
export function* getInvoicePDF() {
  yield takeEvery(DOWNLOAD_INVOICE, downloadInvoicePDF);
}
export function* sendInvoicePDF() {
  yield takeEvery(SEND_MAIL_INVOICE, sendInvoicePDFApi);
}
export function* getInvoice() {
  yield takeEvery(GET_INVOICE, getSingleInvoice);
}
export function* getNextInvoicesReferences() {
  yield takeEvery(GET_INVOICES_REFERENCES, getInvoicesReferencesApi);
}
export function* deleteInvoices() {
  yield takeEvery(DELETE_INVOICE, deleteInvoicesApi);
}
export function* payeInvoice() {
  yield takeEvery(PAYE_INVOICE, payeInvoiceApi);
}
export default function* rootSaga() {
  yield all([
    fork(addInvoice),
    fork(addNewInvoiceFromDeliveryNotes),
    fork(sendInvoicePDF),
    fork(duplicateInvoice),
    fork(transfertQuotationToInvoice),
    fork(getInvoicePDF),
    fork(getEveryInvoice),
    fork(getEveryMonthlyInvoice),
    fork(fetechSalesRevenueByUser),
    fork(getEveryDeliveryNotes),
    fork(getInvoice),
    fork(getNextInvoicesReferences),
    fork(updateSingleInvoice),
    fork(updateMetaInvoice),
    fork(deleteInvoices),
    fork(payeInvoice),
  ]);
}
