import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { UPDATE_PROFILE, UPDATE_PAYMENT_TERMS, IDENTIFIED_USER } from "../../constants/ActionTypes";
import { update } from "../../axios/master";
import { message, notification } from "antd";
import { updateProfileSuccess, updatePaymentTermsSuccess, identifiedUserSuccess } from "../actions/Profile";
import { getCurrentUserSuccess } from "../actions/Auth";
import { fetchError, hideDocModal } from "../actions/Common";

const updateCurentUserUrl = `users/me`;
const updatePaymentTermsUserUrl = `users/payment_terms`;
const isIdentifiedUser = `users/isIdentified`

function* updateProfileApi({ payload }) {
  try {
    const user = yield call(update, updateCurentUserUrl, payload);
    yield put(updateProfileSuccess(user));
    yield put(getCurrentUserSuccess(user));
    console.log("USER", user);
  } catch (error) {
    notification.error({
      message: error,
      duration: 20
    });;
  }
}

function* updatePaymentTermsApi({ payload }) {
  try {
    const user = yield call(update, updatePaymentTermsUserUrl, payload);
    yield put(updatePaymentTermsSuccess(user));
    yield put(getCurrentUserSuccess(user));
  } catch (error) {
    notification.error({
      message: error,
      duration: 20
    });
  }
}

function* identifiedUserApi({ payload, redirect, path }) {
  try {
    const user = yield call(update, isIdentifiedUser, payload);
    yield put(identifiedUserSuccess(user));
    yield put(getCurrentUserSuccess(user.updatedUser));

    if (redirect) {
      redirect(path)
    }

    notification.success({
      message: "Votre compte est activé",
    duration: 20
  });
  } catch (error) {
    if (error && error.response && error.response.data) {
      // Check if the error response contains data
      const errorMessage = error.response.data.message;
      if (errorMessage) {
        notification.error({
          message: errorMessage,
          duration: 20
        });
      } else {
        notification.error({
          message: "Une erreur s'est produite. Veuillez réessayer plus tard.",
          duration: 20
        });
      }
    } else {
      console.log('error', error);
      notification.error({
        message: "Une erreur s'est produite. Veuillez réessayer plus tard.",
        duration: 20
      });
    }
    yield put(fetchError(error));
  }
}

export function* updateProfile() {
  yield takeEvery(UPDATE_PROFILE, updateProfileApi);
}

export function* updatePaymentTerms() {
  yield takeEvery(UPDATE_PAYMENT_TERMS, updatePaymentTermsApi)
}

export function* identifiedUser() {
  yield takeEvery(IDENTIFIED_USER, identifiedUserApi);
}

export default function* rootSaga() {
  yield all([fork(updateProfile)]);
  yield all([fork(updatePaymentTerms)]);
  yield all([fork(identifiedUser)]);
}
