import { 
  UPDATE_PROFILE, 
  UPDATE_PROFILE_SUCCESS, 
  UPDATE_PAYMENT_TERMS, 
  UPDATE_PAYMENT_TERMS_SUCCESS,
  IDENTIFIED_USER,
  IDENTIFIED_USER_SUCCES,
} from "../../constants/ActionTypes";

const profilesInitState = {
  addModal: false,
  updateModal: false,
  profile: {},
  loader: true,
};

const ProductReducers = (state = profilesInitState, action) => {
  let { type, payload } = action;
  switch (type) {
    case UPDATE_PROFILE:
    case UPDATE_PAYMENT_TERMS:
    case IDENTIFIED_USER:
      return { ...state };
    case UPDATE_PROFILE_SUCCESS:
    case UPDATE_PAYMENT_TERMS_SUCCESS: 
    case IDENTIFIED_USER_SUCCES:
      return { ...state, profile: action.payload };
    default:
      return state;
  }
};

export default ProductReducers;
