import { all } from "redux-saga/effects";
import authSagas from "./Auth";
import productSaga from "./Products";
import fileSaga from "./File";
import enterpriseSaga from "./Enterprise";
import taxClassSaga from "./TaxClass";
import documentTemplateSagas from "./DocumentTemplate";
import invoiceSagas from "./Invoice";
import quotationSagas from "./Quotation";
import monthlyReportSagas from "./MonthlyReport";
import bankingTransactionSagas from "./BankingTransaction";
import bankAccountSagas from "./BankAccount";
import ProfileSagas from "./Profile";
import UserSagas from "./User";
import UnitySagas from "./Unity";
import watchSaveFormState from "./FormState";

import GraphSaga from "./Graphs";
import StatSaga from "./Stats";
export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    productSaga(),
    fileSaga(),
    enterpriseSaga(),
    taxClassSaga(),
    invoiceSagas(),
    quotationSagas(),
    monthlyReportSagas(),
    documentTemplateSagas(),
    bankingTransactionSagas(),
    bankAccountSagas(),
    ProfileSagas(),
    GraphSaga(),
    StatSaga(),
    UserSagas(),
    UnitySagas(),
    watchSaveFormState()
  ]);
}
