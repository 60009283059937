import {
  TOP_CLIENTS,
  TOP_CLIENTS_SUCCESS,
  TOP_PRODUCTS,
  TOP_PRODUCTS_SUCCESS,
  YEARLY_SALES,
  YEARLY_SALES_SUCCESS,
  INVOICES_PAYMENT_STATUSES,
  INVOICES_PAYMENT_STATUSES_SUCCESS,
} from "../../constants/ActionTypes";

export const topClients = () => {
  return {
    type: TOP_CLIENTS,
  };
};

export const topClientsSuccess = (payload) => {
  return {
    type: TOP_CLIENTS_SUCCESS,
    payload,
  };
};

export const topProducts = (payload) => {
  return {
    type: TOP_PRODUCTS,
    payload: payload,
  };
};

export const topProductsSuccess = (payload) => {
  return {
    type: TOP_PRODUCTS_SUCCESS,
    payload,
  };
};

export const yearlySales = (payload) => {
  return {
    type: YEARLY_SALES,
    payload: payload,
  };
};

export const yearlySalesSuccess = (payload) => {
  return {
    type: YEARLY_SALES_SUCCESS,
    payload,
  };
};

export const invoicesPaymentStatus = (payload) => {
  return {
    type: INVOICES_PAYMENT_STATUSES,
    payload,
  };
};

export const invoicesPaymentStatusSuccess = (payload) => {
  return {
    type: INVOICES_PAYMENT_STATUSES_SUCCESS,
    payload,
  };
};
