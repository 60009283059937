import {
  ADD_MONTHLY_REPORT,
  ADD_MONTHLY_REPORT_SUCCESS,
  GET_ALL_MONTHLY_REPORT,
  GET_ALL_MONTHLY_REPORT_SUCCESS,
  DOWNLOAD_MONTHLY_REPORT,
  DOWNLOAD_MONTHLY_REPORT_SUCCESS,
  DELETE_MONTHLY_REPORT,
  DELETE_MONTHLY_REPORT_SUCCESS,
  GET_MONTHLY_REPORT,
  GET_MONTHLY_REPORT_SUCCESS,
  UPDATE_MONTHLY_REPORT,
  UPDATE_MONTHLY_REPORT_SUCCESS,
  SHOW_MESSAGE,
} from "../../constants/ActionTypes";

export const addMonthlyReport = ( payload ) => {
  return {
    type: ADD_MONTHLY_REPORT,
    payload,
  };
};

export const addMonthlyReportSuccess = ( payload ) => {
  return {
    type: ADD_MONTHLY_REPORT_SUCCESS,
    payload,
  };
};

export const getAllMonthlyReport = ( page, take ) => {
  return {
    type: GET_ALL_MONTHLY_REPORT,
    page,
    take
  }
}

export const getAllMonthlyReportSuccess = ( monthlyReports ) => {
  return {
    type: GET_ALL_MONTHLY_REPORT_SUCCESS,
    payload: monthlyReports
  };
};

export const downloadMonthlyReport = (payload) => {
  return {
    type: DOWNLOAD_MONTHLY_REPORT,
    payload,
  };
};

export const downloadMonthlyReportSuccess = (payload) => {
  return {
    type: DOWNLOAD_MONTHLY_REPORT_SUCCESS,
    payload,
  };
};

export const deleteMonthlyReport = (id, oldList) => {
  return {
    type: DELETE_MONTHLY_REPORT,
    payload: { id, oldList },
  };
};

export const deleteMonthlyReportSuccess = (payload) => {
  return {
    type: DELETE_MONTHLY_REPORT_SUCCESS,
    payload,
  };
};

export const updateMonthlyReport = (payload) => {
  return {
    type: UPDATE_MONTHLY_REPORT,
    payload,
  };
};

export const updateMonthlyReportSuccess = (payload) => {
  return {
    type: UPDATE_MONTHLY_REPORT_SUCCESS,
    payload,
  };
};

export const getMonthlyReport = (payload) => {
  return {
    type: GET_MONTHLY_REPORT,
    payload,
  };
};

export const getMonthlyReportSuccess = (monthlyReport) => {
  return {
    type: GET_MONTHLY_REPORT_SUCCESS,
    payload: monthlyReport,
  };
};

export const showMonthlyReportMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message,
  };
};