import {
	ADD_QUOTATION,
	ADD_QUOTATION_SUCCESS,
	DUPLICATE_QUOTATION,
	DUPLICATE_QUOTATION_SUCCESS,
	GET_ALL_QUOTATION,
	GET_ALL_QUOTATION_SUCCESS,
	GET_QUOTATION_REFERENCE,
	GET_QUOTATION_REFERENCE_SUCCESS,
	UPDATE_QUOTATION,
	UPDATE_QUOTATION_SUCCESS,
	DELETE_QUOTATION,
	DELETE_QUOTATION_SUCCESS,
	ADD_QUOTATION_ITEM,
	ADD_QUOTATION_ITEM_SUCCESS,
	DELETE_QUOTATION_ITEM,
	DELETE_QUOTATION_ITEM_SUCCESS,
	GET_QUOTATION,
	GET_QUOTATION_SUCCESS,
	DOWNLOAD_QUOTATION,
	DOWNLOAD_QUOTATION_SUCCESS,
	SHOW_MESSAGE,
	SEARCH_DOCTOR,
	SEARCH_DOCTOR_MASTER,
	SEND_MAIL_QUOTATION,
	SEND_MAIL_QUOTATION_SUCCESS
} from "../../constants/ActionTypes";

export const addQuotation = (payload) => {
	return {
		type: ADD_QUOTATION,
		payload,
	};
};

export const addQuotationSuccess = (payload) => {
	return {
		type: ADD_QUOTATION_SUCCESS,
		payload,
	};
};

export const duplicateQuotation = (payload) => {
	return {
		type: DUPLICATE_QUOTATION,
		payload,
	};
};

export const duplicateQuotationSuccess = (payload) => {
	return {
		type: DUPLICATE_QUOTATION_SUCCESS,
		payload,
	};
};

export const addQuotationItem = (payload) => {
	return {
		type: ADD_QUOTATION_ITEM,
		payload,
	};
};

export const addQuotationItemSuccess = (payload) => {
	return {
		type: ADD_QUOTATION_ITEM_SUCCESS,
		payload,
	};
};

export const sendQuotation = (payload) => {
	return {
	  type: SEND_MAIL_QUOTATION,
	  payload,
	};
  };
  
  export const sendQuotationSuccess = (payload) => {
	return {
	  type: SEND_MAIL_QUOTATION_SUCCESS,
	  payload,
	};
  };

export const getListOfQuotation = (page, take) => {
	return {
		type: GET_ALL_QUOTATION,
		page,
		take
	};
};

export const getListOfQuotationSuccess = (quotations) => {
	return {
		type: GET_ALL_QUOTATION_SUCCESS,
		payload: quotations,
	};
};

export const getQuotation = (payload) => {
	return {
		type: GET_QUOTATION,
		payload,
	};
};

export const getQuotationSuccess = (quotation) => {
	return {
		type: GET_QUOTATION_SUCCESS,
		payload: quotation,
	};
};

export const getQuotationReference = (payload) => {
	return {
	  type: GET_QUOTATION_REFERENCE,
	  payload,
	}
  }
  
  export const getQuotationReferenceSuccess = (payload) => {
	return {
	  type: GET_QUOTATION_REFERENCE_SUCCESS,
	  payload,
	}
  }

export const updateQuotation = (payload) => {
	return {
		type: UPDATE_QUOTATION,
		payload,
	};
};

export const updateQuotationSuccess = (payload) => {
	return {
		type: UPDATE_QUOTATION_SUCCESS,
		payload,
	};
};

export const deleteQuotation = (id, oldList) => {
	return {
		type: DELETE_QUOTATION,
		payload: { id, oldList },
	};
};

export const deleteQuotationSuccess = (payload) => {
	return {
		type: DELETE_QUOTATION_SUCCESS,
		payload,
	};
};

export const deleteQuotationItem = (id,quotationId, oldList) => {
	return {
		type: DELETE_QUOTATION_ITEM,
		payload: { id, quotationId, oldList }
	}
}

export const deleteQuotationItemSuccess = (payload) => {
	return {
		type: DELETE_QUOTATION_ITEM_SUCCESS,
		payload,
	}
}

export const downloadQuotation = (payload) => {
	return {
		type: DOWNLOAD_QUOTATION,
		payload,
	};
};

export const downloadQuotationSuccess = (payload) => {
	return {
		type: DOWNLOAD_QUOTATION_SUCCESS,
		payload,
	};
};

export const showQuotationMessage = (message) => {
	return {
		type: SHOW_MESSAGE,
		payload: message,
	};
};

export const searchQuotation = (value, list, filterOption = "name") => {
	try {
		let selected;
		if (value !== "") {
			selected = list.filter(
				(item, index) =>
					item[filterOption]
					.toString()
					.toLowerCase()
					.search(value.toLowerCase()) !== -1 
			);
		} else {
			selected = list;
		}
		return {
			type: SEARCH_DOCTOR,
			payload: selected ? selected: [],
		};
	} catch (error) {
		console.error(error);
	}
};

export const _m = (list, selectedOption) => {
	let selected = [];
	if (Object.values(selectedOption)[0] == 1) {
		selected = list;
	} else {
		let dateNow = new Date();
		dateNow.setDate(dateNow.getDate() - 0);
		selected = list.filter(
			(item) => 
			item[Object.keys(selectedOption)[0]].split("T")[0] ===
			dateNow.toISOString().split("T")[0]
		);
	}
	return {
		type: SEARCH_DOCTOR_MASTER,
		payload: selected ? selected: [],
	};
};






