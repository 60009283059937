import {
  ADD_UNITY,
  ADD_UNITY_SUCCESS,
  GET_ALL_UNITIES,
  GET_ALL_UNITIES_SUCCESS,
  UPDATE_UNITY,
  UPDATE_UNITY_SUCCESS,
  DELETE_UNITY,
  DELETE_UNITY_SUCCESS,
  SHOW_MODAL,
  HIDE_MODAL,
  SHOW_EDIT_MODAL,
  HIDE_EDIT_MODAL,
} from "../../constants/ActionTypes";

const unityInitState = {
  addModal: false,
  updateModal: false,
  unity: {},
  base: [],
  editable: [],
  loader: true,
  meta: {}
};

const UnityReducers = (state= unityInitState, action) => {
  let { type, payload } = action;
  switch (type) {
    case ADD_UNITY:
    case GET_ALL_UNITIES:
    case UPDATE_UNITY:
      return { ...state };
    case UPDATE_UNITY_SUCCESS:
      return { ...state, unity: action.payload };
    case ADD_UNITY_SUCCESS:
      return state;
    case GET_ALL_UNITIES_SUCCESS:
      return { ...state, base: action.payload, editable: action.payload, loader: false };
    case DELETE_UNITY:
      return { ...state, loader: true };
    case DELETE_UNITY_SUCCESS:
      return { ...state, loader: false, base: action.payload };
    case SHOW_MODAL: 
      return { ...state, addModal: true };
    case HIDE_MODAL: 
      return { ...state, addModal: false };
    case SHOW_EDIT_MODAL:
      return { ...state, updateModal: true, unity: action.payload };
    case HIDE_EDIT_MODAL:
      return { ...state, updateModal: false, unity: {} };
    default:
      return state;
  }
};

export default UnityReducers;