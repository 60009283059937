import { result } from "lodash";
import {
  ADD_BANK_ACCOUNT,
  ADD_BANK_ACCOUNT_SUCCESS,
  GET_BANK_ACCOUNT,
  GET_BANK_ACCOUNT_SUCCESS,
  UPDATE_BANK_ACCOUNT,
  UPDATE_BANK_ACCOUNT_SUCCES,
  DELETE_BANK_ACCOUNT,
  DELETE_BANK_ACCOUNT_SUCCESS,
  SEARCH_DOCTOR_MASTER,
  GET_ALL_BANK_ACCOUNTS_SUCCESS,
  SEARCH_DOCTOR,
  SELECT_ALL_BANK_ACCOUNTS,
  SELECT_SINGLE_BANK_ACCOUNT,
  SORT_DOCTORS_BY_NEWEST,
  SHOW_MODAL,
  HIDE_MODAL,
  SHOW_EDIT_MODAL,
  HIDE_EDIT_MODAL,
  GET_ALL_BANK_ACCOUNTS,
} from "../../constants/ActionTypes";

const bankAccountInitState = {
  addModal: false,
  updateModal: false,
  bankAccount: {},
  base: [],
  editable: [],
  loader: true,
  meta:{}
};

const BankAccountReducers = (state= bankAccountInitState, action) => {

  let { type, payload } = action;
  switch (type) {
    case GET_BANK_ACCOUNT:
    case UPDATE_BANK_ACCOUNT:
    case GET_ALL_BANK_ACCOUNTS:
      return { ...state, loader: true };
    case GET_BANK_ACCOUNT_SUCCESS:
    case UPDATE_BANK_ACCOUNT_SUCCES:
      return { ...state, bankAccount: action.payload, loader: false };
    case ADD_BANK_ACCOUNT_SUCCESS:
      return state;
    case GET_ALL_BANK_ACCOUNTS_SUCCESS:
      return { ...state, bankAccount: payload.data, base: payload.data, editable: payload.data, loader: false, meta:payload.meta };
    case DELETE_BANK_ACCOUNT:
      return {
        ...state,
        loader: true
      };
    case DELETE_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        base: action.payload
      };
    case SELECT_ALL_BANK_ACCOUNTS:
      let newState = state.editable.map((elm) => {
        let newElm = { ...elm, selected: payload };
        return newElm;
      });
      return {
        ...state,
        editable: newState
      };
    case SELECT_SINGLE_BANK_ACCOUNT:
      let newValue = state.editable.map((elm) => {
        if (elm.id === payload.id) {
          let newElm = { ...elm, selected: payload.checked };
          return newElm;
        } else {
          return elm;
        }
      });
      return { ...state, editable: newValue };
    case SORT_DOCTORS_BY_NEWEST:
      let result = state.base.sort((x, y) => {
        return new Date(y.createdAt) - new Date(x.createdAt);
      });
      return { ...state, editable: result };
    case SEARCH_DOCTOR:
      return { ...state, editable: payload };
    case SEARCH_DOCTOR_MASTER:
      return { ...state, editable: payload };
    case SHOW_MODAL:
      return { ...state, addModal: true };
    case HIDE_MODAL:
      return { ...state, addModal: false };
    case SHOW_EDIT_MODAL:
      return { ...state, updateModal: true, bankAccount: action.payload };
    case HIDE_EDIT_MODAL:
      return { ...state, updateModal: false, bankAccount: {} };
    default:
      return state;
  }
};

export default BankAccountReducers;