import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { post, get, update, remove } from "../../axios/master";
import {
  ADD_TAX_CLASS,
  DELETE_TAX_CLASSES,
  GET_ALL_TAX_CLASSES,
  GET_TAX_CLASS,
  UPDATE_TAX_CLASS,
  GET_ALL_TAXES_CLASSES
} from "../../constants/ActionTypes";
import {
  getListOfTaxClassesSuccess,
  getTaxClassSuccess,
  showTaxClassMessage,
  deleteTaxClassSuccess,
  getListOfAllTaxesClassesSuccess
} from "../actions/TaxClass";
import { fetchError, hideDocModal } from "../actions/Common";
import { message, notification } from "antd";

const baseUrl = "taxClasses";

function* addNewTaxClass({ payload }) {
  try {
    const addTaxClass = yield call(post, baseUrl, payload);
    if (addTaxClass) {
      yield put(showTaxClassMessage("Tax Class Ajouter avec succées "));
      yield put(hideDocModal());
    } else {
    }
  } catch (error) {
    for (const errorMessage of error.message) {
      for (const keys of Object.keys(errorMessage.constraints)) {
        notification.error({
          message: errorMessage.constraints[keys],
          duration: 20
        });
      }
    }
    yield put(fetchError(error));
  }
}
function* getTaxClasses({ page, take }) {
  let response = yield call(get, page && take ? `${baseUrl}?page=${page}&take=${take}` : `${baseUrl}`);
  if (response) {
    yield put(getListOfTaxClassesSuccess(response));
  } else {
    yield put(showTaxClassMessage(response.message));
  }
}
function* getAllTaxesClasses() {
  let response = yield call(get, `${baseUrl}/all`);
  if (response) {
    yield put(getListOfAllTaxesClassesSuccess(response));
  } else {
    yield put(showTaxClassMessage(response.message));
  }
}
function* getSingleTaxClass({ payload }) {
  try {
    let singleTaxClass = yield call(get, `${baseUrl}/${payload}`);
    if (singleTaxClass) {
      yield put(getTaxClassSuccess(singleTaxClass.data));
    }
  } catch (error) {
    for (const errorMessage of error.message) {
      for (const keys of Object.keys(errorMessage.constraints)) {
        notification.error({
          message: errorMessage.constraints[keys],
          duration: 20
        });
      }
    }
    yield put(fetchError(error));
  }
}
function* updateTaxClass({ payload }) {
  let { data, id } = payload;

  try {
    let result = yield call(update, `${baseUrl}/${id}`, data);
    if (result) {
      yield put(showTaxClassMessage("Tax Class Modifier avec succées "));
    }
  } catch (error) {
    if (error && error.statusCode === 400) {
      notification.error({
        message: `Ce taux ne peuvent pas être modifié`,
        duration: 20
      });
    } else {
      for (const errorMessage of error.message) {
        for (const keys of Object.keys(errorMessage.constraints)) {
          notification.error({
            message: errorMessage.constraints[keys],
            duration: 20
          });
        }
      }
    }
    yield put(fetchError(error));
  }
}
function* deleteTaxClassesApi({ payload: { id, oldList } }) {
  try {
    const response = yield call(remove, `${baseUrl}/${id}`);
    const newList = oldList.filter(({ _id }) => _id !== id);
    yield put(deleteTaxClassSuccess(newList));
    if (response) {
      yield put(showTaxClassMessage("Tax Class Supprimer avec succées "));
    }
  } catch (error) {
    if (error && error.statusCode === 400) {
      notification.error({
        message: `Ce taux ne peuvent pas être effacés`,
        duration: 20
      });
    } else {
      for (const errorMessage of error.message) {
        for (const keys of Object.keys(errorMessage.constraints)) {
          notification.error({
            message: errorMessage.constraints[keys],
            duration: 20
          });
        }
      }
    }
    yield put(fetchError(error));
  }
}

export function* addTaxClass() {
  yield takeEvery(ADD_TAX_CLASS, addNewTaxClass);
}
function* updateSingleTaxClass() {
  yield takeEvery(UPDATE_TAX_CLASS, updateTaxClass);
}
export function* getEveryTaxClass() {
  yield takeEvery(GET_ALL_TAX_CLASSES, getTaxClasses);
}
export function* getEveryTaxesClass() {
  yield takeEvery(GET_ALL_TAXES_CLASSES, getAllTaxesClasses);
}
export function* getTaxClass() {
  yield takeEvery(GET_TAX_CLASS, getSingleTaxClass);
}
export function* deleteTaxClasses() {
  yield takeEvery(DELETE_TAX_CLASSES, deleteTaxClassesApi);
}
export default function* rootSaga() {
  yield all([
    fork(addTaxClass),
    fork(getEveryTaxClass),
    fork(getEveryTaxesClass),
    fork(getTaxClass),
    fork(updateSingleTaxClass),
    fork(deleteTaxClasses),
  ]);
}
