import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { post, get, update, remove } from "../../axios/master";
import {
  ADD_UNITY,
  GET_ALL_UNITIES,
  UPDATE_UNITY,
  DELETE_UNITY
} from "../../constants/ActionTypes";
import {
  getUnitiesSuccess,
  deleteUnitySuccess,
  showUnityMessage,
} from "../actions/Unity";
import { fetchError, hideDocModal } from "../actions/Common";
import { message, notification } from "antd";

const baseUrl = "unities";

function* addNewUnity({ payload }) {
  try {
    const addUnity = yield call(post, baseUrl, payload);
    if (addUnity) {
      yield put(showUnityMessage("Unité ajouter avec succées"))
      yield put(hideDocModal());
    } else {
      yield put(showUnityMessage("Erreur ajouter unité"))
    }
  } catch (error) {
    for (const errorMessage of error.message) {
      for (const keys of Object.keys(errorMessage.constrains)) {
        notification.error({
          message: errorMessage.constrains[keys],
          duration: 20
        });
      }
    }
    yield put(fetchError(error))
  }
}

function* getAllUnities() {
  let response = yield call(get, `${baseUrl}`);
  if (response) {
    yield put(getUnitiesSuccess(response));
  } else {
    yield put(showUnityMessage(response.message));
  }
}

function* updateUnity({ payload }) {
  let { data, id } = payload;

  try {
    let result = yield call(update, `${baseUrl}/${id}`, data);
    if (result) {
      yield put(showUnityMessage("Unity modifier avec succées"));
    } else {
      yield put(showUnityMessage("Error modifier unity"));
    }

  } catch (error) {
    if (error && error.statusCode === 400) {
      notification.error({
        message: `Ce unité ne peuvent pas être modifié`,
        duration: 20
      });
    } else {
      for (const errorMessage of error.message) {
        for (const keys of Object.keys(errorMessage.constrains)) {
          notification.error({
            message: errorMessage.constrains[keys],
            duration: 20
          });
        }
      }
    }
    yield put(fetchError(error));
  }
}

function* deleteUnity({ payload: { id, oldList } }) {
  try {
    const response = yield call(remove, `${baseUrl}/${id}`);
    const newList = oldList.filter(({ _id }) => _id !== id);
    yield put(deleteUnitySuccess(newList));
    if (response) {
      yield put(showUnityMessage("Unité supprimer avec succées"));
    } else {
      yield put(showUnityMessage("Error supprimer unité"));
    }
  } catch (error) {
    if (error && error.statusCode === 400) {
      notification.error({
        message: `Ce unité ne peuvent pas être effacés`,
        duration: 20
      });
    } else {
      for (const errorMessage of error.message) {
        for (const keys of Object.keys(errorMessage.constrains)) {
          notification.error({
            message: errorMessage.constrains[keys],
            duration: 20
          });
        }
      }
    }
    yield put(fetchError(error))
  }
}

export function* addUnity() {
  yield takeEvery(ADD_UNITY, addNewUnity);
}

export function* getEveryUnities() {
  yield takeEvery(GET_ALL_UNITIES, getAllUnities);
}

export function* updateSingleUnity() {
  yield takeEvery(UPDATE_UNITY, updateUnity);
}

export function* deleteSingleUnity() {
  yield takeEvery(DELETE_UNITY, deleteUnity)
}

export default function* rootSaga() {
  yield all([
    fork(addUnity),
    fork(getEveryUnities),
    fork(updateSingleUnity),
    fork(deleteSingleUnity),
  ])
}