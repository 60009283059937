import React, { useState } from "react";
import { Input, Form, Button, message, notification } from "antd";
import { useParams, useHistory } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import axios from "axios";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";


const FormItem = Form.Item;

const ResetPassword = () => {
  const history = useHistory();
  const { token } = useParams();

  const [checks, setChecks] = useState({
    upperLetterCheck: false,
    lowerLetterCheck: false,
    numberCheck: false,
    lengthCheck: false,
  });


  const onChangePwd = (event) => {
    const { value } = event.target;

    const upperLetterCheck = /(?=.*[A-Z])/.test(value);
    const lowerLetterCheck = /(?=.*[a-z])/.test(value);
    const numberCheck = /(?=.*[0-9])/.test(value);
    const lengthCheck = /(?=.{8,})/.test(value);
    setChecks({
      upperLetterCheck,
      lowerLetterCheck,
      numberCheck,
      lengthCheck,
    });
  };

  const otherPwdMessage = (
    <div>
      <p>
        <span className="text-colors">le mot de passe doit contenir :</span>
        <br />
        <ul>
          <li
            className={`text-color-${checks.lengthCheck ? "valide" : "invalide"
              }`}
          >
            Minimum de 8 caractères
          </li>
          <li
            className={`text-color-${checks.upperLetterCheck ? "valide" : "invalide"
              }`}
          >
            1 caractère alphabétique majuscule
          </li>
          <li
            className={`text-color-${checks.lowerLetterCheck ? "valide" : "invalide"
              }`}
          >
            1 caractère alphabétique minuscule
          </li>
          <li
            className={`text-color-${checks.numberCheck ? "valide" : "invalide"
              }`}
          >
            1 chiffre
          </li>
        </ul>
      </p>
    </div>
  );

  const onFinish = async (values) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_SERVER_URI}auth/reset-password/${token}`,
        values
      );
      notification.success({
        message: "Votre mot de passe à été changé avec succès",
        duration: 20
      })
      setTimeout(() => {
        history.push("/signin");
      }, 1500);
    } catch (error) {
      console.error(error);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="gx-main-content-wrapper">
      <div className="gx-login-container">
        <div className="gx-login-content">
          <div className="gx-login-header">
            <img
              src={require("assets/images/app-logo.svg")}
              alt="click2up"
              title="click2up"
            />
          </div>
          <div className="gx-mb-4">
            <h2>Reset Password</h2>
            <p>Enter a new password for your account</p>
          </div>
          <Form
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            className="ant-form ant-form-horizontal gx-signin-form gx-form-row0"
          >
            <FormItem
              name="password"
              rules={[
                {
                  required: true,
                  message: otherPwdMessage,
                },
                {
                  pattern: new RegExp(
                    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"
                  ),
                  message: otherPwdMessage,
                },
              ]}
            >
              <Input.Password
                onChange={onChangePwd}
                //type="password"
                placeholder="Mot de passe"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </FormItem>
            <FormItem
              name="confirmPassword"
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: "Veuillez confirmer votre mot de passe",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "Les deux mots de passe correspondent pas"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                //type="password"
                placeholder="Confirmez votre mot de passe"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </FormItem>
            <FormItem>
              <Button type="primary" htmlType="submit">
                <IntlMessages id="app.userAuth.resetPassword" />
              </Button>
            </FormItem>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
