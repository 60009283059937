import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Input,
  message,
  Form,
  Modal,
  Row,
  Col,
  Select,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import "./styles.css";
import {
  hideMessage,
  showAuthLoader,
  userSignIn,
  forgetPassword,
} from "appRedux/actions/Auth";
import { COUNTRY_CODE, PROFESSIONS } from "../constants/variables";
import backgroundImage from 'assets/images/loginPageBackground.png';
import { ReactComponent as Logo } from "../assets/images/logo.svg";

import IntlMessages from "util/IntlMessages";
import CircularProgress from "components/CircularProgress/index";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";


const { Option } = Select;

const formItem = {
  labelCol: {
    xs: { span: 48 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 48 },
    sm: { span: 48 },
  },
};

const SignIn = () => {
  const dispatch = useDispatch();
  const { loader, alertMessage, showMessage, authUser } = useSelector(
    ({ auth }) => auth
  );
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const [formLogin] = Form.useForm();
  const [formRequest] = Form.useForm();


  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
     if (authUser !== null) {
      window.location.href = "/";
    }
  });

  const handelVisibility = (value) => {
    setOpen(value);
  };

  const onFinishFailed = (errorInfo) => {
    console.error('Failed:', errorInfo);
  };

  const onFinishForget = (values) => {
    dispatch(forgetPassword(values));
    setTimeout(() => {
      setOpen(false);
      formRequest.resetFields();
    }, 1000);
  };

  const onFinishFailedForget = (errorInfo) => {
    console.error('Failed:', errorInfo);
  };

  const onFinish = (values) => {
    dispatch(showAuthLoader());
    dispatch(userSignIn(values));
  };

  return (
    <div className="bg-image-login" style={{ backgroundImage: `url(${backgroundImage})` }}>

      <div className="login-form">
        <div className="logo">
        <Logo />

        </div>

        <Form
          {...formItem}
          form={formLogin}
          initialValues={{ remember: true }}
          name="login"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="gx-signin-form gx-form-row0"
        >
          <Form.Item
            initialValue=""
            rules={[
              {
                required: true,
                message: "Veuillez entrer votre email",
              },
            ]}
            name="email"
          >
            <Input placeholder="Addresse mail" />
          </Form.Item>
          <Form.Item
            initialValue=""
            rules={[
              { 
                required: true, 
                message: "Veuillez entrer votre mot de passe" 
              },
            ]}
              name="password"
            >
              <Input.Password
                placeholder="Mot de passe"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
            <span
              onClick={() => handelVisibility(true)}
              className="gx-login-form-forgot forgot-pssw flex-end"
            >
              Mot de passe oublié ?
            </span>
            <Form.Item>
              <Button type="primary" className="add-btn" htmlType="submit">
                <IntlMessages id="app.userAuth.signIn" />
              </Button>
              <span>
                <IntlMessages id="app.userAuth.or" />
              </span>{" "}
              <Link to="/signup">
                <IntlMessages id="app.userAuth.signUp" />
              </Link>
            </Form.Item>
          </Form>
          {loader ? (
          <div className="loader-position">
            <CircularProgress />
          </div>
          ) : null}
      </div>
      <Modal
        centered
        visible={open}
        onCancel={() => {
          handelVisibility(false); 
          formRequest.resetFields();
        }}
        closable={false}
        footer={null}
      >
        <div className="gx-login-header">
         
        </div>
        <div className="gx-mb-4">
          <h2>Mot de passe oublié ?</h2>
          <p>
            Saisissez votre email.
            Nous vous enverrons un mail avec un lien pour modifier votre mot de passe.
          </p>
        </div>
        <Form 
          form={formRequest}
          onFinish={onFinishForget} 
          onFinishFailed={onFinishFailedForget}
          className="ant-form ant-form-horizontal gx-signin-form gx-form-row0"
        >
          <Row>
            <Col span={16}>
              <Form.Item
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "Veuillez entrer une adresse e-mail valide",
                  },
                  {
                    required: true,
                    message: "Champ obligatoire!",
                  },
                ]}
              >
                <Input placeholder="example@email.com" name="email" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Button className="add-btn" type="primary" htmlType="submit">
                Envoyer
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default SignIn;
