export const PROFESSIONS = [
  {
    label: "charpentière",
    name: "CARPENTER",
  },
  {
    label: "Plombier",
    name: "PLUMBER",
  },
];

// TODO
export const BUSINESS_SECTOR = [
  {
    label: "Agro-alimentaire",
    name: "AGRI_FOOD",
  },
  {
    label: "Bâtiment",
    name: "BUILDING",
  },
  {
    label: "Commerce",
    name: "COMMERCIAL",
  },
  {
    label: "Edition",
    name: "EDITION",
  },
  {
    label: "Etude et Conseil",
    name: "CONSULTING",
  },
  {
    label: "Distribution",
    name: "DISTRIBUTION",
  },
  {
    label: "Industrie",
    name: "INDUSTRIAL",
  },
  {
    label: "Informatique et Télécom",
    name: "INFORMATION_TECHNOLOGY_AND_TELECOMMUNICATIONS"
  },
  {
    label: "Juridique",
    name: "LEGAL",
  },
  {
    label: "Médical",
    name: "MEDICAL",
  },
  {
    label: "Services",
    name: "SERVICES",
  },
  {
    label: "Textile",
    name: "TEXTILE",
  },
  {
    label: "Transport",
    name: "TRANSPORT",
  },
  {
    label: "Autre",
    name: "OTHERS",
  },
]

export const COUNTRY_CODE = [
  {
    label: "tunisia",
    name: "TN",
  },
  {
    label: "french",
    name: "FR",
  },
];

export const BANKING_TRANSACTION_TYPES = [
  {
    label: "Crédit",
    name: "CREDIT",
  },
  {
    label: "Débit",
    name: "DEBIT",
  }
]
export const CREDIT_CARD_TYPE = [
  {
    label: "Visa",
    name: "VISA",
  },
  {
    label: "Paypal",
    name: "PAYPAL",
  },
  {
    label: "Cash",
    name: "CASH",
  },
];

export const PAYMENT = {
  UNPAID: "UNPAID",
  PAID: "PAID",
};

export const CLIENT = {
  PARTICULAR: "PARTICULAR",
  PROFESSIONAL: "PROFESSIONAL",
};

export const AUTHORIZATION_CODE = {
  AUTOMATIC: "0",
  SETTLEMENT: "1",
  CORRECTION: "2",
  MANDATORY_HIRING: "3",
  ACTIVITY_SUSPENSION: "4",
}

export const TYPE_OF_SERVICE = {
  supplierDiscount: "Remise du fournisseur (الخصم من المورد)",
  vocationalTrainingPerformance: "Performance en formation professionnelle (الأداء على التكوين المهني)",
  housingPromotionContribution: "Contribution au Fonds de promotion du logement pour les employés (المساهمة في صندوق النهوض بالمسكن لفائدة الأجراء)",
  consumptionTax: "Taxe de consommation (المعلوم على االستهالك)",
  valueAddedPerformance: "Performance sur la valeur ajoutée (الأداء على القيمة المضافة)",
  otherFees: "Autres frais (معاليم أخرى على رقم المعامالت)",
  taxStampFee: "Frais de timbre fiscal (معلوم الطابع الجبائي)",
  institutionsTax: "Taxe sur les entreprises (المعلوم على المؤسسات)",
  hotelTax: "Taxe sur les hôtels المعلوم على النزل",
  vacationTax: "Taxe de séjour (معلوم الإجازة)",
}

export const PROVIDER = {
  PROVIDER: "PROVIDER",
}

export const PAGE_SIZE_OPTIONS = ["10", "20", "30"];
export const PAGE = 1;
export const TAKE = 10;

export const INVOICE_PAGES = {
  CREATE: "CREATE",
  PREVIEW: "PREVIEW",
};

export const INVOICE_TYPES = {
  REFOUND: 'REFOUND',
  SALES: 'SALES',
  DELIVERY_NOTES: 'DELIVERY_NOTES',
  FEE_NOTE: 'FEE_NOTE',
  RELEASE_NOTE: 'RELEASE_NOTE'
}

export const QUOTATION_PAGES = {
  CREATE: "CREATE",
  PREVIEW: "PREVIEW",
};

export const PERFORMANCE_REPORT_PAGES = {
  CREATE: "CREATE",
  PREVIEW: "PREVIEW"
}

export const SETTINGS_PAGES = {
  PROFILE: "PROFILE",
  TAX_CLASS: "TAX_CLASS",
  BANK_ACCOUNT: "BANK_ACCOUNT",
  UPDATE_PASSWORD: 'UPDATE_PASSWORD',
  DOCUMENT_TEMPLATE: 'DOCUMENT_TEMPLATE',
  PAYMENT_TERMS: 'PAYMENT_TERMS',
  ACCOUNTANT: 'ACCOUNTANT',
  IDENTIFIED: 'IDENTIFIED',
};

export const TAX_CLASS_TYPE = {
  AMOUNT: 'AMOUNT',
  PERCENT: 'PERCENT'
};

export const OriginPurchase = {
  LOCAL: "LOCAL",
  FOREIGNER: "FOREIGNER"
}

export const PurchaseEquipment = {
  YES: "YES",
  NO: "NO"
}

export const WithholdingTax = {
  MARKETS: 1,
  HONORARIUMS: 3,
  COMMISSIONS: 10,
  REMUNERATIONS: 15, 
}

export const UNITIES_TYPE = ["CM", "M", "H", "KG", "M²", "PCE"];

export const PAYMENT_TERMS = [0, 7, 14, 30, 60, 90];

