import React, { Fragment } from "react";
import loader from "../../assets/images/loader.svg";
import "./styling.css";
const CircularProgress = ({ className }) => (
  <Fragment>
    <div className="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </Fragment>
);
export default CircularProgress;
