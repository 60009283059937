import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { post, get, update, remove } from "../../axios/master";
import {
  ADD_BANKING_TRANSACTION, 
  GET_ALL_BANKING_TRANSACTION,
  GET_ALL_MONTHLY_BANKING_TRANSACTION,
  GET_BANKING_TRANSACTION,
  UPDATE_BANKING_TRANSACTION,
  DELETE_BANKING_TRANSACTION,
} from "../../constants/ActionTypes";
import {
  getAllBankingTransactionSuccess,
  getListOfMonthlyBankingTransactionSuccess,
  getSingleBankingTransactionSuccess,
  showBankingTransactionMessage,
  deleteBankingTransactionSuccess,
} from "../actions/BankingTransaction";
import {
  fetchError, 
  hideDocModal
} from "../actions/Common";
import { message, notification } from "antd";

const baseUrl= "transactions";

function* addNewTransactions({ payload }) {
  try {
    const addTransaction = yield call(post, baseUrl, payload);
    if (addTransaction) {
      yield put(showBankingTransactionMessage("Transaction bancaire ajouter avec succées"));
      yield put(hideDocModal())
    } else {
    }
  } catch (error) {
    for (const errorMessage of error.message) {
      for (const keys of Object.keys(errorMessage.constraints)) {
        notification.error({
            message: errorMessage.constraints[keys],
            duration: 20
          });
      }
    }
    yield put(fetchError(error))
  }
}

function* getTransactions({ after, before }) {
  let response = yield call(get, after && before ? `${baseUrl}?after=${after}&before=${before}`: `${baseUrl}`);
  if(response && response.data) {
    yield put(getAllBankingTransactionSuccess(response));
  } else {
    yield put(showBankingTransactionMessage(response.message));
  }
}

function* getMonthlyTransactions({ yearMonth, clientId }) {
  let response = yield call(
    get, 
    yearMonth ? 
    `${baseUrl}/monthly?clientId=${clientId}&yearMonth=${yearMonth}`: 
    `${baseUrl}/monthly?clientId=${clientId}`);

  if (response && response.data) {
    yield put(getListOfMonthlyBankingTransactionSuccess(response));
  } else {
    yield put(showBankingTransactionMessage(response.message));
  }
}

function* getTransaction({ payload }){
  try{
    let singleTransaction = yield call(get, `${baseUrl}/${payload}`);
    if (singleTransaction) {
      yield put(getSingleBankingTransactionSuccess(singleTransaction));
    }
  } catch (error) {
    for (const errorMessage of error.message){
      for (const keys of Object.keys(errorMessage.constraints)) {
        notification.error({
            message: errorMessage.constraints[keys],
            duration: 20
          });
      }
    }
    yield put(fetchError(error));
  }
}

function* updateTransaction({ payload }) {
  let { data, id }= payload;
  try {
    let response = yield call(update, `${baseUrl}/${id}`, data);
    if (response) {
      yield put(showBankingTransactionMessage("Transaction bancaire modifier avec succées"));
    }
  } catch (error) {
    for (const errorMessage of error.message){
      for (const keys of Object.keys(errorMessage.constraints)) {
        notification.error({
            message: errorMessage.constraints[keys],
            duration: 20
          });
      }
    }
    yield put(fetchError(error));
  }
}

function* deleteTransaction({ payload: { id, oldList } }) {
  try {
    const response = yield call(remove, `${baseUrl}/${id}`);
    const newList = oldList.filter((item) => item.id !== id);
    yield put(deleteBankingTransactionSuccess(newList));
    if(response) {
      yield put(showBankingTransactionMessage("Transaction bancaire supprimer avec succées"))
    }
  } catch (error) {
    for (const errorMessage of error.message) {
      for (const keys of Object.keys(errorMessage.constraints)){
        notification.error({
            message: errorMessage.constraints[keys],
            duration: 20
          });
      }
    }
    yield put(fetchError(error))
  }
}

export function* addTransaction() {
  yield takeEvery(ADD_BANKING_TRANSACTION, addNewTransactions);
}

export function* getAllTransactions() {
  yield takeEvery(GET_ALL_BANKING_TRANSACTION, getTransactions)
}

export function* getAllMonthlyTransactions() {
  yield takeEvery(GET_ALL_MONTHLY_BANKING_TRANSACTION, getMonthlyTransactions)
}

export function* getSingleTransaction() {
  yield takeEvery(GET_BANKING_TRANSACTION, getTransaction)
}

export function* updateSingleTransaction() {
  yield takeEvery(UPDATE_BANKING_TRANSACTION, updateTransaction)
}

export function* deleteSingleTransaction() {
  yield takeEvery(DELETE_BANKING_TRANSACTION, deleteTransaction)
}

export default function* rootSaga() {
  yield all([
    fork(addTransaction),
    fork(getAllTransactions),
    fork(getAllMonthlyTransactions),
    fork(getSingleTransaction),
    fork(updateSingleTransaction),
    fork(deleteSingleTransaction)
  ]);
}