import { message, notification } from "antd";
import {
  ADD_UNITY,
  ADD_UNITY_SUCCESS,
  GET_ALL_UNITIES,
  GET_ALL_UNITIES_SUCCESS,
  UPDATE_UNITY,
  UPDATE_UNITY_SUCCESS,
  DELETE_UNITY,
  DELETE_UNITY_SUCCESS,
  SHOW_MESSAGE,
} from "../../constants/ActionTypes";

export const addUnity = (payload) => {
  return {
    type: ADD_UNITY,
    payload,
  };
};

export const addUnitySuccess = (payload) => {
  return {
    type: ADD_UNITY_SUCCESS,
    payload,
  }
}

export const getUnities = (payload) => {
  return {
    type: GET_ALL_UNITIES,
    payload,
  }
}

export const getUnitiesSuccess = (unities) => {
  return {
    type: GET_ALL_UNITIES_SUCCESS,
    payload: unities,
  }
}

export const updateUnity = (payload) => {
  return {
    type: UPDATE_UNITY,
    payload,
  }
}

export const updateUnitySuccess = (payload) => {
  return {
    type: UPDATE_UNITY_SUCCESS,
    payload,
  }
}

export const deleteUnity = (id, oldList) => {
  return {
    type: DELETE_UNITY,
    payload: { id, oldList },
  }
}

export const deleteUnitySuccess = (payload) => {
  return {
    type: DELETE_UNITY_SUCCESS,
    payload,
  }
}

export const showUnityMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message,
  }
}