import React, { useState } from "react";
import { Button, Input, Form, Modal, Row, Col, } from "antd";
import { useDispatch, useSelector } from "react-redux";
import './styles.css'
import { resetPassword } from "appRedux/actions/Auth";


const formItemLayout = {
    labelCol: {
        span: 24,
    },
    /* wrapperCol: {
        span: 18,
    }, */
};

const Index = () => {
    const dispatch = useDispatch();
    const { loader, isPasswordTmp } = useSelector(({ auth }) => auth);
    const [open, setOpen] = useState(isPasswordTmp)

    const onFinish = values => {
        setOpen(false)
        dispatch(resetPassword(values));
    };

    const onFinishFailed = errorInfo => {
        console.error('Failed:', errorInfo);
    };

    const handelVisibility = (value) => {
        setOpen(value)
    }
    return (
        <Modal
            centered
            visible={open}
            onCancel={() => handelVisibility(false)}
            closable={false}
            footer={null}
        >
            <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                {...formItemLayout}
            >
                <Row justify="center">
                    <Col span={16}> 
                        <Form.Item
                            label='Modifier votre mot de passe'
                            name="newPassword"
                            rules={[
                                {
                                    type: "string",
                                    message: "Non valide!",
                                },
                                {
                                    required: true,
                                    min: 6,
                                    message: "short password",
                                },
                            ]}
                        >
                            <Input.Password name='newPassword' />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Button type="primary" htmlType="submit" style={{ marginTop: 35 }}>
                            Envoyer
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Modal >
    );
};

export default Index
