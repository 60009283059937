import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import URLSearchParams from "url-search-params";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { ConfigProvider } from "antd";
import { IntlProvider } from "react-intl";

import AppLocale from "lngProvider";
import MainApp from "./MainApp";
import SignIn from "../SignIn";
import SignUp from "../SignUp";
import ResetPassword from "../ResetPassword";
import { setInitUrl } from "appRedux/actions/Auth";
import {
  onLayoutTypeChange,
  onNavStyleChange,
  setThemeType,
} from "appRedux/actions/Setting";

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
} from "../../constants/ThemeSetting";
import ActivateAccount from "../ActivateAccount";
import { getCurrentUser, userSignOut } from "../../appRedux/actions/Auth";
import SignUpAccountant from "../../routes/Accountant/SignUp";

const RestrictedRoute = ({
  component: Component,
  location,
  authUser,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        authUser ? <Component {...props} /> : <pre>{authUser}</pre>
      }
    />
  );
};

/* <Redirect
  to={{
    pathname: "/signin",
    state: { from: location },
  }}
/> */
const App = (props) => {
  const dispatch = useDispatch();
  const { locale, navStyle, layoutType } = useSelector(
    ({ settings }) => settings
  );
  const { authUser, initURL } = useSelector(({ auth }) => auth);

  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  useEffect(() => {
    let link = document.createElement("link");
    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = "/css/style.css";
    link.className = "gx-style";
    document.body.appendChild(link);
  });

  useEffect(() => {
    if (initURL === "") {
      dispatch(setInitUrl(location.pathname));
    }
    const params = new URLSearchParams(location.search);

    if (params.has("theme")) {
      dispatch(setThemeType(params.get("theme")));
    }
    if (params.has("nav-style")) {
      dispatch(onNavStyleChange(params.get("nav-style")));
    }
    if (params.has("layout-type")) {
      dispatch(onLayoutTypeChange(params.get("layout-type")));
    }
    setLayoutType(layoutType);
    setNavStyle(navStyle);
  });

  const setLayoutType = (layoutType) => {
    if (layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove("boxed-layout");
      document.body.classList.remove("framed-layout");
      document.body.classList.add("full-layout");
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove("full-layout");
      document.body.classList.remove("framed-layout");
      document.body.classList.add("boxed-layout");
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove("boxed-layout");
      document.body.classList.remove("full-layout");
      document.body.classList.add("framed-layout");
    }
  };

  const setNavStyle = (navStyle) => {
    if (
      navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
      navStyle === NAV_STYLE_DARK_HORIZONTAL ||
      navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
      navStyle === NAV_STYLE_ABOVE_HEADER ||
      navStyle === NAV_STYLE_BELOW_HEADER
    ) {
      document.body.classList.add("full-scroll");
      document.body.classList.add("horizontal-layout");
    } else {
      document.body.classList.remove("full-scroll");
      document.body.classList.remove("horizontal-layout");
    }
  };

  const checkForSpecialRoutes = (url) => {
    const isSpecialRoute = false;
    const specialRoutes = ["reset-password", "create-account"];
    for (const specialRoute of specialRoutes) {
      if (url.includes(specialRoute)) {
        return true;
      }
    }

    return isSpecialRoute;
  };

  useEffect(() => {
    if (!checkForSpecialRoutes(window.location.href)) {
      if (localStorage.getItem("token")) {
        dispatch(getCurrentUser());
      } else {
        dispatch(userSignOut());
      }
    }
  }, []);

  const currentAppLocale = AppLocale[locale.locale];
  return (
    <ConfigProvider
      locale={currentAppLocale.antd}
      direction={locale.locale === "ar" ? "rtl" : "ltr"}
    >
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <Switch>
          <Route exact path="/signin" component={SignIn} />
          <Route exact path="/signup" component={SignUp} />
          <Route
            exact
            path="/create-account/:clientId/:accountantEmail/:countryCode"
            component={SignUpAccountant}
          />
          <Route
            exact
            path="/reset-password/:token"
            component={ResetPassword}
          />
          <Route
            exact
            path="/validate-account/:token"
            component={ActivateAccount}
          />
          <RestrictedRoute
            path={`${match.url}`}
            authUser={authUser}
            location={location}
            component={MainApp}
          />
        </Switch>
      </IntlProvider>
    </ConfigProvider>
  );
};

export default memo(App);
