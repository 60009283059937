import {
  ADD_TAX_CLASS,
  ADD_TAX_CLASS_SUCCESS,
  GET_ALL_TAX_CLASSES,
  GET_ALL_TAX_CLASSES_SUCCESS,
  UPDATE_TAX_CLASS,
  UPDATE_TAX_CLASS_SUCCESS,
  DELETE_TAX_CLASSES,
  DELETE_TAX_CLASSES_SUCCESS,
  GET_TAX_CLASS,
  GET_TAX_CLASS_SUCCESS,
  SEARCH_DOCTOR,
  SHOW_MESSAGE,
  SEARCH_DOCTOR_MASTER,
  GET_ALL_TAXES_CLASSES,
  GET_ALL_TAXES_CLASSES_SUCCESS
} from "../../constants/ActionTypes";

export const addTaxClass = (payload) => {
  return {
    type: ADD_TAX_CLASS,
    payload,
  };
};

export const addTaxClassSuccess = (payload) => {
  return {
    type: ADD_TAX_CLASS_SUCCESS,
    payload,
  };
};

export const updateTaxClass = (payload) => {
  return {
    type: UPDATE_TAX_CLASS,
    payload,
  };
};

export const updateTaxClassSuccess = (payload) => {
  return {
    type: UPDATE_TAX_CLASS_SUCCESS,
    payload,
  };
};

export const getTaxClass = (payload) => {
  return {
    type: GET_TAX_CLASS,
    payload,
  };
};

export const getTaxClassSuccess = (client) => {
  return {
    type: GET_TAX_CLASS_SUCCESS,
    payload: client,
  };
};

export const deleteTaxClass = (id, oldList) => {
  return {
    type: DELETE_TAX_CLASSES,
    payload: { id, oldList },
  };
};

export const deleteTaxClassSuccess = (payload) => {
  return {
    type: DELETE_TAX_CLASSES_SUCCESS,
    payload,
  };
};

export const getListOfTaxClasses = (page, take) => {
  return {
    type: GET_ALL_TAX_CLASSES,
    page,
    take
  };
};

export const getListOfTaxClassesSuccess = (client) => {
  return {
    type: GET_ALL_TAX_CLASSES_SUCCESS,
    payload: client,
  };
};

export const getListOfAllTaxes = () => {
  return {
    type: GET_ALL_TAXES_CLASSES
  };
};

export const getListOfAllTaxesClassesSuccess = (client) => {
  return {
    type: GET_ALL_TAXES_CLASSES_SUCCESS,
    payload: client,
  };
};

export const showTaxClassMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message,
  };
};

export const searchTaxClass = (value, list, filterOption = "id") => {
  try {
    let selected;
    if (value !== "") {
      selected = list.filter(
        (item, index) =>
          item[filterOption].toString().toLowerCase().search(value.toLowerCase()) !== -1
      );
    } else {
      selected = list;
    }
    return {
      type: SEARCH_DOCTOR,
      payload: selected ? selected : [],
    };
  } catch (error) {
    console.error(error);
  }
};
export const _m = (list, selectedOption) => {
  let selected = [];
  if (Object.values(selectedOption)[0] === 1) {
    selected = list;
  } else {
    let dateNow = new Date();
    dateNow.setDate(dateNow.getDate() - 0); // - 0 ==> today
    selected = list.filter(
      (item) =>
        item[Object.keys(selectedOption)[0]].split("T")[0] ===
        dateNow.toISOString().split("T")[0]
    );
  }
  return {
    type: SEARCH_DOCTOR_MASTER,
    payload: selected ? selected : [],
  };
};
