import {
  CREATE_DOCUMENT_TEMPLATE,
  CREATE_DOCUMENT_TEMPLATE_SUCCESS,
  UPDATE_DOCUMENT_TEMPLATE,
  UPDATE_DOCUMENT_TEMPLATE_SUCCESS,
  GET_DOCUMENT_TEMPLATE,
  GET_DOCUMENT_TEMPLATE_SUCCESS,
  GET_DOCUMENT_TEMPLATE_BY_ID,
  GET_DOCUMENT_TEMPLATE_BY_ID_SUCCESS,
  DELETE_DOCUMENT_TEMPLATE,
  DELETE_DOCUMENT_TEMPLATE_SUCCESS,
  SEARCH_DOCUMENT_TEMPLATES,
} from "constants/ActionTypes";

const INIT_STATE = {
  loader: true,
  base: [],
  editable: [],
  documentTemplate: {},
  openModal: true,
  meta:{}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_DOCUMENT_TEMPLATE:
    case GET_DOCUMENT_TEMPLATE: {
      return {
        ...state,
        loader: true,
      };
    }
    case UPDATE_DOCUMENT_TEMPLATE_SUCCESS:
    case GET_DOCUMENT_TEMPLATE_SUCCESS: {
      return {
        ...state,
        loader: false,
        base: action.payload.data,
        editable: action.payload.data,
        meta:action.payload.meta
      };
    }
    case SEARCH_DOCUMENT_TEMPLATES: {
      return {
        ...state,
        loader: false,
        editable: action.payload,
      };
    }
    case GET_DOCUMENT_TEMPLATE_BY_ID: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_DOCUMENT_TEMPLATE_BY_ID_SUCCESS: {
      return {
        ...state,
        loader: false,
        documentTemplate: action.payload,
      };
    }
    case CREATE_DOCUMENT_TEMPLATE: {
      return {
        ...state,
        loader: true,
      };
    }
    case CREATE_DOCUMENT_TEMPLATE_SUCCESS: {
      return {
        ...state,
        loader: false,
        documentTemplate: action.payload,
        openModal: false,
      };
    }
    case DELETE_DOCUMENT_TEMPLATE: {
      return {
        ...state,
        loader: true,
      };
    }
    case DELETE_DOCUMENT_TEMPLATE_SUCCESS: {
      return {
        ...state,
        loader: false,
        base: state.base.filter(
          (item) => action.payload.indexOf(item._id) === -1
        ),
      };
    }
    default:
      return state;
  }
};
