import {
  ASSIGN_ACCOUNTANT,
  ASSIGN_ACCOUNTANT_SUCCESS,
  GET_ALL_USERS,
  GET_ALL_USERS_SUCCESS,
  GET_USER,
  GET_USER_SUCCESS,
  GET_ACCOUNTANT_USERS,
  GET_ACCOUNTANT_USERS_SUCCESS
} from "../../constants/ActionTypes";

export const getUser = (payload) => {
  return {
    type: GET_USER,
    payload,
  };
};

export const getUserSuccess = (payload) => {
  return {
    type: GET_USER_SUCCESS,
    payload,
  };
};

export const assignAccountant = (payload) => {
  return {
    type: ASSIGN_ACCOUNTANT,
    payload,
  };
};

export const assignAccountantSuccess = (payload) => {
  return {
    type: ASSIGN_ACCOUNTANT_SUCCESS,
    payload,
  };
};

export const getListOfUsers = (filter, page, take) => {
  return {
    type: GET_ALL_USERS,
    filter,
    page,
    take
  };
};

export const getListOfUsersSuccess = (client) => {
  return {
    type: GET_ALL_USERS_SUCCESS,
    payload: client,
  };
};

export const getAccountantUsers = (payload) => {
  return {
    type: GET_ACCOUNTANT_USERS,
    payload
  };
};

export const getAccountantUsersSuccess = (clients) => {
  return {
    type: GET_ACCOUNTANT_USERS_SUCCESS,
    payload: clients,
  }
}
