import {
  TOP_CLIENTS,
  TOP_CLIENTS_SUCCESS,
  TOP_PRODUCTS,
  TOP_PRODUCTS_SUCCESS,
  YEARLY_SALES,
  YEARLY_SALES_SUCCESS,
  INVOICES_PAYMENT_STATUSES,
  INVOICES_PAYMENT_STATUSES_SUCCESS,
} from "../../constants/ActionTypes";

const initModalState = {
  topClients: [],
  topProducts: [],
  yearlySales: [],
  invoicesPaymentStatus: {
    items: [],
    count: 0,
    totalPrice: 0,
    currency: '',
  },
  loader: true,
};

const MasterStatReducer = (state = initModalState, action) => {
  switch (action.type) {
    case TOP_CLIENTS:
      return { ...state, loader: false };
    case TOP_CLIENTS_SUCCESS:
      return { ...state, loader: false, topClients: action.payload };

    case TOP_PRODUCTS:
      return { ...state, loader: false };
    case TOP_PRODUCTS_SUCCESS:
      return { ...state, loader: false, topProducts: action.payload };

    case YEARLY_SALES:
      return { ...state, loader: false };
    case YEARLY_SALES_SUCCESS:
      return { ...state, loader: false, yearlySales: action.payload };

    case INVOICES_PAYMENT_STATUSES:
      return { ...state, loader: false };
    case INVOICES_PAYMENT_STATUSES_SUCCESS:
      return { ...state, loader: false, invoicesPaymentStatus: action.payload };

    default:
      return state;
  }
};

export default MasterStatReducer;
