import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  createDocumentTemplateSuccess,
  getListOfDocumentTemplatesSuccess,
  getDocumentTemplatesByIdSuccess,
  deleteDocumentTemplateSuccess,
} from "../actions/DocumentTemplate";
import {
  CREATE_DOCUMENT_TEMPLATE,
  GET_DOCUMENT_TEMPLATE,
  GET_DOCUMENT_TEMPLATE_BY_ID,
  DELETE_DOCUMENT_TEMPLATE,
  UPDATE_DOCUMENT_TEMPLATE,
} from "../../constants/ActionTypes";
import { fetchError } from "../actions/Common";
import { post, get, update, patch } from "../../axios/master";
import { message, notification } from "antd";

const documentTemplate = `documentTemplates`;

function* createDocumentTemplateApi({
  documentTemplate: new_documentTemplate,
  documentTemplates: all_documentTemplates,
  isNew,
}) {
  try {
    let response;
    if (isNew) {
      response = yield call(
        post,
        `${documentTemplate}`,
        new_documentTemplate,
        all_documentTemplates
      );
      yield put(createDocumentTemplateSuccess(response.data));
      yield put(
        getListOfDocumentTemplatesSuccess(
          all_documentTemplates.concat(response.data)
        )
      );
    } else {
      response = yield call(
        update,
        `documentTemplates/${new_documentTemplate.id}`,
        new_documentTemplate,
        all_documentTemplates
      );
      const updated_documentTemplates = all_documentTemplates.map((item) => {
        if (item.id === response.data.id) {
          item = response.data;
        }
        return item;
      });
      yield put(createDocumentTemplateSuccess(response.data));
      yield put(getListOfDocumentTemplatesSuccess(updated_documentTemplates));
    }
  } catch (error) {
    for (const errorMessage of error.message) {
      for (const keys of Object.keys(errorMessage.constraints)) {
        notification.error({
          message: errorMessage.constraints[keys],
          duration: 20
        });
      }
    }
    yield put(fetchError(error));
  }
}

function* updateDocumentTemplateApi({ payload }) {
  const { isDefault, id } = payload;
  try {
    const updatedDocument = yield call(
      patch,
      `${documentTemplate}/default/${id}`,
      { isDefault }
    );
    if (updatedDocument) {
      notification.success({
        message: 'document par défaut est ete mise a jours avec success',
        duration: 20
      });
    }
  } catch (error) {
    for (const errorMessage of error.message) {
      for (const keys of Object.keys(errorMessage.constraints)) {
        notification.error({
          message: errorMessage.constraints[keys],
          duration: 20
        });
      }
    }
    yield put(fetchError(error));
  }
}

function* deleteDocumentTemplateApi({ payload }) {
  const stringToArray = typeof payload === "string" ? [payload] : payload;
  try {
    yield call(update, `${documentTemplate}/delete`, { ids: stringToArray });
    yield put(deleteDocumentTemplateSuccess(payload));
  } catch (error) {
    for (const errorMessage of error.message) {
      for (const keys of Object.keys(errorMessage.constraints)) {
        notification.error({
          message: errorMessage.constraints[keys],
          duration: 20
        });
      }
    }
    yield put(fetchError(error));
  }
}

function* getDocumentTemplateApi({ page, take }) {
  try {
    const response = yield call(
      get,
      page && take
        ? `${documentTemplate}?page=${page}&take=${take}`
        : `${documentTemplate}`
    );
    if (response) {
      yield put(getListOfDocumentTemplatesSuccess(response));
    } else {
      yield put(getListOfDocumentTemplatesSuccess([]));
    }
  } catch (error) {
    for (const errorMessage of error.message) {
      for (const keys of Object.keys(errorMessage.constraints)) {
        notification.error({
          message: errorMessage.constraints[keys],
          duration: 20
        });
      }
    }
    yield put(fetchError(error));
  }
}

function* getDocumentTemplateByIdApi({ payload: id }) {
  try {
    const response = yield call(get, `${documentTemplate}/${id}`);
    yield put(getDocumentTemplatesByIdSuccess(response));
  } catch (error) {
    for (const errorMessage of error.message) {
      for (const keys of Object.keys(errorMessage.constraints)) {
        notification.error({
          message: errorMessage.constraints[keys],
          duration: 20
        });
      }
    }
    yield put(fetchError(error));
  }
}

export function* createDocumentTemplate() {
  yield takeEvery(CREATE_DOCUMENT_TEMPLATE, createDocumentTemplateApi);
}

export function* getDocumentTemplate() {
  yield takeEvery(GET_DOCUMENT_TEMPLATE, getDocumentTemplateApi);
}

export function* getDocumentTemplateById() {
  yield takeEvery(GET_DOCUMENT_TEMPLATE_BY_ID, getDocumentTemplateByIdApi);
}

export function* deleteDocumentTemplate() {
  yield takeEvery(DELETE_DOCUMENT_TEMPLATE, deleteDocumentTemplateApi);
}

export function* updateDocumentTemplate() {
  yield takeEvery(UPDATE_DOCUMENT_TEMPLATE, updateDocumentTemplateApi);
}
export default function* rootSaga() {
  yield all([
    fork(getDocumentTemplate),
    fork(getDocumentTemplateById),
    fork(createDocumentTemplate),
    fork(deleteDocumentTemplate),
    fork(updateDocumentTemplate),
  ]);
}
