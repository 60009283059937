import React, { useImperativeHandle, useState } from "react";
import { Button, message, notification, Upload } from "antd";
import { useDispatch } from "react-redux";
import { UploadOutlined } from "@ant-design/icons";
import { ReactComponent as UploadFile } from "../../assets/images/uploadFile.svg"
import { HIDE_MODAL, HIDE_EDIT_MODAL } from "../../constants/ActionTypes";
import IntlMessages from "util/IntlMessages";

const CustomUploadFile = React.forwardRef(
  ({ name = "file", url, successApiResponse, addEntityApi, isModal }, ref) => {
    const dispatch = useDispatch();
    const [fileList, setFileList] = useState([]);

    const props = {
      onRemove: (file) => {
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        setFileList(newFileList);
      },
      beforeUpload: (file) => {
        setFileList([...fileList, file]);
        return false;
      },
      fileList,
    };

    useImperativeHandle(ref, () => ({ HandleCreateEntity }), [fileList]);

    const HandleCreateEntity = (formData, values, id) => {
      try {
        fileList.forEach((file) => {
          formData.append([name], file);
        });
        for (const [key, value] of Object.entries(values)) {
          formData.append(key, value);
        }
        if (id) {
          let data = {
            id,
            data: formData,
          };
          dispatch(addEntityApi(data));
          dispatch({
            type: HIDE_EDIT_MODAL,
          });
        } else {
          dispatch(addEntityApi(formData));
          dispatch({
            type: HIDE_MODAL,
          });
        }
        setFileList([]);
        if (successApiResponse) {
          dispatch(successApiResponse());
        }
      } catch (error) {
        notification.error({
          message: "upload failed.",
          duration: 20
        })
      }
    };

    return (
      <Upload {...props} forwardedRef={ref}>
        {isModal &&
          <Button icon={<UploadOutlined />}>
            <IntlMessages id="choose.file" />
          </Button>} {!isModal && <UploadFile />}

      </Upload>
    );
  }
);

export default CustomUploadFile;
