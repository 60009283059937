import {
  GET_ALL_INVOICE,
  GET_ALL_DELIVERY_NOTE,
  ADD_INVOICE,
  ADD_INVOICE_SUCCESS,
  ADD_INVOICE_FROM_DELIVERY_NOTE,
  ADD_INVOICE_FROM_DELIVERY_NOTE_SUCCESS,
  DUPLICATE_INVOICE,
  DUPLICATE_INVOICE_SUCCESS,
  DUPLICATE_QUOTATION,
  DUPLICATE_QUOTATION_SUCCESS,
  GET_INVOICE,
  GET_INVOICE_SUCCESS,
  UPDATE_INVOICE,
  UPDATE_INVOICE_SUCCESS,
  UPDATE_META_INVOICE,
  UPDATE_META_INVOICE_SUCCESS,
  DELETE_INVOICE,
  DELETE_INVOICE_SUCCESS,
  SEARCH_DOCTOR_MASTER,
  GET_ALL_INVOICE_SUCCESS,
  GET_ALL_MONTHLY_INVOICE_SUCCESS,
  GET_ALL_DELIVERY_NOTE_SUCCESS,
  GET_INVOICES_REFERENCES,
  GET_INVOICES_REFERENCES_SUCCESS,
  SEARCH_DOCTOR,
  SELECT_ALL_INVOICES,
  SELECT_SINGLE_INVOICE,
  SORT_DOCTORS_BY_NEWEST,
  SHOW_MODAL,
  HIDE_MODAL,
  HIDE_EDIT_MODAL,
  SHOW_EDIT_MODAL,
  HIDE_EDIT_MODAL_INVOICE,
  SHOW_EDIT_MODAL_INVOICE,
  DOWNLOAD_INVOICE,
  DOWNLOAD_INVOICE_SUCCESS,
  SEND_MAIL_INVOICE,
  SEND_MAIL_INVOICE_SUCCESS,
  PAYE_INVOICE,
  PAYE_INVOICE_SUCCESS,
  FETECH_SALES_REVENUE,
  FETECH_SALES_REVENUE_SUCCESS
} from "../../constants/ActionTypes";

const invoicesInitState = {
  addModal: false,
  updateModal: false,
  invoice: {},
  updateModalMeta: false,
  meta: false,
  base: [],
  editable: [],
  loader: true,
  meta: {},
  sales: [],
  invoicesReferences: {}
};

const InvoiceReducers = (state = invoicesInitState, action) => {
  let { type, payload } = action;
  switch (type) {
    case GET_INVOICE:
    case GET_ALL_DELIVERY_NOTE:
    case GET_ALL_INVOICE:
    case GET_INVOICES_REFERENCES:
    case UPDATE_INVOICE:
    case UPDATE_META_INVOICE:
    case PAYE_INVOICE:
    case FETECH_SALES_REVENUE:
      return { ...state, loader: true };
    case FETECH_SALES_REVENUE_SUCCESS: 
    return { ...state, loader:false, sales: action.payload };
    case GET_INVOICE_SUCCESS:
    case UPDATE_INVOICE_SUCCESS:
      return { 
        ...state, 
        loader: false,
        invoice: action.payload, 
        base: payload 
      };
    case GET_INVOICES_REFERENCES_SUCCESS: 
      return { ...state, invoicesReferences: action.payload, loader: false };
    case UPDATE_META_INVOICE_SUCCESS:
    case PAYE_INVOICE_SUCCESS:
      return { ...state, invoice: action.payload, editable: payload };
    case ADD_INVOICE_SUCCESS:
    case ADD_INVOICE_FROM_DELIVERY_NOTE_SUCCESS:
    case DUPLICATE_QUOTATION_SUCCESS:
    case DUPLICATE_INVOICE_SUCCESS:
      return state;
    case GET_ALL_INVOICE_SUCCESS:
      return {
        ...state,
        base: payload.data,
        editable: payload.data,
        meta: payload.meta,
        loader: false
      };
    case GET_ALL_MONTHLY_INVOICE_SUCCESS:
    case GET_ALL_DELIVERY_NOTE_SUCCESS:
      return {
        ...state,
        base: action.payload,
        editable: action.payload,
        loader: false
      }
    case ADD_INVOICE:
    case ADD_INVOICE_FROM_DELIVERY_NOTE:
      return {
        ...state,
        loader: true
      }
    case DOWNLOAD_INVOICE:
    case SEND_MAIL_INVOICE:
      return {
        ...state,
        // loader: true,
      };
    case DOWNLOAD_INVOICE_SUCCESS:
    case SEND_MAIL_INVOICE_SUCCESS:
      return {
        ...state,
        loader: false,
        invoice: action.payload,
      };
    case DELETE_INVOICE:
      return {
        ...state,
        loader: true,
      };
    case DELETE_INVOICE_SUCCESS:
      return {
        ...state,
        loader: false,
        base: action.payload,
        editable: payload,
      };
    case SELECT_ALL_INVOICES:
      let newState = state.editable.map((elm) => {
        let newElm = { ...elm, selected: payload };
        return newElm;
      });
      return {
        ...state,
        editable: newState,
      };
    case SELECT_SINGLE_INVOICE:
      let newValue = state.editable.map((elm) => {
        if (elm.id === payload.id) {
          let newElm = { ...elm, selected: payload.checked };
          return newElm;
        } else {
          return elm;
        }
      });
      return { ...state, editable: newValue };
    case SORT_DOCTORS_BY_NEWEST:
      let result = state.base.sort((x, y) => {
        return new Date(y.createdAt) - new Date(x.createdAt);
      });
      return { ...state, editable: result };
    case SEARCH_DOCTOR:
      return { ...state, editable: payload };
    case SEARCH_DOCTOR_MASTER:
      return { ...state, editable: payload };
    case SHOW_MODAL:
      return { ...state, addModal: true };
    case HIDE_MODAL:
      return { ...state, addModal: false };
    case SHOW_EDIT_MODAL:
      return { ...state, updateModal: true, invoice: action.payload };
    case HIDE_EDIT_MODAL:
      return { ...state, updateModal: false, invoice: {} };
    case SHOW_EDIT_MODAL_INVOICE:
      return {
        ...state,
        updateModalMeta: true,
        meta: true,
        invoice: action.payload,
      };
    case HIDE_EDIT_MODAL_INVOICE:
      return { ...state, updateModalMeta: false, invoice: {} };
    default:
      return state;
  }
};

export default InvoiceReducers;
