import axios from "axios";
import moment from "moment";
import slugify from "slugify";
export const baseUrl = process.env.REACT_APP_SERVER_URI;
const baseConfig = (token) => {
  return {
    headers: {
      Authorization: ["Bearer", token].join(" "),
    },
  };
};

// POST action
const post = async (url, data, additionalConfig = {}) => {
  const config = localStorage.getItem("token"); 
  console.log('baseUrl', baseUrl);
  return await axios
    .post(baseUrl + url, data, { ...baseConfig(config), ...additionalConfig })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (window.location.href.search("signin") === -1)
        if (err.response?.status === 403 || err.response?.status === 401) {
          localStorage.clear();
         window.location.href = "/signin";
        }
      throw err.response?.data;
    });
};

// GET action
const get = (url, additionalConfig = {}) => {
  const config = localStorage.getItem("token");
  return axios
    .get(baseUrl + url, { ...baseConfig(config), ...additionalConfig })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response?.status === 403 || err.response?.status === 401) {
        localStorage.clear();
       window.location.href = "/signin";
      }
      throw err.response?.data;
    });
};

// PATCH action
const patch = (url, data) => {
  const config = localStorage.getItem("token");
  return axios
    .patch(baseUrl + url, data, baseConfig(config))
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response?.status === 403 || err.response?.status === 401) {
        localStorage.clear();
       window.location.href = "/signin";
      }
      throw err.response?.data;
    });
};

// PUT action
const update = (url, data) => {
  const config = localStorage.getItem("token");
  return axios
    .put(baseUrl + url, data, baseConfig(config))
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response?.status === 403 || err.response?.status === 401) {
        localStorage.clear();
       window.location.href = "/signin";
      }
      throw err.response?.data;
    });
};

// DELETE action
const remove = async (url, additionalConfig = {}) => {
  const config = localStorage.getItem("token");
  return await axios
    .delete(baseUrl + url, { ...baseConfig(config), ...additionalConfig })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response?.status === 403 || err.response?.status === 401) {
        localStorage.clear();
       window.location.href = "/signin";
      }
      throw err.response?.data;
    });
};

// Download action
const downloadFile =
  ({ fileName = "Facture", responseType = "blob", createdAt }) =>
  (url, additionalConfig = {}) => {
    const config = localStorage.getItem("token");
    return axios
      .get(baseUrl + url, {
        ...baseConfig(config),
        ...additionalConfig,
        responseType,
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          [
            slugify(fileName, {
              replacement: "_", // replace spaces with replacement character, defaults to `-`
              remove: undefined, // remove characters that match regex, defaults to `undefined`
              lower: false, // convert to lower case, defaults to `false`
              strict: false, // strip special characters except replacement, defaults to `false`
              locale: "fr", // language code of the locale to use
              trim: true, // trim leading and trailing replacement chars, defaults to `true`
            }),
            moment(createdAt).format("DD_MM_YYYY_HH_mm"),
            "pdf",
          ].join(".")
        ); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log("err", err);
        if (err.response?.status === 403 || err.response?.status === 401) {
          localStorage.clear();
         window.location.href = "/signin";
        }
        throw err.response?.data;
      });
  };

export { get, post, remove, patch, update, downloadFile };
