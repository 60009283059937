import React, { useState } from "react";
import { Layout, Popover, Badge, Divider } from "antd";
import { useHistory } from "react-router-dom";
import { ReactComponent as Fold } from "../../assets/images/fold.svg";
import { ReactComponent as Unfold } from "../../assets/images/unfold.svg";
import AppNotification from "components/AppNotification";
import {
  onNavStyleChange,
  toggleCollapsedSideNav,
  switchLanguage,
} from "appRedux/actions/Setting";
import UserProfile from "./../../containers/Sidebar/UserProfile";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_LITE,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
} from "../../constants/ThemeSetting";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import languageData from "./languageData";
import "./style.css";
const { Header } = Layout;

const getPageHeader = (history) => {
  const PAGES_HEADER = {
    clients: "Clients",
    client: "Client",
    products: "Produits",
    product: "Produit",
    quotations: "Devis",
    quotation: "Devis",
    invoices: "Factures",
    invoice: "Facture",
    "delivery-notes": "Bon de Livraison",
    "delivery-note": "Bon de Livraison",
    "release-notes": "Bon de Sortie",
    "release-note": "Bon de Sortie",
    "fee-notes": "Note d'Honoraires",
    "fee-note": "Note d'Honoraires",
    "banking-transactions": "Transactions bancaires",
    "banking-transaction": "Transaction bancaires",
    files: "Achats",
    file: "Achat",
    "rapport-comptable": "Rapport Comptable",
  };
  if (history?.location?.pathname) {
    const pathName = history.location.pathname.split("/")[1];
    return PAGES_HEADER[pathName] || "Home";
  }
};

const Topbar = () => {
  const history = useHistory();
  const role = localStorage.getItem("role");
  const notification = useSelector(({ patientReviews }) => patientReviews);
  const { themeType } = useSelector(({ settings }) => settings);

  const { locale, navStyle } = useSelector(({ settings }) => settings);
  const { navCollapsed, width } = useSelector(({ common }) => common);
  const [flag, setFlag] = useState("fr");
  const dispatch = useDispatch();
  const [showPop, setShowPop] = useState(false);
  const handleShow = () => {
    setShowPop(!false);
  };
  const closePop = () => {
    setShowPop(false);
  };
  const changeLanguage = (language) => {
    setFlag(language.icon);
    dispatch(switchLanguage(language));
  };
  useEffect(() => {
    if (role == "DOCUMENT_TEMPLATE") {
    }
  }, []);

  const languageMenu = () => (
    <ul className="gx-sub-popover">
      {languageData.map((language) => (
        <li
          className="gx-media gx-pointer"
          key={JSON.stringify(language)}
          onClick={() => changeLanguage(language)}
        >
          <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
          <span className="gx-language-text">{language.name}</span>
        </li>
      ))}
    </ul>
  );

  return (
    <Header style={{ minHeight: '80px' }}>
      {navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR ? (
        <div
          onClick={() => {
            dispatch(toggleCollapsedSideNav(!navCollapsed));
          }}
          className="gx-linebar top-bar-styles"
        >
          <span>
            {navStyle === NAV_STYLE_MINI_SIDEBAR ? (
              <Unfold
                className="cursor-pointer"
                onClick={() => {
                  if (navStyle === NAV_STYLE_DRAWER) {
                    dispatch(toggleCollapsedSideNav(!navCollapsed));
                  } else if (navStyle === NAV_STYLE_FIXED) {
                    dispatch(onNavStyleChange(NAV_STYLE_MINI_SIDEBAR));
                  } else if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
                    dispatch(toggleCollapsedSideNav(!navCollapsed));
                  } else {
                    dispatch(onNavStyleChange(NAV_STYLE_FIXED));
                  }
                }}
              />
            ) : (
              <Fold
                className="cursor-pointer"
                onClick={() => {
                  if (navStyle === NAV_STYLE_DRAWER) {
                    dispatch(toggleCollapsedSideNav(!navCollapsed));
                  } else if (navStyle === NAV_STYLE_FIXED) {
                    dispatch(onNavStyleChange(NAV_STYLE_MINI_SIDEBAR));
                  } else if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
                    dispatch(toggleCollapsedSideNav(!navCollapsed));
                  } else {
                    dispatch(onNavStyleChange(NAV_STYLE_FIXED));
                  }
                }}
              />
            )}
          </span>
          <span className="page-title">{getPageHeader(history)}</span>
        </div>
      ) : null}

      {role === "DOCUMENT_TEMPLATE" ? (
        <ul className="gx-header-notifications gx-ml-auto gx-mr-5">
          <Popover
            overlayClassName="gx-popover-horizantal"
            placement="bottomRight"
            content={<AppNotification closePop={closePop} />}
            trigger="click"
            visible={showPop}
            onVisibleChange={handleShow}
          >
            <span className="gx-pointer gx-d-block">
              <Badge count={notification.editable.length}>
                <i className="icon icon-notification" />{" "}
              </Badge>
            </span>
          </Popover>
        </ul>
      ) : (
        <div></div>
      )}
      <div style={{ paddingTop: 20, display: "flex" }}>
        <UserProfile />
        {/*
        <Divider type="vertical" style={{ top: "-0.06em", height: "2.9em" }} />
        
        <Popover
          className="top"
          overlayClassName="gx-popover-horizantal"
          placement="bottomRight"
          content={languageMenu()}
          trigger="click"
        >
          <div className="gx-overview-item cursor-pointer">
            <img
              src={
                flag === "fr"
                  ? require("assets/images/france.webp")
                  : require("assets/images/arab1.webp")
              }
              loading="lazy"
              alt="francais"
              width="40px"
              height="40px"
            />
          </div>
        </Popover>
        */}
      </div>
    </Header>
  );
};

export default Topbar;
