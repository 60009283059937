import React, { useState } from "react";
import NotificationResponse from "./NotificationResponse";
import "./styles.css";
import moment from "moment";
const NotificationItem = ({ notification, close }) => {
  const { icon, image, title, time } = notification;
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    close();
    setOpen(true);
  };
  const displayStatus = (status) => {
    switch (status) {
      case "TOUS LES 5 MINUTES":
        return "Comment allez vous aujourd’hui? ";
      case "TOUS LES JOURS":
        return "Comment allez vous aujourd’hui? ";
      case "TOUS LES 2 JOURS":
        return "Comment allez vous apres ces deux jours? ";
      case "TOUS LES 3 JOURS":
        return "Comment allez vous apres ces trois jours?";
      case "TOUTES LES SEMAINES":
        return "Comment allez vous cette semaine? ";
      case "TOUTES LES 2 SEMAINES":
        return "Comment allez vous apres ces deux semaines?";
      case "TOUS LES MOIS":
        return "Comment allez ce mois?";
      case "TOUS LES 2 MOIS":
        return "Comment allez apres ces deux mois?";
      case "TOUS LES 3 MOIS":
        return "Comment allez apres ces trois mois?";
      default:
        return "Comment allez vous aujourd’hui? ";
    }
  };
  return (
    <React.Fragment>
      <li className="gx-media">
        {/* <Avatar className="gx-size-40 gx-mr-3" alt={image} src={image} /> */}
        <div
          className="gx-media-body gx-align-self-center notification-item"
          onClick={handleClick}
        >
          <h5 className="gx-fs-sm gx-mb-0">
            {`DR. ${notification.consultation.doctor.firstname} ${notification.consultation.doctor.lastname
              }: ${displayStatus(
                notification.consultation.surveillance.period
              )}   `}
          </h5>
          <i className={`icon icon-calendar gx-pr-2`} />
          <span className="gx-meta-date">
            <small>{moment(notification.date).format("DD.MM.YYYY")}</small>
          </span>
        </div>
      </li>
      {open && <NotificationResponse
        open={open}
        setOpen={setOpen}
        notification={notification}
      />}
    </React.Fragment>
  );
};

export default NotificationItem;
