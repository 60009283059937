import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { message, notification } from "antd";
import {
  SIGNIN_USER,
  SIGNOUT_USER,
  SIGNUP_USER,
  FORGET_PASSWORD,
  RESET_PASSWORD,
} from "constants/ActionTypes";
import {
  showAuthMessage,
  userSignInSuccess,
  userSignOutSuccess,
  userSignUpSuccess,
  forgetPasswordSuccess,
  getCurrentUserSuccess,
  hideAuthLoader,
  updatePasswordSuccess,
} from "../../appRedux/actions/Auth";
import { fetchError } from "../actions/Common";

import { get, post } from "../../axios/master";
import { GET_CURRENT_USER, UPDATE_PASSWORD } from "../../constants/ActionTypes";

const auth = `auth`;

function* createUserWithEmailPassword({ payload }) {
  const { user, route } = payload;
  try {
    let signUpUser;
    if (route) {
      signUpUser = yield call(post, [auth, route].join("/"), user);
    } else {
      signUpUser = yield call(post, `${auth}/register`, user);
    }
    notification.success({
      message:
        `Veuillez consulter votre boite mail pour valider votre inscription`,
      duration: 20
    })
    if (!signUpUser) {
      yield put(showAuthMessage(signUpUser));
    } else {
      setTimeout(() => {
        window.location.href = "/signin";
        userSignUpSuccess();
      }, 3000);
    }
  } catch (error) {
    if (error.statusCode === 500) {
      notification.error({
        message: `Erreur Serveur - Veuillez nous contacter`,
        duration: 20
      });
    } else {
      if (error && error.statusCode === 409) {
        notification.error({
          message: `L'Email déjà utilisé`,
          duration: 20
        });
      } else {
        for (const errorMessage of error.message) {
          for (const keys of Object.keys(errorMessage.constraints)) {
            notification.error({
              message: errorMessage.constraints[keys],
              duration: 20
            });
          }
        }
      }
    }
    yield put(hideAuthLoader());
    yield put(fetchError(error));
  }
}

function* signInUserWithEmailPassword({ payload }) {
  try {
    const currentUser = yield call(post, `${auth}/login`, payload);
    if (!currentUser) {
      yield put(showAuthMessage("Email ou mot de passe incorrect"));
    }
    localStorage.setItem("token", currentUser.token.accessToken);
    window.location.href = "/";
    yield put(userSignInSuccess(currentUser.user));
  } catch (error) {
    notification.error({
      message: "Email ou mot de passe incorrect",
      duration: 20
    });
    yield put(showAuthMessage("Email ou mot de passe incorrect"));
    yield put(hideAuthLoader());
  }
}

function* signOut() {
  try {
    const signOutUser = {
      authUser: null,
      initURL: "/",
      loader: false,
    };

    if (window.location.href.search("/signin") === -1) {
      localStorage.clear();
      window.location.href = "/signin";
      yield put(userSignOutSuccess(signOutUser));
    }
  } catch (error) {
    for (const errorMessage of error.message) {
      for (const keys of Object.keys(errorMessage.constraints)) {
        notification.error({
          message: errorMessage.constraints[keys],
          duration: 20
        });
      }
    }
    yield put(fetchError(error));
  }
}

function* forgetPasswordApi({ payload }) {
  try {
    yield call(post, `${auth}/reset-password-link`, payload);
    yield put(forgetPasswordSuccess());
    notification.success({
      message: "Veuillez vérifier votre adresse email",
      duration: 20
    });
  } catch (error) {
    console.log("error.message.statusCode", error)
    yield put(hideAuthLoader());
    notification.error({
      message: `L'adresse email indiquée ne correspond à aucun compte enregistré`,
      duration: 20
    });
  }
}

function* resetPasswordApi({ payload }) {
  try {
    yield call(post, "updatePasswordTmp", payload);
    yield put(updatePasswordSuccess());
  } catch (error) {
    for (const errorMessage of error.message) {
      for (const keys of Object.keys(errorMessage.constraints)) {
        notification.error({
          message: errorMessage.constraints[keys],
          duration: 20
        });
      }
    }
    yield put(fetchError(error));
    yield put(hideAuthLoader());
  }
}

function* updatePasswordApi({ payload }) {
  try {
    const response = yield call(post, `${auth}/update-password/`, payload);

    if (!response || !response.success) {
      notification.error({
        message: "L'ancien mot de passe saisie est incorrect",
        duration: 20
      });
    } else {
      notification.success({
        message: "Votre mot de passe a été mis à jour",
      duration: 20
    });
    }
  } catch (error) {
    for (const errorMessage of error.message) {
      for (const keys of Object.keys(errorMessage.constraints)) {
        notification.error({
          message: errorMessage.constraints[keys],
          duration: 20
        });
      }
    }
    yield put(fetchError(error));
    yield put(hideAuthLoader());
  }
}

function* meApi() {
  try {
    const currentUser = yield call(get, `${auth}/me`);
    if (currentUser) {
      yield put(getCurrentUserSuccess(currentUser));
      if (window.location.href.search("/signin") !== -1) {
        window.location.href = "/";
      }
    } else {
      localStorage.clear();
    }
  } catch (error) {
    console.log(error);
    if (error && error.statusCode === 500) {
      localStorage.clear()
      window.location.href = "/";
    }
    /* for (const errorMessage of error.message) {
      for (const keys of Object.keys(errorMessage.constraints)) {
        notification.error({
            message: errorMessage.constraints[keys],
            duration: 20
          });
      }
    }
    yield put(fetchError(error)); */
  }
}

export function* me() {
  yield takeEvery(GET_CURRENT_USER, meApi);
}

export function* createUserAccount() {
  yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export function* forgetPassword() {
  yield takeEvery(FORGET_PASSWORD, forgetPasswordApi);
}

export function* resetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPasswordApi);
}

export function* updatePassword() {
  yield takeEvery(UPDATE_PASSWORD, updatePasswordApi);
}

export default function* rootSaga() {
  yield all([
    fork(me),
    fork(createUserAccount),
    fork(signInUser),
    fork(signOutUser),
    fork(forgetPassword),
    fork(resetPassword),
    fork(updatePassword),
  ]);
}
