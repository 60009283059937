import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { get, post } from "../../axios/master";

import {
  ASSIGN_ACCOUNTANT,
  GET_ALL_USERS,
  GET_ACCOUNTANT_USERS,
  GET_USER,
} from "../../constants/ActionTypes";
import { message, notification } from "antd";
import {
  assignAccountantSuccess,
  getListOfUsersSuccess,
  getAccountantUsersSuccess,
  getUserSuccess,
} from "../actions";

const baseUrl = `users`;

function* getUserApi({ payload }) {
  const { userId } = payload;
  try {
    const user = yield call(get, [baseUrl, userId].join("/"));
    yield put(getUserSuccess(user));
  } catch (error) {
    notification.error({
      message: error,
      duration: 20
    });;
  }
}

function* getUsersApi({ filter, page, take }) {
  try {
    let response = yield call(
      get,
      page && take ? `${baseUrl}?page=${page}&take=${take}` : `${baseUrl}`
    );
    if (response) {
      yield put(getListOfUsersSuccess(response));
    }
  } catch (error) {
    notification.error({
      message: error,
      duration: 20
    });;
  }
}

function* getUsersByAccountant() {
  try {
    let response = yield call(get, `${baseUrl}/accountant-users`);

    if (response) {
      yield put(getAccountantUsersSuccess(response))
    }
  } catch (error) {
    notification.error({
      message: error,
      duration: 20
    });
  }
}


function* assignAccountantApi({ payload }) {
  const { accountantEmail } = payload;
  try {
    const user = yield call(post, ["auth", "send-invitation-link"].join("/"), {
      email: accountantEmail,
    });
    if (user && user.success) {

      yield put(assignAccountantSuccess(user));
      notification.success({
        message: "Votre invitation a bien été envoyé",
        duration: 20
      });
    } else {
      notification.error({
        message: 'Erreur technique : veuillez contacter le support',
        duration: 20
      });
    }
  } catch (error) {
    notification.error({
      message: 'Erreur technique : veuillez contacter le support',
      duration: 20
    });
  }
}

export function* getUser() {
  yield takeEvery(GET_USER, getUserApi);
}

export function* getUsers() {
  yield takeEvery(GET_ALL_USERS, getUsersApi);
}

export function* getUsersAccountant() {
  yield takeEvery(GET_ACCOUNTANT_USERS, getUsersByAccountant);
}

export function* assignAccountant() {
  yield takeEvery(ASSIGN_ACCOUNTANT, assignAccountantApi);
}

export default function* rootSaga() {
  yield all([fork(getUser),
  fork(getUsers),
  fork(getUsersAccountant),
  fork(assignAccountant)]);
}
