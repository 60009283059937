import { message, notification } from "antd";
import {
  ADD_BANK_ACCOUNT,
  ADD_BANK_ACCOUNT_SUCCESS,
  GET_ALL_BANK_ACCOUNTS,
  GET_ALL_BANK_ACCOUNTS_SUCCESS,
  UPDATE_BANK_ACCOUNT,
  UPDATE_BANK_ACCOUNT_SUCCES,
  DELETE_BANK_ACCOUNT,
  DELETE_BANK_ACCOUNT_SUCCESS,
  GET_BANK_ACCOUNT,
  GET_BANK_ACCOUNT_SUCCESS,
  SEARCH_DOCTOR,
  SHOW_MESSAGE,
  SEARCH_DOCTOR_MASTER,
} from "../../constants/ActionTypes";

export const addBankAccount = (payload) => {
  return {
    type: ADD_BANK_ACCOUNT,
    payload,
  };
};

export const addBankAccountSuccess = () => {
  return {
    type: ADD_BANK_ACCOUNT_SUCCESS,
  };
};

export const getAllBankAccounts = ( page, take) => {
  return {
    type: GET_ALL_BANK_ACCOUNTS,
    page,
		take
  };
};

export const getAllBankAccountsSuccess = (payload) => {
  return {
    type: GET_ALL_BANK_ACCOUNTS_SUCCESS,
    payload,
  };
};

export const updateBankAccount = (payload) => {
  return {
    type: UPDATE_BANK_ACCOUNT,
    payload,
  };
};

export const updateBankAccountSuccess = (payload) => {
  return {
    type: UPDATE_BANK_ACCOUNT_SUCCES,
    payload,
  };
};

export const deleteBankAccount = (id, oldList) => {
  return {
    type: DELETE_BANK_ACCOUNT,
    payload: {id, oldList},
  };
};

export const deleteBankAccountSuccess = (payload) => {
  return {
    type: DELETE_BANK_ACCOUNT_SUCCESS,
    payload,
  };
};

export const getBankAccount = (payload) => {
  return {
    type: GET_ALL_BANK_ACCOUNTS,
    payload,
  };
};

export const getBankAccountSuccess = (payload) => {
  return {
    type: GET_BANK_ACCOUNT_SUCCESS,
    payload,
  };
};

export const showBankAccountMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message,
  };
};

export const searchBankAccount = (value, list, filterOption= "creditCardName") => {
  try{
    let selected;
    if (value != ""){
      selected = list.filter(
        (item, index) => 
        item[filterOption]
          .toString()
          .toLowerCase()
          .search(value.toLowerCase()) !== -1
      );
    } else {
      selected = list;
    }
    return {
      type: SEARCH_DOCTOR,
      payload: selected ? selected: [],
    };
  } catch (error) {
    console.error(error);
  }
};

export const _m = (list, selectedOption) => {
  let selected = [];
  if (Object.values(selectedOption)[0] === 1){
    selected = list;
  } else {
    let dateNow= new Date();
    dateNow.setDate(dateNow.getDate() - 0);
    selected = list.filter(
      (item) => 
      item[Object.keys(selectedOption)[0]].split("T")[0] ===
      dateNow.toISOString().split("T")[0]
    );
  }
  return {
    type: SEARCH_DOCTOR_MASTER, 
    payload: selected ? selected: [],
  };
};