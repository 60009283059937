import {
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PAYMENT_TERMS,
  UPDATE_PAYMENT_TERMS_SUCCESS,
  IDENTIFIED_USER,
  IDENTIFIED_USER_SUCCES,
} from "../../constants/ActionTypes";

export const updateProfile = (data) => {
  return {
    type: UPDATE_PROFILE,
    payload: data,
  };
};

export const updateProfileSuccess = (data) => {
  return {
    type: UPDATE_PROFILE_SUCCESS,
    payload: data,
  };
};

export const updatePaymentTerms = (data) => {
  return {
    type: UPDATE_PAYMENT_TERMS,
    payload: data,
  }
}

export const updatePaymentTermsSuccess = (data) => {
  return {
    type: UPDATE_PAYMENT_TERMS_SUCCESS,
    payload: data
  }
}

export const identifiedUser = (data, redirect, path) => {
  return {
    type: IDENTIFIED_USER,
    payload: data,
    redirect, 
    path
  };
};

export const identifiedUserSuccess = (data) => {
  return {
    type: IDENTIFIED_USER_SUCCES,
    payload: data,
  };
};
