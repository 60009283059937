import {
  ASSIGN_ACCOUNTANT,
  ASSIGN_ACCOUNTANT_SUCCESS,
  GET_ALL_USERS,
  GET_ALL_USERS_SUCCESS,
  GET_USER,
  GET_USER_SUCCESS,
  GET_ACCOUNTANT_USERS,
  GET_ACCOUNTANT_USERS_SUCCESS
} from "../../constants/ActionTypes";

const profilesInitState = {
  user: {},
  base: [],
  editable: [],
  loader: true,
  meta: {},
};

const UserReducers = (state = profilesInitState, action) => {
  let { type, payload } = action;
  switch (type) {
    case GET_USER:
    case GET_ALL_USERS:
    case GET_ACCOUNTANT_USERS:
    case ASSIGN_ACCOUNTANT:
      return { ...state, loader: true };
    case GET_USER_SUCCESS:
      return { ...state, loader: false, user: payload, base: payload };
    case GET_ACCOUNTANT_USERS_SUCCESS: 
      return { ...state, loader: false, base: payload, editable: payload}
    case GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        base: payload.data || [],
        editable: payload.data || [],
        loader: false,
        meta: payload.meta,
      };
    case ASSIGN_ACCOUNTANT_SUCCESS:
      return { ...state, loader: false };
    default:
      return state;
  }
};

export default UserReducers;
