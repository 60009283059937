import {
  ADD_INVOICE,
  ADD_INVOICE_SUCCESS,
  ADD_INVOICE_FROM_DELIVERY_NOTE,
  ADD_INVOICE_FROM_DELIVERY_NOTE_SUCCESS,
  DUPLICATE_INVOICE,
  DUPLICATE_INVOICE_SUCCESS,
  TRANSFERT_QUOTATION_TO_INVOICE,
  TRANSFERT_QUOTATION_TO_INVOICE_SUCCESS,
  GET_ALL_INVOICE,
  GET_ALL_INVOICE_SUCCESS,
  GET_ALL_DELIVERY_NOTE,
  GET_ALL_DELIVERY_NOTE_SUCCESS,
  GET_ALL_MONTHLY_INVOICE,
  GET_ALL_MONTHLY_INVOICE_SUCCESS,
  GET_INVOICES_REFERENCES,
  GET_INVOICES_REFERENCES_SUCCESS,
  UPDATE_INVOICE,
  UPDATE_INVOICE_SUCCESS,
  UPDATE_META_INVOICE,
  UPDATE_META_INVOICE_SUCCESS,
  DELETE_INVOICE,
  DELETE_INVOICE_SUCCESS,
  PAYE_INVOICE,
  PAYE_INVOICE_SUCCESS,
  GET_INVOICE,
  GET_INVOICE_SUCCESS,
  SEARCH_DOCTOR,
  SHOW_MESSAGE,
  SEARCH_DOCTOR_MASTER,
  DOWNLOAD_INVOICE,
  DOWNLOAD_INVOICE_SUCCESS,
  SEND_MAIL_INVOICE,
  SEND_MAIL_INVOICE_SUCCESS,
  FETECH_SALES_REVENUE,
  FETECH_SALES_REVENUE_SUCCESS,
} from "../../constants/ActionTypes";

export const addInvoice = (payload, redirect, path, invoiceType) => {
  return {
    type: ADD_INVOICE,
    payload,
    invoiceType,
    redirect,
    path
  };
};

export const addInvoiceSuccess = (payload) => {
  return {
    type: ADD_INVOICE_SUCCESS,
    payload,
  };
};

export const addInvoiceFromDeliveryNote = (payload, redirect, path) => {
  return {
    type: ADD_INVOICE_FROM_DELIVERY_NOTE,
    payload,
    redirect,
    path
  }
}

export const AddInvoiceFromDeliveyNote = (payload) => {
  return {
    type: ADD_INVOICE_FROM_DELIVERY_NOTE_SUCCESS,
    payload
  }
}

export const duplicateInvoice = (payload) => {
  return {
    type: DUPLICATE_INVOICE,
    payload,
  };
};

export const duplicateInvoiceSuccess = (payload) => {
  return {
    type: DUPLICATE_INVOICE_SUCCESS,
    payload,
  };
};

export const transfertQuotationToInvoice = (payload) => {
  return {
    type: TRANSFERT_QUOTATION_TO_INVOICE,
    payload,
  };
};

export const transfertQuotationToInvoiceSuccess = (payload) => {
  return {
    type: TRANSFERT_QUOTATION_TO_INVOICE_SUCCESS,
    payload,
  };
};

export const updateInvoice = (payload) => {
  return {
    type: UPDATE_INVOICE,
    payload,
  };
};

export const updateInvoiceSuccess = (payload) => {
  return {
    type: UPDATE_INVOICE_SUCCESS,
    payload,
  };
};

export const updateMetaInvoice = (payload) => {
  return {
    type: UPDATE_META_INVOICE,
    payload,
  };
};

export const updateMetaInvoiceSuccess = (payload) => {
  return {
    type: UPDATE_META_INVOICE_SUCCESS,
    payload,
  };
};

export const downloadInvoice = (payload) => {
  return {
    type: DOWNLOAD_INVOICE,
    payload,
  };
};

export const downloadInvoiceSuccess = (payload) => {
  return {
    type: DOWNLOAD_INVOICE_SUCCESS,
    payload,
  };
};

export const sendInvoice = (payload) => {
  return {
    type: SEND_MAIL_INVOICE,
    payload,
  };
};

export const sendInvoiceSuccess = (payload) => {
  return {
    type: SEND_MAIL_INVOICE_SUCCESS,
    payload,
  };
};

export const getInvoicesReferences = (payload) => {
  return {
    type: GET_INVOICES_REFERENCES,
    payload,
  }
}

export const getInvoicesReferencesSuccess = (payload) => {
  return {
    type: GET_INVOICES_REFERENCES_SUCCESS,
    payload,
  }
}

export const getInvoice = (payload) => {
  return {
    type: GET_INVOICE,
    payload,
  };
};

export const getInvoiceSuccess = (invoice) => {
  return {
    type: GET_INVOICE_SUCCESS,
    payload: invoice,
  };
};

export const payeInvoice = (id, invoiceList) => {
  return {
    type: PAYE_INVOICE,
    payload: { id, invoiceList }
  }
}

export const payeInvoiceSuccess = (payload) => {
  return {
    type: PAYE_INVOICE_SUCCESS,
    payload
  }
}

export const deleteInvoice = (id, oldList) => {
  return {
    type: DELETE_INVOICE,
    payload: { id, oldList },
  };
};

export const deleteInvoiceSuccess = (payload) => {
  return {
    type: DELETE_INVOICE_SUCCESS,
    payload,
  };
};

export const getListOfInvoices = (page, take, invoiceType) => {
  return {
    type: GET_ALL_INVOICE,
    page,
    take,
    invoiceType,
  };
};

export const getListOfInvoicesSuccess = (invoices) => {
  return {
    type: GET_ALL_INVOICE_SUCCESS,
    payload: invoices,
  };
};

export const getListOfMonthlyInvoices = (yearMonth, clientId) => {
  return {
    type: GET_ALL_MONTHLY_INVOICE,
    yearMonth,
    clientId
  }
}

export const getListOfMonthlyInvoicesSuccess = (invoices) => {
  return {
    type: GET_ALL_MONTHLY_INVOICE_SUCCESS,
    payload: invoices,
  }
}

export const fetechSalesRevenue = (month, clientId) => {
  return {
    type: FETECH_SALES_REVENUE,
    month,
    clientId
  }
}

export const fetechSalesRevenueSuccess = (payload) => {
  return {
    type: FETECH_SALES_REVENUE_SUCCESS,
    payload
  }
}

export const getListOfDeliveryNote = (payload) => {
  return {
    type: GET_ALL_DELIVERY_NOTE,
    payload
  }
}

export const getListOfDeliveryNoteSuccess = (invoices) => {
  return {
    type: GET_ALL_DELIVERY_NOTE_SUCCESS,
    payload: invoices
  }
}

export const showInvoiceMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message,
  };
};

export const searchInvoice = (value, list, filterOption = "id") => {
  try {
    let selected;
    if (value !== "") {
      selected = list.filter(
        (item, index) =>
          item[filterOption]
            .toString()
            .toLowerCase()
            .search(value.toLowerCase()) !== -1
      );
    } else {
      selected = list;
    }
    return {
      type: SEARCH_DOCTOR,
      payload: selected ? selected : [],
    };
  } catch (error) {
    console.error(error);
  }
};
export const _m = (list, selectedOption) => {
  let selected = [];
  if (Object.values(selectedOption)[0] === 1) {
    selected = list;
  } else {
    let dateNow = new Date();
    dateNow.setDate(dateNow.getDate() - 0); // - 0 ==> today
    selected = list.filter(
      (item) =>
        item[Object.keys(selectedOption)[0]].split("T")[0] ===
        dateNow.toISOString().split("T")[0]
    );
  }
  return {
    type: SEARCH_DOCTOR_MASTER,
    payload: selected ? selected : [],
  };
};
