import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { post, get, update, remove } from "../../axios/master";
import { 
  ADD_BANK_ACCOUNT,
  DELETE_BANK_ACCOUNT,
  GET_ALL_BANK_ACCOUNTS,
  GET_BANK_ACCOUNT,
  UPDATE_BANK_ACCOUNT,
} from "../../constants/ActionTypes";
import {
  getAllBankAccountsSuccess,
  getBankAccountSuccess,
  showBankAccountMessage,
  deleteBankAccountSuccess,
} from "../actions/BankAcount";
import { fetchError, hideDocModal } from "../actions/Common";
import { message, notification } from "antd";

const baseUrl = "bankAccounts";

function* addNewBankAccount({ payload }) {
  try {
    const addBankAccount = yield call(post, baseUrl, payload);
    if (addBankAccount) {
      yield put(showBankAccountMessage("Circuit Ajouter avec succées"));
      yield put(hideDocModal());
    } else {
      // Handle unsuccessful addition here
    }
  } catch (error) {
    console.log("error.message.statusCode", error.statusCode);
    if (error && error.statusCode === 404) {
      notification.error({
        message: 'Vous devez saisir le numéro de compte bancaire ou bien l\'IBAN',
      });
    } else {
      for (const errorMessage of error.message) {
        for (const keys of Object.keys(errorMessage.constraints)) {
          notification.error({
            message: errorMessage.constraints[keys],
            duration: 20
          });
        }
      }
    }
    yield put(fetchError(error));
  }
}
function* getBankAccounts ({page, take}) {
  let response = yield call (get,page && take ? `${baseUrl}?page=${page}&take=${take}`: `${baseUrl}`);
  if (response){
    yield put (getAllBankAccountsSuccess(response));
  } else {
    yield put (showBankAccountMessage(response.message));
  }
}
function* getSingleBankAccount ({ payload }) {
  try {
    const singleBankAccount = yield call (get, `${baseUrl}/${payload}`)
    if (singleBankAccount) {
      yield put (getBankAccountSuccess(singleBankAccount))
    }
  } catch (error) {
    for (const errorMessage of error.message) {
      for (const keys of Object.keys(errorMessage.constraints)){
        notification.error({
            message: errorMessage.constraints[keys],
            duration: 20
          });
      }
    }
    yield put (fetchError(error));
  }
}
function* updateBankAccount ({ payload }) {
  let { id, data } = payload;
  try {
    const result = yield call(update, `${baseUrl}/${id}`, data);
    if (result) {
      yield put(showBankAccountMessage("BankAccount modifier avec succées"))
    }
  } catch (error) {
    if (error && error.statusCode === 404) {
      notification.error({
        message: 'Vous devez saisir le numéro de compte bancaire ou bien l\'IBAN',
        duration:5
      });
    } else {
      for (const errorMessage of error.message){
        for (const keys of Object.keys(errorMessage.constraints)){
          notification.error({
            message: errorMessage.constraints[keys],
            duration:5
      });
        }
      }
  }
    yield put(fetchError(error));
  }
}
function* deleteBankAccount({ payload: {id, oldList } }) {
  try {
    const response = yield call(remove, `${baseUrl}/${id}`);
    const newList = oldList.filter(({ _id }) => _id !== id );
    yield put(deleteBankAccountSuccess(newList));
    if (response) {
      yield put(showBankAccountMessage("BankAccount Supprimer avec succées"));
    }
  } catch (error) {
    for (const errorMessage of error.message){
      for (const keys of Object.keys(errorMessage.constraints)){
        error.message(errorMessage.constraints[keys]);
      }
    }
    yield put(fetchError(error));
  }
}

export function* addBankAccount() {
  yield takeEvery(ADD_BANK_ACCOUNT, addNewBankAccount);
}

export function* getAllBankAccounts() {
  yield takeEvery(GET_ALL_BANK_ACCOUNTS, getBankAccounts);
}

export function* getBankAccount() {
  yield takeEvery(GET_BANK_ACCOUNT, getSingleBankAccount);
}

export function* updateOneBankAccount() {
  yield takeEvery(UPDATE_BANK_ACCOUNT, updateBankAccount);
}

export function* deleteOneBankAccount() {
  yield takeEvery(DELETE_BANK_ACCOUNT, deleteBankAccount);
}

export default function* rootSaga() {
  yield all([
    fork(addBankAccount),
    fork(getAllBankAccounts),
    fork(getBankAccount),
    fork(updateOneBankAccount),
    fork(deleteOneBankAccount),
  ]);
}