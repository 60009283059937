import React, { useState, useEffect } from "react";
import { Modal, Button, Col, Row, Rate, message, Select } from "antd";
import { useDispatch } from "react-redux";
import { descriptions, oxygene_50_100 } from "./constants";

const { Option } = Select
const NotificationResponse = ({ open, setOpen, notification }) => {
  const dispatch = useDispatch();

  const [review, setReview] = useState({
    rating: 0
  });

  useEffect(() => {
    if (notification && notification.consultation && notification.consultation.pattern === "COVID") {
      setReview({
        rating: 0,
        saturationO2: 50
      })
    } else {
      setReview({
        rating: 0
      })
    }
  }, [notification])

  const submitReview = () => {
    setOpen(false);
    setReview({
      ...review,
      rating: 0
    })
    notification.success({
      message: "Votre Review a été envoyé ",
      duration: 20
    });
  };

  const handleChange = (name) => (value) => {
    try {
      if (notification && notification.consultation && notification.consultation.pattern === "COVID") {
        setReview({
          ...review,
          [name]: parseInt(value)
        })
      } else {
        setReview({
          rating: value
        })
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Modal
      centered
      visible={open}
      onCancel={() => setOpen(false)}
      closable={false}
      footer={null}
    >
      <Row>
        <Col span={16}>
          <div style={{ paddingBottom: '20px', marginLeft: '5px' }}>
            <span>
              <Rate
                tooltips={descriptions}
                onChange={handleChange('rating')}
                value={review.rating}
                style={{ backgroundColor: '#038fdd' }}
              />
              {review.rating ? (
                <span className="ant-rate-text">{descriptions[review.rating - 1]}</span>
              ) : (
                ""
              )}
            </span>
          </div>
          <Select
            disabled={notification && notification.consultation && notification.consultation.pattern !== "COVID"}
            style={{ width: 150 }}
            defaultValue={oxygene_50_100[0]}
            onChange={handleChange('saturationO2')}
          >
            {oxygene_50_100.map((item, index) => <Option key={`saturationO2${index}`} value={item}>{item}</Option>)}
          </Select>
        </Col>
        <Col span={8}>
          <Button type="primary" onClick={submitReview}>
            Envoyer
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default NotificationResponse;
