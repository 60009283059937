// sagas/formStateSaga.js
import { takeLatest, put } from 'redux-saga/effects';
import { SAVE_FORM_STATE, UPDATE_FORM_STATE_SUCCESS } from '../../constants/ActionTypes';

function* saveFormStateSaga(action) {
 try {
    // Here you can save the form state to your backend or any other storage
    // For demonstration, we'll just dispatch an action to update the Redux store
    yield put({ type: UPDATE_FORM_STATE_SUCCESS, payload: action.payload });
 } catch (error) {
    console.error(error);
 }
}

export default function* watchSaveFormState() {
 yield takeLatest(SAVE_FORM_STATE, saveFormStateSaga);
}
