import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { fetchError } from "../actions/Common";
import { post, get } from "../../axios/master";
import { TOP_CLIENTS } from "../../constants/ActionTypes";

import {
  topClientsSuccess,
  topProductsSuccess,
  yearlySalesSuccess,
  invoicesPaymentStatusSuccess,
} from "../actions/Stats";

const apiUrl = "stats";

function* topClientsApi() {
  try {
    const response = yield call(get, [apiUrl, "top-clients"].join("/"));
    yield put(topClientsSuccess(response));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* topProductsApi() {
  try {
    const response = yield call(get, [apiUrl, "top-products"].join("/"));
    yield put(topProductsSuccess(response));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* yearlySalesApi() {
  try {
    const response = yield call(get, [apiUrl, "top-yearly-sales"].join("/"));
    yield put(yearlySalesSuccess(response));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* invoicesPaymentStatusApi() {
  try {
    const response = yield call(
      get,
      [apiUrl, "invoices-payment-status"].join("/")
    );
    yield put(invoicesPaymentStatusSuccess(response));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* topClients() {
  yield takeEvery(TOP_CLIENTS, topClientsApi);
}

function* topProducts() {
  yield takeEvery(TOP_CLIENTS, topProductsApi);
}

function* yearlySales() {
  yield takeEvery(TOP_CLIENTS, yearlySalesApi);
}

function* invoicesPaymentStatus() {
  yield takeEvery(TOP_CLIENTS, invoicesPaymentStatusApi);
}

export default function* rootSaga() {
  yield all([
    fork(topClients),
    fork(topProducts),
    fork(yearlySales),
    fork(invoicesPaymentStatus),
  ]);
}
