export const oxygene_50_100 = []

for (let index = 50; index <= 100; index++) {
    oxygene_50_100.push(index.toString())
}

export const descriptions = [
    "Pas bien du tout",
    "Comme avant",
    "Mieux qu'avant",
    "Bien",
    "Excellent",
];