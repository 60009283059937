import {
  ADD_TAX_CLASS,
  ADD_TAX_CLASS_SUCCESS,
  GET_TAX_CLASS,
  GET_TAX_CLASS_SUCCESS,
  UPDATE_TAX_CLASS,
  UPDATE_TAX_CLASS_SUCCESS,
  DELETE_TAX_CLASSES,
  DELETE_TAX_CLASSES_SUCCESS,
  SEARCH_DOCTOR_MASTER,
  GET_ALL_TAX_CLASSES_SUCCESS,
  SEARCH_DOCTOR,
  SELECT_ALL_TAX_CLASSES,
  SELECT_SINGLE_TAX_CLASS,
  SORT_DOCTORS_BY_NEWEST,
  SHOW_MODAL,
  HIDE_MODAL,
  HIDE_EDIT_MODAL,
  SHOW_EDIT_MODAL,
} from "../../constants/ActionTypes";

const taxClassesInitState = {
  addModal: false,
  updateModal: false,
  taxClass: {},
  base: [],
  editable: [],
  loader: true,
  meta:{}
};

const TaxClassReducers = (state = taxClassesInitState, action) => {
  let { type, payload } = action;
  switch (type) {
    case GET_TAX_CLASS:
    case UPDATE_TAX_CLASS:
      return { ...state, };
    case GET_TAX_CLASS_SUCCESS:
    case UPDATE_TAX_CLASS_SUCCESS:
      return { ...state, taxClass: action.payload };
    case ADD_TAX_CLASS_SUCCESS:
      return state;
    case GET_ALL_TAX_CLASSES_SUCCESS:
      return { ...state, base: payload.data, editable: payload.data, loader: false, meta:payload.meta };
    case DELETE_TAX_CLASSES:
      return {
        ...state,
        loader: true,
      };
    case DELETE_TAX_CLASSES_SUCCESS:
      return {
        ...state,
        loader: false,
        base: action.payload
      };
    case SELECT_ALL_TAX_CLASSES:
      let newState = state.editable.map((elm) => {
        let newElm = { ...elm, selected: payload };
        return newElm;
      });
      return {
        ...state,
        editable: newState,
      };
    case SELECT_SINGLE_TAX_CLASS:
      let newValue = state.editable.map((elm) => {
        if (elm.id === payload.id) {
          let newElm = { ...elm, selected: payload.checked };
          return newElm;
        } else {
          return elm;
        }
      });
      return { ...state, editable: newValue };
    case SORT_DOCTORS_BY_NEWEST:
      let result = state.base.sort((x, y) => {
        return new Date(y.createdAt) - new Date(x.createdAt);
      });
      return { ...state, editable: result };
    case SEARCH_DOCTOR:
      return { ...state, editable: payload };
    case SEARCH_DOCTOR_MASTER:
      return { ...state, editable: payload };
    case SHOW_MODAL:
      return { ...state, addModal: true };
    case HIDE_MODAL:
      return { ...state, addModal: false };
    case SHOW_EDIT_MODAL:
      return { ...state, updateModal: true, taxClass: action.payload };
    case HIDE_EDIT_MODAL:
      return { ...state, updateModal: false, taxClass: {} };
    default:
      return state;
  }
};

export default TaxClassReducers;
